import { IAnsweredQuestionInput } from '../interfaces/input/answeredQuestion';
import {
  getAnsweredQuestionsRepository,
  postAnsweredQuestionRepository,
  updateUserAnsweredQuestionsRepository,
} from '../repositories/answeredQuestion';

export const postAnsweredQuestionService = async (answeredQuestion: IAnsweredQuestionInput) => {
  return await postAnsweredQuestionRepository(answeredQuestion);
};

export const getAnsweredQuestionService = async () => {
  return await getAnsweredQuestionsRepository();
};

export const updateUserAnsweredQuestionsService = async () => {
  return await updateUserAnsweredQuestionsRepository();
};
