import * as React from 'react';
import { mobileBreakPoint } from '../../../../styles/theme';
import { Box } from '@mui/material';
import { Container, Content, InfoContainer, Title } from './styles';
import FinishTestImg from '../../../../images/illustrations/finishTest';
import CustomButton from '../../../../shared/components/button';
import { Link } from 'react-router-dom';
import { routes } from '../../../../routes/routes';

interface ITestResults {
  remainingTime: number | undefined;
  fullTime: number | undefined;
  userTest: any;
}

const TestResults: React.FC<ITestResults> = ({ remainingTime, userTest, fullTime }) => {
  const isMobile = window.screen.width <= mobileBreakPoint;

  const formatTime = (time: any) => {
    if (time) {
      let hours: any = Math.floor(time / 3600);
      let minutes: any = Math.floor((time % 3600) / 60);
      let seconds: any = Math.floor(time % 60);

      if (hours < 10) hours = `0${hours}`;
      if (minutes < 10) minutes = `0${minutes}`;
      if (seconds < 10) seconds = `0${seconds}`;

      return `${hours}:${minutes}:${seconds}`;
    }
  };

  const getAnsweredCorrectly = () => {
    let total = 0;

    for (const question of userTest) {
      const correctAnswer = question.correctAnswer;

      const answer = question.answers.find((item: any) => item.description === correctAnswer);
      if (answer?.selected) {
        total++;
      }
    }

    return total;
  };

  return (
    <Box
      display={'flex'}
      mt="5%"
      flexDirection={'column'}
      gap={'46px'}
      textAlign={'center'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <FinishTestImg />
      <Container>
        <InfoContainer>
          <Title>Total de questões</Title>
          <Content>{userTest.length}</Content>
        </InfoContainer>
        <InfoContainer>
          <Title>Total de acertos: </Title>
          <Content>{getAnsweredCorrectly()}</Content>
        </InfoContainer>
        <InfoContainer>
          <Title>Aproveitamento: </Title>
          <Content>{((getAnsweredCorrectly() / userTest.length) * 100 || 0).toFixed(2)}%</Content>
        </InfoContainer>
        <InfoContainer>
          <Title>Tempo estimado:</Title>
          <Content>{formatTime(fullTime)}</Content>
        </InfoContainer>
        <InfoContainer>
          <Title>Tempo gasto: </Title>
          <Content>{formatTime(fullTime! - remainingTime!)}</Content>
        </InfoContainer>
      </Container>
      <Box>
        <CustomButton
          classification="primary"
          uppercase={false}
          title="Fazer novo simulado"
          onClick={() => window.location.reload()}
        />
      </Box>
    </Box>
  );
};

export default TestResults;
