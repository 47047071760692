import * as React from 'react';

import Breadcrumbs from '@mui/material/Breadcrumbs';

import { StyledBreadcrumbItem } from './styles';

interface IBreadCrumbItens {
  label: string;
  link: string;
  icon?: any;
}

interface ICustomBreadcrumbsProps {
  data: IBreadCrumbItens[];
}

const CustomBreadcrumbs: React.FC<ICustomBreadcrumbsProps> = ({ data }) => {
  return (
    <Breadcrumbs>
      {data.map((item) => (
        <StyledBreadcrumbItem
          key={item.link}
          label={item.label}
          component="a"
          href={item.link}
          icon={item.icon}
        />
      ))}
    </Breadcrumbs>
  );
};

export default CustomBreadcrumbs;
