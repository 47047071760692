import * as React from 'react';

import { StyledContainer, StyledLabel, StyledProgressBar } from './styles';

interface IProgressBar {
  value: number;
  length: number;
}

const ProgressBar: React.FC<IProgressBar> = ({ value, length }) => {
  const percentageComplete = (value / length) * 100;
  return (
    <StyledContainer>
      <StyledLabel>
        {value}/{length}
      </StyledLabel>
      <StyledProgressBar variant="determinate" value={percentageComplete} />
    </StyledContainer>
  );
};

export default ProgressBar;
