import * as React from 'react';
import { SVGProps } from 'react';

const DashboardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={28} height={28} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M22.1667 3.5H5.83333C4.55 3.5 3.5 4.55 3.5 5.83333V22.1667C3.5 23.45 4.55 24.5 5.83333 24.5H22.1667C23.45 24.5 24.5 23.45 24.5 22.1667V5.83333C24.5 4.55 23.45 3.5 22.1667 3.5ZM22.1667 22.1667H5.83333V5.83333H22.1667V22.1667ZM8.16667 11.6667H10.5V19.8333H8.16667V11.6667ZM12.8333 8.16667H15.1667V19.8333H12.8333V8.16667ZM17.5 15.1667H19.8333V19.8333H17.5V15.1667Z"
      fill={props.fill}
    />
  </svg>
);

export default DashboardIcon;
