import * as React from 'react';

import { Box, RadioGroup } from '@mui/material';

import {
  StyledFormControlLabel,
  StyledLabel,
  StyledRadioButton,
  StyledDescription,
  StyledLabelContainer,
} from './styles';

interface IOption {
  value: string;
  label: string;
  description: string;
}

interface IRadioListProps {
  options: IOption[];
  selectedValue: string | undefined;
  onChange: (event: any) => void;
}

const RadioList: React.FC<IRadioListProps> = ({ options, selectedValue, onChange }) => {
  return (
    <Box display={'flex'} flexDirection={'column'}>
      <RadioGroup onClick={onChange}>
        {options.map((option) => (
          <StyledFormControlLabel
            key={option.value}
            value={option.value}
            labelPlacement={'start'}
            control={<StyledRadioButton size={'small'} value={option.value} checked={selectedValue === option.value} />}
            label={
              <StyledLabelContainer>
                <StyledLabel>{option.label}</StyledLabel>
                <StyledDescription>{option.description}</StyledDescription>
              </StyledLabelContainer>
            }
          />
        ))}
      </RadioGroup>
    </Box>
  );
};

export default RadioList;
