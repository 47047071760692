export const numberToCurrency = (number: number | undefined) => {
  if (number) return number.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
};

export const removeMask = (value?: string): string => {
  if (!value) return '';

  const result = value.replace(/[^\d]+/g, '');
  return result;
};

export const formatCpf = (cpf: any) => {
  if (cpf) {
    const newCpf = cpf.replace(/\D/g, '');
    return newCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  }
};

export const formatCnpj = (cnpj: any) => {
  if (cnpj) {
    const newCnpj = cnpj.replace(/\D/g, '');
    return newCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
  } else return cnpj;
};

export const formatPhone = (phone: any) => {
  if (phone) {
    phone = phone.replace(/[^0-9]/g, '');
    const primeiroDigito = phone.substring(0, 1);
    primeiroDigito === '0' ? (phone = phone.substring(1, phone.length)) : phone.substring(0, phone.length);
    let result = phone.slice(0, 11);
    result = result.replace(/^(\d{2})(\d)/g, '($1) $2');
    result = result.replace(/(\d)(\d{4})$/, '$1-$2');
    return result;
  }
  return phone;
};

export const formatCurrency = (value: any) => {
  if (value) {
    return Number(value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  }
  return '';
};

export const onlyNumbers = (input: any) => {
  if (typeof input === 'undefined' || input == null) return String(0);

  let value = '';

  if (typeof input.value !== 'undefined') {
    value = input.value;
  } else {
    value = input;
  }

  if (value.length == 2) return value;

  let v = value;
  v = v.replace(/\D/g, '');
  v = v.replace(/(\d{1,2})$/, ',$1');
  return String(v);
};

export const onlyIntegers = (value: any) => {
  if (value) {
    const retorno = value.replace(/[^0-9]/g, '');
    return retorno;
  }
};

export const formatDate = (value: any) => {
  let result = value.slice(0, 10);

  result = result.replace(/^(\d{2})(\d{2})(\d{4})/g, '$1/$2/$3');

  return result;
};

export const isEmailValid = (emailParam: string) => {
  const regex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  return regex.test(emailParam);
};

export const isPhoneNumberValid = (phoneNumber: string) => {
  const formatedPhoneNumber = removeMask(phoneNumber);
  const regex = /^([14689][0-9]|2[12478]|3([1-5]|[7-8])|5([13-5])|7[193-7])9[0-9]{8}$/;

  return regex.test(formatedPhoneNumber);
};

export const isExpiredDate = (dateString: string) => {
  const date = new Date(dateString);
  date.setDate(date.getDate() + 1);

  const currentDate = new Date();

  return currentDate > date;
};

export const formatStringDate = (dateString: string) => {
  const months: any = {
    Jan: '01',
    Feb: '02',
    Mar: '03',
    Apr: '04',
    May: '05',
    Jun: '06',
    Jul: '07',
    Aug: '08',
    Sep: '09',
    Oct: '10',
    Nov: '11',
    Dec: '12',
  };

  const parts = dateString.split(' ');
  const day = parts[2];
  const month = months[parts[1]];
  const year = parts[3];

  return `${day}/${month}/${year}`;
};
