import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MissionStatus } from '.';
import { defaultColors, defaultShadow } from '../../../../styles/theme';

interface IMissionStatus {
  status: MissionStatus;
}

export const getStatusColor = (status: MissionStatus) => {
  switch (status) {
    case MissionStatus.acomplished:
      return defaultColors.green.main;
    case MissionStatus.pending:
      return defaultColors.yellow.main;
    default:
      return defaultColors.red.main;
  }
};

export const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const MissionContentNew = styled(Typography)((props) => ({
  color: props.theme.palette.textColor,
  fontSize: '14px',
  width: '100%',
  alignSelf: 'center',

  '@media (max-width: 768px)': {
    fontSize: '10px',
    marginBottom: '6px',
  },
}));

export const MissionExpirationDate = styled(Typography)((props) => ({
  color: props.theme.palette.textColor,
  fontSize: '12px',
  width: '100%',
  alignSelf: 'center',

  '@media (max-width: 768px)': {
    fontSize: '10px',
  },
}));

export const MissionContainer = styled(Box)((props) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: '14px',
  paddingBottom: '14px',
  backgroundColor: props.theme.palette.white.main,
  borderTopLeftRadius: '16px',
  borderBottomLeftRadius: '16px',
  paddingLeft: '28px',
  paddingRight: '16px',
  position: 'relative',
  width: '100%',
}));

export const Tag = styled(Box)<IMissionStatus>((props) => ({
  width: '10px',
  height: '100%',
  backgroundColor: getStatusColor(props.status),
  position: 'absolute',
  left: 0,
  top: 0,
  borderTopLeftRadius: '20px',
  borderBottomLeftRadius: '20px',
}));

export const StatusContainer = styled(Box)((props) => ({
  display: 'flex',
  width: '18vw',
  justifyContent: 'center',
  backgroundColor: props.theme.palette.purple.light,
  borderTopRightRadius: '16px',
  borderBottomRightRadius: '16px',

  '@media (max-width: 768px)': {
    width: '50vw',
  },
}));

export const MissionStatusLabel = styled(Typography)<IMissionStatus>((props) => ({
  color: props.theme.palette.purple.main,
  fontSize: '14px',
  alignSelf: 'center',
  borderRadius: '12px',
  textAlign: 'center',
  maxWidth: '50%',
  fontWeight: 600,
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  '@media (max-width: 768px)': {
    fontSize: '10px',
    border: `none`,
    padding: '0px',
    fontWeight: 600,
  },
}));

export const NotFoundLabel = styled(Typography)((props) => ({
  fontSize: '14px',
  fontWeight: 500,
  marginTop: '12px',

  '@media (max-width: 768px)': {
    fontSize: '12px',
    marginTop: '0px',
  },
}));
