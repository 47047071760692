import * as React from 'react';
import { SVGProps } from 'react';

const CheckedCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg width={30} height={30} {...props}>
    <path
      d="M12.937 17.963 9.525 14.55a1.14 1.14 0 0 0-.843-.338c-.338 0-.62.112-.844.338-.275.275-.412.569-.412.882 0 .312.137.593.412.842l4.05 4.05c.275.3.606.45.994.45.387 0 .744-.15 1.069-.45l8.211-8.249c.226-.224.339-.5.339-.824 0-.326-.113-.601-.339-.827-.25-.25-.53-.374-.844-.374-.312 0-.593.125-.842.374l-7.539 7.539ZM15 29.25c-1.975 0-3.832-.375-5.57-1.125-1.737-.75-3.243-1.763-4.517-3.039-1.276-1.274-2.288-2.78-3.038-4.518C1.125 18.832.75 16.975.75 15s.375-3.832 1.125-5.57c.75-1.737 1.762-3.243 3.038-4.517 1.274-1.276 2.78-2.289 4.518-3.039S13.025.75 15 .75c1.974 0 3.83.375 5.568 1.125 1.738.75 3.244 1.763 4.518 3.039 1.276 1.274 2.289 2.78 3.039 4.518.75 1.737 1.125 3.594 1.125 5.569s-.375 3.832-1.125 5.57c-.75 1.737-1.763 3.243-3.039 4.517-1.274 1.276-2.78 2.289-4.518 3.039-1.737.75-3.594 1.125-5.569 1.125Zm0-2.402c3.275 0 6.069-1.156 8.38-3.468 2.313-2.313 3.47-5.106 3.47-8.381 0-3.275-1.157-6.068-3.47-8.38C21.07 4.306 18.276 3.15 15 3.15c-3.275 0-6.068 1.157-8.38 3.47C4.306 8.931 3.15 11.724 3.15 15c0 3.275 1.157 6.068 3.47 8.38 2.312 2.313 5.105 3.47 8.38 3.47Z"
      fill={props.fill}
    />
  </svg>
);

export default CheckedCircle;
