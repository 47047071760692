import * as React from 'react';

import { getFontColor, StyledBadge, StyledCloseIcon } from './styles';

interface IStyledBadgeProps {
  children: any;
  color: 'blue' | 'green' | 'yellow' | 'gray' | 'red';
  showCloseIcon?: boolean;
  onClickCloseIcon?: () => void;
}

const CustomizedBadge: React.FC<IStyledBadgeProps> = ({
  children,
  color,
  showCloseIcon,
  onClickCloseIcon,
}) => {
  return (
    <StyledBadge color={color}>
      {children}
      {showCloseIcon && (
        <StyledCloseIcon
          onClick={onClickCloseIcon}
          size={10}
          fill={getFontColor(color)}
        />
      )}
    </StyledBadge>
  );
};

export default CustomizedBadge;
