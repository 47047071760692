import * as React from 'react';

import { Box } from '@mui/material';

import {
  ContentSmallCard,
  StyledLargeCard,
  StyledLargeContent,
  StyledMediumCard,
  StyledLeftContent,
  StyledLeftTitle,
  StyledMediumCardRightContent,
  StyledMediumCardRightTitle,
  StyledSmallCard,
} from './styles';
import { defaultColors } from '../../../styles/theme';
import CheckedCircleSmall from '../../../images/icons/checked_circle_sm';

interface ICustomCardProps {
  selected: boolean;
  size: 'small' | 'medium' | 'large';
  smallCardContent?: string;
  leftTitle?: string;
  leftContent?: string;
  rightTitle?: string;
  rightContent?: string;
  largeContent?: string;
}

const CustomCard: React.FC<ICustomCardProps> = ({
  selected,
  size,
  smallCardContent,
  leftTitle,
  leftContent,
  rightTitle,
  rightContent,
  largeContent,
}) => {
  switch (size) {
    case 'small':
      return (
        <StyledSmallCard selected={selected}>
          <ContentSmallCard selected={selected}>{smallCardContent}</ContentSmallCard>
        </StyledSmallCard>
      );
    case 'medium':
      return (
        <StyledMediumCard selected={selected}>
          <Box display={'flex'} flexDirection={'column'}>
            <StyledLeftTitle>{leftTitle}</StyledLeftTitle>
            <StyledLeftContent marginTop={'8px'}>{leftContent}</StyledLeftContent>
          </Box>
          <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
            <StyledMediumCardRightTitle>{rightTitle}</StyledMediumCardRightTitle>
            <StyledMediumCardRightContent marginTop={'2px'}>{rightContent}</StyledMediumCardRightContent>
          </Box>
        </StyledMediumCard>
      );
    default:
      return (
        <StyledLargeCard selected={selected}>
          <Box display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} justifyContent={'space-between'}>
              <StyledLeftTitle>{leftTitle}</StyledLeftTitle>
              {selected && <CheckedCircleSmall fill={defaultColors.purple.main} />}
            </Box>
            <StyledLeftContent marginTop={'12px'} marginBottom={'25px'}>
              {leftContent}
            </StyledLeftContent>
          </Box>
          <StyledLargeContent>{largeContent}</StyledLargeContent>
        </StyledLargeCard>
      );
  }
};

export default CustomCard;
