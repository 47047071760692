import { FormControlLabel, Radio, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledRadioButton = styled(Radio)((props) => ({
  color: props.theme.palette.gray.main,
  '&.Mui-checked': {
    color: props.theme.palette.purple.main,
  },
}));

export const StyledLabel = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '14px',
}));

export const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  alignItems: 'center',
  display: 'flex',
}));
