import React, { useState } from 'react';
import { createContext } from 'react';

interface IMissionsContext {
  missionsData: any;
  setMissionsData: any;
}

interface IMissionsData {
  missionsData: any;
  setMissionsData: any;
}

export const MissionsContext = createContext<IMissionsContext>({} as IMissionsContext);

export const MissionsProvider = ({ children }: { children: React.ReactNode }) => {
  const [missionsData, setMissionsData] = useState<IMissionsData>();
  return (
    <MissionsContext.Provider value={{ missionsData: missionsData, setMissionsData: setMissionsData }}>
      <>{children}</>
    </MissionsContext.Provider>
  );
};
