import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ButtonsContainer = styled(Box)((props) => ({
  display: 'flex',
  gap: '24px',

  '@media (max-width: 768px)': {
    flexDirection: 'column',
  },
}));
