import React, { useEffect, useState } from 'react';

import { ChartTitle, Container } from './styles';
import GeneralInfo from '../generalInfo';
import { defaultColors, mobileBreakPoint } from '../../../../styles/theme';
import { Box, Skeleton } from '@mui/material';
import Chart from 'react-apexcharts';
import { getAnsweredQuestionService } from '../../../../services/answeredQuestions';
import CustomSnackBar from '../../../../shared/components/toast';
import CustomAlert from '../../../../shared/components/alert';

const GeneralContent: React.FC = () => {
  const [dashboardData, setDashboardData] = useState<any>();
  const [answeredQuestions, setAnsweredQuestions] = useState<any[]>([]);
  const [snackOpen, setSnackOpen] = useState(false);
  const isMobile = window.screen.width <= mobileBreakPoint;

  const getLastSevenDays = () => {
    const daysList = [];
    const currentDate = new Date();

    for (let i = 0; i < 7; i++) {
      const date = new Date(currentDate);
      date.setDate(date.getDate() - i);

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');

      daysList.push(`${day}/${month}`);
    }

    const sortedDays = daysList.sort((a, b) => {
      const newA = a.split('/');
      const newB = b.split('/');

      const aFormatted = `${newA[1]}/${newA[0]}`;
      const bFormatted = `${newB[1]}/${newB[0]}`;
      const dateA: any = new Date('2000/' + aFormatted);
      const dateB: any = new Date('2000/' + bFormatted);
      return dateA - dateB;
    });

    return sortedDays;
  };

  let chartOptionsDefault = {
    options: {
      chart: {
        height: 350,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      xaxis: {
        categories: getLastSevenDays(),
      },
      colors: [defaultColors.purple.main, defaultColors.green.main],
      dataLabels: {
        enabled: false,
      },

      markers: {
        size: 4,
      },
    },
    series: [
      {
        name: 'Questões respondidas',
        data: [],
      },
      {
        name: 'Acertos',
        data: [],
      },
    ],
  };

  const [chartOptions, setChartOptions] = useState<any>(chartOptionsDefault);

  const getAnsweredQuestions = async () => {
    const response = await getAnsweredQuestionService();
    const data = {
      answeredQuestions: 0,
      correctAnswers: 0,
      rate: 0,
      totalSpendedTime: 0,
    };

    if (response.status === 200) {
      const result = response.data.result;
      setAnsweredQuestions(result);

      for (const item of result) {
        data.answeredQuestions += 1;
        data.correctAnswers = item.answeredCorrectly ? data.correctAnswers + 1 : data.correctAnswers;
        data.rate = (data.correctAnswers / data.answeredQuestions) * 100 || 0;
        data.totalSpendedTime += item.spendedTime;
      }
    } else {
      setSnackOpen(true);
    }
    setDashboardData(data);
  };

  const compareDates = (dateString: any, compareString: any) => {
    const months: any = {
      Jan: '01',
      Feb: '02',
      Mar: '03',
      Apr: '04',
      May: '05',
      Jun: '06',
      Jul: '07',
      Aug: '08',
      Sep: '09',
      Oct: '10',
      Nov: '11',
      Dec: '12',
    };

    // Obter o dia e o mês da string no formato "Tue Nov 07 2023"
    const parts = dateString.split(' ');
    const day = parts[2];
    const month = months[parts[1]];

    // Comparar o dia e o mês com a string de comparação "07/11"
    const compareParts = compareString.split('/');
    return day === compareParts[0] && month === compareParts[1];
  };

  const getChartAnsweredQuestions = () => {
    const result = [];
    if (answeredQuestions.length > 0) {
      const lastSevenDays = getLastSevenDays();

      for (const day of lastSevenDays) {
        const answeredQuestionsByDay = answeredQuestions.filter((item) => compareDates(item.answerDate, day));

        result.push(answeredQuestionsByDay.length);
      }
    }
    return result;
  };

  const getChartAnsweredQuestionsCorrectly = () => {
    const result = [];
    if (answeredQuestions.length > 0) {
      const lastSevenDays = getLastSevenDays();

      for (const day of lastSevenDays) {
        const answeredQuestionsByDay = answeredQuestions.filter(
          (item) => compareDates(item.answerDate, day) && item.answeredCorrectly,
        );

        result.push(answeredQuestionsByDay.length);
      }
    }
    return result;
  };

  useEffect(() => {
    getAnsweredQuestions();
  }, []);

  useEffect(() => {
    const chartOptionsNew = {
      ...chartOptions,
      series: [
        {
          name: 'Questões respondidas',
          data: getChartAnsweredQuestions(),
        },
        {
          name: 'Acertos',
          data: getChartAnsweredQuestionsCorrectly(),
        },
      ],
    };

    setChartOptions(chartOptionsNew);
  }, [answeredQuestions]);

  return (
    <Container>
      {dashboardData ? (
        <>
          <GeneralInfo data={dashboardData} />
          <Box
            mt={'22px'}
            sx={{
              border: `1px solid ${defaultColors.gray.light}`,
              borderRadius: '16px',
              paddingLeft: '8px',
              paddingRight: '8px',
              paddingBottom: '8px',
            }}
          >
            <Box sx={{ paddingTop: '12px', paddingLeft: '16px', paddingBottom: '8px' }}>
              <ChartTitle>Desempenho dos últimos 07 dias</ChartTitle>
            </Box>
            <Chart options={chartOptions.options} series={chartOptions.series} type="area" height={'300px'} />
          </Box>
        </>
      ) : (
        <Box display={'flex'} gap={'22px'} flexDirection={'column'}>
          <Box display={'flex'} gap={'20px'} flexDirection={isMobile ? 'column' : 'row'}>
            <Skeleton
              sx={{ bgcolor: defaultColors.purple.light, borderRadius: '12px' }}
              variant="rectangular"
              animation="wave"
              width={'100%'}
              height={'88px'}
            />
            <Skeleton
              sx={{ bgcolor: defaultColors.purple.light, borderRadius: '12px' }}
              variant="rectangular"
              animation="wave"
              width={'100%'}
              height={'88px'}
            />
            <Skeleton
              sx={{ bgcolor: defaultColors.purple.light, borderRadius: '12px' }}
              variant="rectangular"
              animation="wave"
              width={'100%'}
              height={'88px'}
            />
            <Skeleton
              sx={{ bgcolor: defaultColors.purple.light, borderRadius: '12px' }}
              variant="rectangular"
              animation="wave"
              width={'100%'}
              height={'88px'}
            />
          </Box>
          <Skeleton
            sx={{ bgcolor: defaultColors.purple.light, borderRadius: '12px' }}
            variant="rectangular"
            animation="wave"
            width={'100%'}
            height={'350px'}
          />
        </Box>
      )}
      <CustomSnackBar isOpen={snackOpen}>
        <CustomAlert
          showLeftIcon={true}
          showCloseIcon={true}
          severity={'error'}
          title={'Ops! Tivemos um problema ao carregar as informações do seu dashboard.'}
          onClose={() => setSnackOpen(false)}
        />
      </CustomSnackBar>
    </Container>
  );
};

export default GeneralContent;
