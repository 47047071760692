import * as React from 'react';

import Snackbar from '@mui/material/Snackbar';

interface ICustomSnackBarProps {
  isOpen: boolean;
  children: any;
}

const CustomSnackBar: React.FC<ICustomSnackBarProps> = ({ isOpen, children }) => {
  return (
    <div>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isOpen} key={'top' + 'right'}>
        <div>{children}</div>
      </Snackbar>
    </div>
  );
};

export default CustomSnackBar;
