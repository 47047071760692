import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { defaultShadow } from '../../../../styles/theme';

interface IDashboardCard {
  urlImage: string;
}

export const Container = styled(Box)((props) => ({
  display: 'flex',
  width: '100%',
  borderRadius: '16px',
  border: `1px solid ${props.theme.palette.gray.light}`,
  padding: '12px',
  justifyContent: 'space-between',

  '@media (max-width: 768px)': {
    boxShadow: defaultShadow,
    padding: '8px',

    '& + &': {
      marginTop: '12px',
    },
  },
}));

export const InfoContainer = styled(Box)((props) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  '& + &': {
    borderLeft: `1px solid ${props.theme.palette.gray.light}`,
  },
}));

export const HeaderLabel = styled(Typography)((props) => ({
  color: props.theme.palette.textColor,
  textAlign: 'center',
  fontSize: '12px',
  fontWeight: 400,
  letterSpacing: '0.5px',
  marginBottom: '8px',

  '@media (max-width: 768px)': {
    fontSize: '10px',
    marginBottom: '8px',
    padding: '0px 8px',
  },
}));

export const Info = styled(Typography)((props) => ({
  color: props.theme.palette.textColor,
  textAlign: 'center',
  fontSize: '24px',
  fontWeight: 600,
  letterSpacing: '0.5px',

  '@media (max-width: 768px)': {
    fontSize: '18px',
    fontWeight: 500,
  },
}));

export const DashboardCard = styled(Box)<IDashboardCard>((props) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '18px 24px',
  borderRadius: '16px',
  backgroundImage: `url(${props.urlImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: 'right',
  width: '25%',
  backgroundColor: props.theme.palette.purple.main,

  '@media (max-width: 768px)': {
    width: '100%',
    padding: '12px 20px',
  },
}));

export const DashboardCardTitle = styled(Typography)((props) => ({
  letterSpacing: '0.5px',
  color: props.theme.palette.white.main,
  fontSize: '12px',
  fontWeight: '500',

  '@media (max-width: 768px)': {
    fontSize: '10px',
  },
}));

export const DashboardCardContent = styled(Typography)((props) => ({
  letterSpacing: '0.5px',
  color: props.theme.palette.white.main,
  fontSize: '32px',
  fontWeight: '600',

  '@media (max-width: 768px)': {
    fontSize: '22px',
  },
}));
