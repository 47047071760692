import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { useState } from 'react';
import { Container, Span, StyledLink } from './styles';

import { mobileBreakPoint } from '../../../styles/theme';
import CustomButton from '../button';
import RocketGuyImg from '../../../images/illustrations/rocketGuy';
import WelcomeImg from '../../../images/illustrations/welcome';
import NotebookGuy from '../../../images/illustrations/notebookGuy';
import ShieldGuyImg from '../../../images/illustrations/shieldGuy';
import GiftGirlImg from '../../../images/illustrations/giftGirl';
import DashboardGirlImg from '../../../images/illustrations/dashboardGirl';
import { userFirstAccessKey } from '../../util/constants';
import { updateUserFirstAccessService, updateUserShowOnRankingService } from '../../../services/user';
import { ToggleContainer, ShowRankingLabel } from '../../../pages/profile/views/styles';
import CustomToggle from '../toggle';
import { routes } from '../../../routes/routes';

interface IFirstAccessDrawer {
  isOpen: boolean;
  onClose: any;
}

const steps = [
  {
    step: 'welcome',
    image: <WelcomeImg />,
    label:
      'Oláá! Que bom ter você aqui com a gente! Tenho certeza que aqui se iniciam uma bela trajetória e uma parceria de sucesso! Antes de iniciar sua jornada, vamos te explicar um pouco melhor como funciona a plataforma. Vamos lá?!',
  },
  {
    step: 'introduction',
    image: <NotebookGuy />,
    label:
      'Basicamente, o CFC Lab é uma plataforma para te auxiliar na preparação para o exame do CFC. Aqui você tem a possibilidade de responder questões livremente, simular uma prova nos moldes do exame oficial, ou ainda, customizar um simulado à sua escolha!',
  },
  {
    step: 'suitability',
    image: <ShieldGuyImg />,
    label:
      'Mas não é só isso! Através de um questionário de perfil, lhe daremos algumas missões que poderão ser mais fáceis, ou difíceis, dependendo das suas respostas.',
  },
  {
    step: 'prizes',
    image: <GiftGirlImg />,
    label:
      'E depois de tanto estudar para o exame, sabemos que a melhor recompensa é a aprovação, maaas, aqui no CFC Lab você também pode ganhar algumas recompensas! Basta estar entre os primeiros do nosso ranking mensal!',
  },
  {
    step: 'ranking',
    image: <DashboardGirlImg />,
    label:
      'Nosso ranking é reiniciado todo início de mês e os critérios de classificação são a quantidade de questões respondidas corretamente e o percentual de questões respondidas corretamente em relação ao total.',
  },
  {
    step: 'last',
    image: <RocketGuyImg />,
    label: `Enfim, acho que era isso que gostaríamos de te passar antes de você iniciar sua jornada. Caso queira fazer o teste de perfil agora e já receber suas primeiras missões, clique no link abaixo, ou então, você pode fazer a qualquer momento acessando Perfil -> Teste de perfil.`,
  },
];

const FirstAccessDrawer: React.FC<IFirstAccessDrawer> = ({ isOpen, onClose }) => {
  const isMobile = window.screen.width <= mobileBreakPoint;
  const [selectedStep, setSelectedStep] = useState(0);
  const [showOnRanking, setOnRanking] = useState(true);
  const [redirectToSuitabilityQuiz, setRedirectToSuitabilityQuiz] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleNextButton = async (redirectToSuitabilityQuiz?: boolean) => {
    if (selectedStep > steps.length - 1) {
      return;
    }
    if (selectedStep === steps.length - 1) {
      setLoading(true);

      const promises = [updateUserShowOnRankingService(), updateUserFirstAccessService()];
      await Promise.all(promises);

      localStorage.setItem(userFirstAccessKey, '0');

      onClose();
      if (redirectToSuitabilityQuiz) {
        setRedirectToSuitabilityQuiz(redirectToSuitabilityQuiz);
      }
      setLoading(false);
      return;
    }
    setSelectedStep(selectedStep + 1);
  };

  const handleBackButton = () => {
    if (selectedStep === 0) {
      return;
    }
    setSelectedStep(selectedStep - 1);
  };

  return (
    <>
      <Drawer anchor={'right'} open={isOpen}>
        <Container>
          <Box textAlign={'center'} alignItems={'center'} display={'flex'} flexDirection={'column'} gap={'40px'}>
            {steps[selectedStep].image}
            <Box display={'flex'} flexDirection={'column'} gap={'12px'}>
              <Span>{steps[selectedStep].label}</Span>
              {steps[selectedStep].step === 'last' && (
                <Span>
                  Esperamos que seu caminho seja repleto de aprendizado, e, no que depender da gente, com certeza você
                  será APROVADO no próximo exame!
                </Span>
              )}
              {steps[selectedStep].step === 'last' && (
                <Box mt="12px">
                  <StyledLink to={routes.suitabilityQuiz.path!} onClick={() => handleNextButton(true)}>
                    Fazer teste de perfil
                  </StyledLink>
                </Box>
              )}
            </Box>
            {/* {steps[selectedStep].step === 'ranking' && (
              <ToggleContainer onClick={() => setOnRanking(!showOnRanking)}>
                <CustomToggle selected={showOnRanking} />
                <ShowRankingLabel selected={showOnRanking}>Exibir meu desempenho no ranking</ShowRankingLabel>
              </ToggleContainer>
            )} */}
          </Box>
          {isMobile ? (
            <Box display={'flex'} gap={'24px'} justifyContent={'center'}>
              <CustomButton
                fullWidth
                classification="secondary"
                uppercase={false}
                disabled={selectedStep === 0}
                title="Voltar"
                onClick={() => handleBackButton()}
              />
              <CustomButton
                fullWidth
                classification="primary"
                uppercase={false}
                title={selectedStep === steps.length - 1 ? 'Concluir' : 'Avançar'}
                onClick={() => handleNextButton()}
                isLoading={loading}
              />
            </Box>
          ) : (
            <Box width={'40%'} display={'flex'} gap={'24px'}>
              <CustomButton
                fullWidth
                classification="secondary"
                uppercase={false}
                disabled={selectedStep === 0}
                title="Voltar"
                onClick={() => handleBackButton()}
              />
              <CustomButton
                fullWidth
                classification="primary"
                uppercase={false}
                title={selectedStep === steps.length - 1 ? 'Concluir' : 'Avançar'}
                onClick={() => handleNextButton()}
                isLoading={loading}
              />
            </Box>
          )}
        </Container>
      </Drawer>
    </>
  );
};

export default FirstAccessDrawer;
