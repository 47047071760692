import * as React from 'react';

import { Box } from '@mui/material';
import Modal from '@mui/material/Modal';

import CustomButton from '../button';
import {
  StyledButtonsContainer,
  StyledModalDefault,
  StyledModalContent,
  StyledModalTitle,
  StyledModalLined,
  StyledButtonsContainerLined,
} from './styles';
import { defaultColors } from '../../../styles/theme';
import CheckedCircle from '../../../images/icons/checked_circle';
import ErrorIcon from '../../../images/icons/error';

interface ICustomModalProps {
  title: string;
  content: string;
  primaryButtonTitle: string;
  open: boolean;
  onClose: () => void;
  primaryButtonOnClick: () => void;
  classification: 'warning' | 'success';
  lined?: boolean;
  secondaryButtonTitle?: string;
  secondaryButtonOnClick?: () => void;
  showSecondaryButton?: boolean;
  maxWidth?: string;
}

const CustomModal: React.FC<ICustomModalProps> = ({
  title,
  content,
  primaryButtonTitle,
  open,
  onClose,
  primaryButtonOnClick,
  secondaryButtonOnClick,
  showSecondaryButton,
  secondaryButtonTitle,
  classification,
  lined,
  maxWidth,
}) => {
  return (
    <div>
      <Modal open={open} onClose={onClose}>
        {lined ? (
          <StyledModalLined>
            <Box>
              <Box display={'flex'} alignItems={'center'} gap={'16px'}>
                {classification === 'warning' ? (
                  <ErrorIcon fill={defaultColors.red.main} />
                ) : (
                  <CheckedCircle fill={defaultColors.green.main} />
                )}
                <StyledModalTitle>{title}</StyledModalTitle>
              </Box>
              <Box marginLeft={'46px'}>
                <StyledModalContent>{content}</StyledModalContent>
              </Box>
              <Box display="flex" justifyContent={'flex-end'}>
                <StyledButtonsContainerLined>
                  {showSecondaryButton && (
                    <CustomButton
                      classification="secondary"
                      uppercase={false}
                      title={secondaryButtonTitle}
                      onClick={secondaryButtonOnClick}
                      fullWidth={true}
                    />
                  )}
                  <CustomButton
                    classification={classification === 'warning' ? 'warning' : 'primary'}
                    uppercase={false}
                    title={primaryButtonTitle}
                    onClick={primaryButtonOnClick}
                    fullWidth={showSecondaryButton}
                  />
                </StyledButtonsContainerLined>
              </Box>
            </Box>
          </StyledModalLined>
        ) : (
          <StyledModalDefault maxWidth={maxWidth}>
            <Box marginBottom={'28px'}>
              {classification === 'warning' ? (
                <ErrorIcon fill={defaultColors.red.main} />
              ) : (
                <CheckedCircle fill={defaultColors.green.main} />
              )}
            </Box>
            <StyledModalTitle>{title}</StyledModalTitle>
            <StyledModalContent>{content}</StyledModalContent>
            <StyledButtonsContainer justifyContent={showSecondaryButton ? 'space-between' : 'center'}>
              {showSecondaryButton && (
                <CustomButton
                  classification="secondary"
                  uppercase={false}
                  title={secondaryButtonTitle}
                  onClick={secondaryButtonOnClick}
                  fullWidth={true}
                />
              )}
              <CustomButton
                classification={classification === 'warning' ? 'warning' : 'primary'}
                uppercase={false}
                title={primaryButtonTitle}
                onClick={primaryButtonOnClick}
                fullWidth={showSecondaryButton}
              />
            </StyledButtonsContainer>
          </StyledModalDefault>
        )}
      </Modal>
    </div>
  );
};

export default CustomModal;
