import { Box, Step, StepConnector, stepConnectorClasses, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface IStyledStepItemProps {
  active: boolean;
  completed: boolean;
}

interface IStyledConnectorProps {
  orientation: 'vertical' | 'horizontal';
}

export const StyledConnector = styled(StepConnector)<IStyledConnectorProps>((props) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: props.theme.palette.purple.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: props.theme.palette.purple.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderTopWidth: '1.5px',
    borderColor: props.theme.palette.gray.light,
    marginLeft: props.orientation === 'vertical' && '3.3px',
  },
}));

export const StyledStep = styled(Box)<IStyledStepItemProps>((props) => ({
  background: props.active
    ? props.theme.palette.white.main
    : props.completed
    ? props.theme.palette.purple.main
    : props.theme.palette.white.main,
  width: '32px',
  height: '32px',
  borderRadius: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: props.active
    ? `1.5px solid ${props.theme.palette.purple.main}`
    : props.completed
    ? 'none'
    : `1.5px solid ${props.theme.palette.gray.light}`,
}));

export const StyledActiveIcon = styled(Box)((props) => ({
  background: props.theme.palette.purple.main,
  width: '12px',
  height: '12px',
  borderRadius: '50%',
}));

export const StyledStepContainer = styled(Step)<IStyledConnectorProps>((props) => ({
  padding: '0px',
  display: 'flex',
  gap: props.orientation === 'vertical' ? '12px' : '0px',
  alignItems: 'center',
}));

export const StyledLabel = styled(Typography)<IStyledStepItemProps>((props) => ({
  color: props.active
    ? props.theme.palette.purple.main
    : props.completed
    ? props.theme.palette.textColor
    : props.theme.palette.gray.main,
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '14px',
}));

export const StyledContent = styled(Typography)((props) => ({
  color: props.theme.palette.gray.main,
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '14px',
  marginTop: '4px',
}));
