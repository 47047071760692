import React, { useState, useEffect } from 'react';
import CustomAlert from '../../../shared/components/alert';
import CustomAvatar from '../../../shared/components/avatar';
import CustomizedBadge from '../../../shared/components/badge';
import CustomBreadcrumbs from '../../../shared/components/breadcrumbs';
import CustomButton from '../../../shared/components/button';
import CustomCard from '../../../shared/components/card';
import CustomCardStepper from '../../../shared/components/cardStepper';
import CustomCheckbox from '../../../shared/components/checkbox';
import CustomModal from '../../../shared/components/modal';
import CustomPagination from '../../../shared/components/pagination';
import CustomRadio from '../../../shared/components/radioButton';
import RadioList from '../../../shared/components/radioList';
import CustomSelect from '../../../shared/components/select';
import CustomStepper from '../../../shared/components/stepper';
import CustomTable from '../../../shared/components/table';
import CustomTextInput from '../../../shared/components/textInput';
import CustomSnackBar from '../../../shared/components/toast';
import CustomToggle from '../../../shared/components/toggle';

import { tokenKey } from '../../../shared/util/constants';
import ProgressBar from '../../../shared/components/progressBar';

const Components: React.FC = () => {
  const [toggleOne, setToggleOne] = useState(false);
  const [toggleTwo, setToggleTwo] = useState(true);
  const [checkboxChecked, setCheckboxChecked] = useState(true);
  const [radioValue, setRadioValue] = useState();
  const [radioListValue, setRadioListValue] = useState();
  const [selectValue, setSelectValue] = useState();
  const [inputValue, setInputValue] = useState();
  const [simpleModalOpen, setSimpleModalOpen] = useState(false);
  const [modalOpenTwoButtons, setModalOpenTwoButtons] = useState(false);
  const [modalWarningOpen, setModalWarningOpen] = useState(false);
  const [modalLinedOpen, setModalLinedOpen] = useState(false);
  const [modalLinedWarningOpen, setModalLinedWarningOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);

  const radioOptions = [
    { value: 'a', label: 'Email' },
    { value: 'b', label: 'Phone (SMS)' },
    { value: 'c', label: 'Push notification' },
  ];

  const radioListOptions = [
    { value: 'a', label: 'Checking', description: 'CIBC 6610' },
    { value: 'b', label: 'Savings', description: 'Banks of America 0149' },
  ];

  const selectOptions = [
    { value: 'a', label: 'Tom Cook' },
    { value: 'b', label: 'Wade Cooper' },
  ];

  const steps = [
    { label: 'Create account', content: 'Vitae sed mi luctus laoreet.' },
    {
      label: 'Profile information',
      content: 'Cursus semper viverra facilisis.',
    },
    { label: 'Business information', content: 'Lorem ispum sit amet.' },
    { label: 'Preview', content: 'Faucibus nec enim leo et.' },
  ];

  const tableColumns = ['Name', 'Title', 'Email'];

  const dataRows = [
    {
      name: 'Lindsay Walton',
      title: 'Front-end Developer',
      email: 'lindsay.walton@example.com',
    },
    {
      name: 'Courtney Henry',
      title: 'Designer',
      email: 'courtney.henry@example.com',
    },
    {
      name: 'Tom Cook',
      title: 'Director',
      email: 'tom.cook@example.com',
    },
    {
      name: 'Leonard Krasner',
      title: 'Senior Designer',
      email: 'leonard.krasner@example.com',
    },
    {
      name: 'Floyd Miles',
      title: 'Principal Designer',
      email: 'floyd.miles@example.com',
    },
  ];

  const breadcrumbItens = [
    {
      label: 'Dashboard',
      link: '/dashboard',
    },
    {
      label: 'Projects',
      link: '/projects',
    },
    {
      label: 'Projects Nero',
      link: '/projectsNero',
    },
  ];

  return (
    <>
      <>
        <ProgressBar value={50} length={100} />

        <div style={{ marginLeft: '20px', marginRight: '20px' }}>
          <div style={{ display: 'flex', marginTop: '30px', gap: '20px' }}>
            <CustomButton
              classification="primary"
              uppercase={false}
              title="Abrir toast"
              onClick={() => setSnackOpen(true)}
            />
            <CustomSnackBar isOpen={snackOpen}>
              <CustomAlert
                showLeftIcon={true}
                showCloseIcon={true}
                severity={'error'}
                title="A new software update is avaible."
                onClose={() => setSnackOpen(false)}
              />
            </CustomSnackBar>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '30px',
              gap: '20px',
            }}
          >
            <CustomAvatar size={128} />
            <CustomAvatar size={96} />
            <CustomAvatar size={40} />
            <CustomAvatar size={32} />
            <CustomAvatar size={16} />
          </div>
          <div style={{ display: 'flex', marginTop: '30px', gap: '20px' }}>
            <CustomCard selected={true} size={'small'} smallCardContent={'8gb'} />
            <CustomCard selected={false} size={'small'} smallCardContent={'16gb'} />
            <CustomCard
              selected={true}
              leftTitle={'Hobby'}
              leftContent={'8GB / 4 CPUs - 512 GB SSD disk'}
              rightTitle={'40'}
              rightContent={'/mo'}
              size={'medium'}
            />
            <CustomCard
              selected={false}
              leftTitle={'Startup'}
              leftContent={'8GB / 4 CPUs - 512 GB SSD disk'}
              rightTitle={'160'}
              rightContent={'/mo'}
              size={'medium'}
            />
            <CustomCard
              selected={true}
              leftTitle={'Newsletter'}
              leftContent={'Last message sent an hour agor'}
              largeContent={'621 users'}
              size={'large'}
            />
            <CustomCard
              selected={false}
              leftTitle={'Existing Customers'}
              leftContent={'Last message sent 2 weeks ago'}
              largeContent={'1200 users'}
              size={'large'}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '30px',
              gap: '20px',
              width: '20%',
            }}
          >
            <CustomAlert
              showLeftIcon={true}
              showCloseIcon={true}
              severity={'error'}
              title="A new software update is avaible."
            />
            <CustomAlert
              showLeftIcon={true}
              showCloseIcon={false}
              severity={'info'}
              title="A new software update is avaible."
            />
            <CustomAlert
              showLeftIcon={true}
              showCloseIcon={false}
              severity={'warning'}
              title="A new software update is avaible."
            />
            <CustomAlert
              showLeftIcon={true}
              showCloseIcon={true}
              severity={'success'}
              title="A new software update is avaible."
            />
            <CustomAlert
              showLeftIcon={true}
              showCloseIcon={false}
              title="A new software update is avaible."
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam orci lacus, pharetra id est ut."
            />
          </div>
          <div style={{ display: 'flex', marginTop: '30px', gap: '20px' }}>
            <CustomBreadcrumbs data={breadcrumbItens} />
          </div>
          <div style={{ display: 'flex', marginTop: '30px', gap: '20px' }}>
            <CustomTable dataColumns={tableColumns} dataRows={dataRows} hasActionColumn={true} />
          </div>
          <div style={{ display: 'flex', marginTop: '30px', gap: '20px' }}>
            <CustomCardStepper steps={steps} activeStep={1} showContent showLabel />
          </div>
          <div style={{ display: 'flex', marginTop: '30px', gap: '20px' }}>
            <CustomStepper width={250} steps={steps} activeStep={2} orientation="horizontal" />
            <CustomStepper width={300} steps={steps} activeStep={1} orientation="vertical" showContent showLabel />
          </div>
          <div style={{ display: 'flex', marginTop: '30px', gap: '20px' }}>
            <CustomPagination count={5} />
          </div>
          <div style={{ display: 'flex', marginTop: '30px', gap: '20px' }}>
            <CustomButton
              classification="primary"
              uppercase={false}
              title="Abrir modal simples"
              onClick={() => setSimpleModalOpen(true)}
            />
            <CustomButton
              classification="primary"
              uppercase={false}
              title="Abrir modal dois botoes"
              onClick={() => setModalOpenTwoButtons(true)}
            />
            <CustomButton
              classification="primary"
              uppercase={false}
              title="Abrir modal warning"
              onClick={() => setModalWarningOpen(true)}
            />
            <CustomButton
              classification="primary"
              uppercase={false}
              title="Abrir modal lined"
              onClick={() => setModalLinedOpen(true)}
            />
            <CustomButton
              classification="primary"
              uppercase={false}
              title="Abrir modal warning lined"
              onClick={() => setModalLinedWarningOpen(true)}
            />
            <CustomModal
              onClose={() => setSimpleModalOpen(false)}
              open={simpleModalOpen}
              title="Payment sucessful"
              content="Lorem ipsum dolor sit amet consectur adipisicing elit. Consequatur."
              primaryButtonTitle="Go back to dashboard"
              primaryButtonOnClick={() => console.log('clicou no botao')}
              classification="success"
            />
            <CustomModal
              onClose={() => setModalOpenTwoButtons(false)}
              open={modalOpenTwoButtons}
              title="Payment sucessful"
              content="Lorem ipsum dolor sit amet consectur adipisicing elit. Consequatur."
              primaryButtonTitle="Go back to dashboard"
              primaryButtonOnClick={() => console.log('clicou no botao')}
              showSecondaryButton={true}
              secondaryButtonTitle="Cancel"
              classification="success"
            />
            <CustomModal
              onClose={() => setModalWarningOpen(false)}
              open={modalWarningOpen}
              title="Payment sucessful"
              content="Lorem ipsum dolor sit amet consectur adipisicing elit. Consequatur."
              primaryButtonTitle="Go back to dashboard"
              primaryButtonOnClick={() => console.log('clicou no botao')}
              showSecondaryButton={true}
              secondaryButtonTitle="Cancel"
              classification="warning"
            />
            <CustomModal
              onClose={() => setModalLinedOpen(false)}
              open={modalLinedOpen}
              title="Payment sucessful"
              content="Lorem ipsum dolor sit amet consectur adipisicing elit."
              primaryButtonTitle="Deactivate"
              primaryButtonOnClick={() => console.log('clicou no botao')}
              showSecondaryButton={true}
              secondaryButtonTitle="Cancel"
              classification="success"
              lined
            />
            <CustomModal
              onClose={() => setModalLinedWarningOpen(false)}
              open={modalLinedWarningOpen}
              title="Payment sucessful"
              content="Lorem ipsum dolor sit amet consectur adipisicing elit."
              primaryButtonTitle="Deactivate"
              primaryButtonOnClick={() => console.log('clicou no botao')}
              secondaryButtonTitle="Cancel"
              classification="warning"
              lined
            />
          </div>
          <div style={{ display: 'flex', marginTop: '30px', gap: '20px' }}>
            <CustomTextInput
              value={inputValue}
              onChange={(event: any) => setInputValue(event.target.value)}
              label="Nome"
              helperText="Informe seu nome completo"
              maxLength={100}
              minWidth={343}
            />
          </div>
          <div style={{ display: 'flex', marginTop: '30px', gap: '20px' }}>
            <CustomTextInput
              multiline={true}
              rows={5}
              value={inputValue}
              onChange={(event: any) => setInputValue(event.target.value)}
              label="Nome"
              maxLength={100}
              minWidth={343}
            />
          </div>
          <div style={{ display: 'flex', marginTop: '30px', gap: '20px' }}>
            <CustomSelect
              onChange={(event: any) => setSelectValue(event.target.value)}
              value={selectValue}
              options={selectOptions}
              minWidth={343}
            />
          </div>
          <div style={{ display: 'flex', gap: '20px' }}>
            <CustomButton classification="primary" disabled uppercase={false} title="Button text" />
            <CustomButton classification="secondary" disabled uppercase={false} title="Button text" />
          </div>
          <div style={{ display: 'flex', marginTop: '30px', gap: '20px' }}>
            <CustomizedBadge color="blue">Badge</CustomizedBadge>
            <CustomizedBadge color="green">Badge</CustomizedBadge>
            <CustomizedBadge color="yellow">Badge</CustomizedBadge>
            <CustomizedBadge color="red">Badge</CustomizedBadge>
            <CustomizedBadge color="gray">Badge</CustomizedBadge>
            <CustomizedBadge color="blue" showCloseIcon={true}>
              Badge
            </CustomizedBadge>
          </div>
          <div style={{ display: 'flex', marginTop: '30px', gap: '20px' }}>
            <CustomToggle selected={toggleOne} onChange={() => setToggleOne(!toggleOne)} />
            <CustomToggle selected={toggleTwo} onChange={() => setToggleTwo(!toggleTwo)} />
          </div>
          <div style={{ display: 'flex', marginTop: '30px', gap: '20px' }}>
            <CustomCheckbox
              checked={checkboxChecked}
              onclick={() => setCheckboxChecked(!checkboxChecked)}
              label="Comments"
              description="Get notified when someones posts a comment on a posting."
            />
          </div>
          <div style={{ display: 'flex', marginTop: '30px', gap: '20px' }}>
            <CustomRadio
              onChange={(event: any) => setRadioValue(event.target.value)}
              selectedValue={radioValue}
              options={radioOptions}
            />
          </div>
          <div style={{ display: 'flex', marginTop: '30px', gap: '20px' }}>
            <RadioList
              onChange={(event: any) => setRadioListValue(event.target.value)}
              selectedValue={radioListValue}
              options={radioListOptions}
            />
          </div>
        </div>
      </>
    </>
  );
};

export default Components;
