import React from 'react';

import { Container, ContentContainer } from './styles';

interface IContentProps {
  expandAsideMenu: boolean;
}

const Content: React.FC<IContentProps> = ({ expandAsideMenu, children }) => (
  <Container expandAsideMenu={expandAsideMenu}>
    <ContentContainer>{children}</ContentContainer>
  </Container>
);

export default Content;
