import * as React from 'react';

import {
  AnswersContainer,
  StyledContainer,
  StyledLabel,
  AnswerLabel,
  StyledFormControlLabel,
  StyledRadioButton,
} from './styles';
import { RadioGroup } from '@mui/material';

interface IQuestion {
  question: string;
  answers: { description: string; score: number }[];
  onChange: (event: any) => void;
  selectedValue: string | undefined | null | number;
}

const Question: React.FC<IQuestion> = ({ question, answers, onChange, selectedValue }) => {
  return (
    <StyledContainer>
      <StyledLabel>{question}</StyledLabel>
      <AnswersContainer>
        <RadioGroup>
          {answers.map((item) => (
            <StyledFormControlLabel
              key={item.description}
              onClick={onChange}
              control={
                <StyledRadioButton
                  size={'small'}
                  value={answers[answers.indexOf(item)].description}
                  checked={selectedValue ? selectedValue === answers[answers.indexOf(item)].description : false}
                />
              }
              label={<AnswerLabel>{answers[answers.indexOf(item)].description}</AnswerLabel>}
            />
          ))}
        </RadioGroup>
      </AnswersContainer>
    </StyledContainer>
  );
};

export default Question;
