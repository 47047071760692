import { IUserInput } from '../interfaces/input/user';
import axiosInstance from '../shared/util/axiosInstance';

export const patchUserFirstAccessRepository = async () => {
  const result: any = await axiosInstance
    .patch(`/userFirstAccess`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

export const patchUserShowOnRankingRepository = async () => {
  const result: any = await axiosInstance
    .patch(`/userShowOnRanking`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

export const patchUserSignatureRepository = async (reason: string) => {
  const body = {
    cancelReason: reason,
  };
  const result: any = await axiosInstance
    .patch(`/updateUserStatus`, body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

export const userSignUpRepository = async (userData: IUserInput) => {
  const result: any = await axiosInstance
    .post(`/user`, userData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

export const getUserRepository = async () => {
  const result: any = await axiosInstance
    .get(`/user`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

export const updateUserAnsweredCustomTestsRepository = async () => {
  const result: any = await axiosInstance
    .patch(`/updateUserAnsweredCustomTests`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

export const updateUserAnsweredDefaultTestsRepository = async () => {
  const result: any = await axiosInstance
    .patch(`/updateUserAnsweredDefaultTests`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

export const updateUserDataRepository = async (userData: any) => {
  const result: any = await axiosInstance
    .patch(`/user`, userData)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return result;
};
