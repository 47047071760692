import * as React from 'react';

import Avatar from '@mui/material/Avatar';

interface ICustomAvatarProps {
  size: number;
  src?: string;
}

const CustomAvatar: React.FC<ICustomAvatarProps> = ({ size, src }) => {
  return <Avatar src={src} sx={{ width: size, height: size }} />;
};

export default CustomAvatar;
