import React from 'react';

import { Box, Skeleton } from '@mui/material';
import { defaultColors, mobileBreakPoint } from '../../../../styles/theme';

const UserDataSkeleton: React.FC = () => {
  const isMobile = window.screen.width <= mobileBreakPoint;

  return (
    <>
      <Box mt={'18px'} display={'flex'} flexDirection={'column'} gap={'16px'}>
        <Skeleton
          sx={{ bgcolor: defaultColors.purple.light, borderRadius: '12px' }}
          variant="rectangular"
          animation="wave"
          width={isMobile ? '100%' : '75%'}
          height={'42px'}
        />
        <Skeleton
          sx={{ bgcolor: defaultColors.purple.light, borderRadius: '12px' }}
          variant="rectangular"
          animation="wave"
          width={isMobile ? '100%' : '65%'}
          height={'42px'}
        />
        <Skeleton
          sx={{ bgcolor: defaultColors.purple.light, borderRadius: '12px' }}
          variant="rectangular"
          animation="wave"
          width={isMobile ? '100%' : '20%'}
          height={'42px'}
        />
        <Skeleton
          sx={{ bgcolor: defaultColors.purple.light, borderRadius: '12px' }}
          variant="rectangular"
          animation="wave"
          width={isMobile ? '100%' : '50%'}
          height={'42px'}
        />
        <Skeleton
          sx={{ bgcolor: defaultColors.purple.light, borderRadius: '12px' }}
          variant="rectangular"
          animation="wave"
          width={isMobile ? '100%' : '35%'}
          height={'42px'}
        />
        <Skeleton
          sx={{ bgcolor: defaultColors.purple.light, borderRadius: '12px' }}
          variant="rectangular"
          animation="wave"
          width={isMobile ? '100%' : '70%'}
          height={'42px'}
        />
        <Box mt={'12px'}>
          <Skeleton
            sx={{ bgcolor: defaultColors.purple.light, borderRadius: '12px' }}
            variant="rectangular"
            animation="wave"
            width={isMobile ? '100%' : '55%'}
            height={'24px'}
          />
        </Box>
      </Box>
    </>
  );
};

export default UserDataSkeleton;
