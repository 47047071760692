import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

export const StyledContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  justifyContent: 'space-between',
  height: '100%',
  width: '100%',
  paddingTop: '20%',
  paddingLeft: '25%',
  paddingRight: '25%',
  paddingBottom: '25px',

  '@media (max-width: 768px)': {
    paddingLeft: '10%',
    paddingRight: '10%',
    paddingBottom: '30px',
  },
}));

export const StyledTitle = styled(Typography)((props) => ({
  fontSize: '28px',
  fontStyle: 'normal',
  fontWeight: '600',
  color: props.theme.palette.purple.main,

  '@media (max-width: 768px)': {
    marginTop: '25%',
    fontSize: '16px',
  },
}));

export const InputsContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  marginTop: '32px',
  marginBottom: '16px',
}));

export const StyledLink = styled('a')((props) => ({
  cursor: 'pointer',
  color: props.theme.palette.purple.main,
}));

export const ButtonsContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  marginTop: '25%',
  justifyContent: 'center',
  width: '75%',
}));
