import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Theme from './styles/theme';
import Router from './routes/router';
import CssBaseline from '@mui/material/CssBaseline';
import { setupAxiosInterceptors } from './shared/util/axiosConfig';
import { ThemeProvider } from '@mui/material/styles';
import { AuthenticationProvider } from './contexts/authentication';
import { ToastProvider } from './contexts/toast';
import { NotificationProvider } from './contexts/notification';
import { UserProvider } from './contexts/user';
import { MissionsProvider } from './contexts/missions';

function App() {
  function logout(): void {
    console.log('logout function');
  }

  setupAxiosInterceptors(logout);

  return (
    <>
      <BrowserRouter basename="/">
        <MissionsProvider>
          <UserProvider>
            <NotificationProvider>
              <ToastProvider>
                <AuthenticationProvider>
                  <ThemeProvider theme={Theme}>
                    <CssBaseline />
                    <Router />
                  </ThemeProvider>
                </AuthenticationProvider>
              </ToastProvider>
            </NotificationProvider>
          </UserProvider>
        </MissionsProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
