import * as React from 'react';

import { StyledSwitch } from './styles';

interface ICustomToggle {
  onChange?: () => void;
  selected: boolean;
}

const CustomToggle: React.FC<ICustomToggle> = ({ onChange, selected }) => {
  return <StyledSwitch checked={selected} onChange={onChange} />;
};

export default CustomToggle;
