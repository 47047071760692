import * as React from 'react';

import { Box, RadioGroup } from '@mui/material';

import { StyledFormControlLabel, StyledLabel, StyledRadioButton } from './styles';

interface IOption {
  value: string;
  label: string;
}

interface ICustomRadioProps {
  options: IOption[];
  selectedValue: string | undefined;
  onChange: (event: any) => void;
}

const CustomRadio: React.FC<ICustomRadioProps> = ({ options, selectedValue, onChange }) => {
  return (
    <Box display={'flex'} flexDirection={'column'}>
      <RadioGroup>
        {options.map((option) => (
          <StyledFormControlLabel
            key={option.value}
            onClick={onChange}
            value={option.value}
            control={<StyledRadioButton size={'small'} value={option.value} checked={selectedValue === option.value} />}
            label={<StyledLabel>{option.label}</StyledLabel>}
          />
        ))}
      </RadioGroup>
    </Box>
  );
};

export default CustomRadio;
