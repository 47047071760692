import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface IStyledCardProps {
  selected: boolean;
}

export const StyledSmallCard = styled(Box)<IStyledCardProps>((props) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '17px 13px',
  gap: '10px',
  background: props.selected ? props.theme.palette.purple.main : props.theme.palette.white.main,
  border: `1px solid ${props.theme.palette.gray.light}`,
  borderRadius: '6px',
  cursor: 'pointer',
  maxHeight: '48px',
}));

export const ContentSmallCard = styled(Typography)<IStyledCardProps>((props) => ({
  fontWeight: props.selected ? 700 : 400,
  color: props.selected ? props.theme.palette.white.main : props.theme.palette.textColor,
  fontSize: '14px',
  lineHeight: '14px',
}));

export const StyledMediumCard = styled(Box)<IStyledCardProps>((props) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '18px 15px',
  gap: '10px',
  background: props.theme.palette.white.main,
  border: props.selected
    ? `1.5px solid ${props.theme.palette.purple.main}`
    : `1px solid ${props.theme.palette.gray.light}`,
  borderRadius: '6px',
  cursor: 'pointer',
  maxHeight: '70px',
}));

export const StyledLeftTitle = styled(Typography)((props) => ({
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '14px',
  color: props.theme.palette.textColor,
}));

export const StyledMediumCardRightTitle = styled(Typography)((props) => ({
  fontWeight: 600,
  fontSize: '18px',
  lineHeight: '18px',
  color: props.theme.palette.textColor,
}));

export const StyledLeftContent = styled(Typography)((props) => ({
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '18px',
  color: props.theme.palette.gray.main,
}));

export const StyledMediumCardRightContent = styled(Typography)((props) => ({
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '14px',
  color: props.theme.palette.gray.main,
}));

export const StyledLargeCard = styled(Box)<IStyledCardProps>((props) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '17px 16px',
  gap: '10px',
  background: props.theme.palette.white.main,
  border: props.selected
    ? `1.5px solid ${props.theme.palette.purple.main}`
    : `1px solid ${props.theme.palette.gray.light}`,
  borderRadius: '6px',
  cursor: 'pointer',
  maxWidth: '175px',
  flexDirection: 'column',
}));

export const StyledLargeContent = styled(Typography)((props) => ({
  marginTop: '2px',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '14px',
  color: props.theme.palette.textColor,
}));
