import * as React from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

interface StyledTabProps {
  label: string;
}

export const Container = styled(Box)((props) => ({
  display: 'flex',

  '@media (max-width: 768px)': {
    justifyContent: 'center',
    marginTop: '-16px',
  },
}));

export const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs {...props} variant="scrollable" TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))((props) => ({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    padding: '0px',
    margin: '0px',
    top: 40,
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    padding: '0px',
    margin: '0px',
    backgroundColor: props.theme.palette.purple.main,
    border: '0px',
  },
}));

export const StyledTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)((props) => ({
  textTransform: 'none',
  fontSize: '14px',
  color: props.theme.palette.gray.main,
  padding: '0px',
  margin: '0px',

  '@media (max-width: 768px)': {
    fontSize: '12px',
  },

  '&.Mui-selected': {
    color: props.theme.palette.purple.main,
    fontWeight: 500,
  },

  '&.Mui-focusVisible': {
    backgroundColor: props.theme.palette.purple.main,
  },

  '& + &': {
    marginLeft: '32px',

    '@media (max-width: 768px)': {
      marginLeft: '0px',
    },
  },
}));
