import axiosInstance from './axiosInstance';
import { tokenKey } from './constants';

export const setupAxiosInterceptors = (logout: () => void) => {
  axiosInstance.interceptors.request.use(async (config) => {
    const token = localStorage.getItem(tokenKey);
    if (token && config && config.headers)
      config.headers = {
        Authorization: `Bearer ${token}`,
      };

    return config;
  });
};
