import { Alert, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { defaultColors } from '../../../styles/theme';

export const getBackgroundColor = (severity: string | undefined) => {
  switch (severity) {
    case 'error':
      return defaultColors.red.light;
    case 'warning':
      return defaultColors.yellow.light;
    case 'info':
      return defaultColors.purple.light;
    case 'success':
      return defaultColors.green.light;
    default:
      return defaultColors.gray.light;
  }
};

export const getTextColor = (severity: string | undefined) => {
  switch (severity) {
    case 'error':
      return defaultColors.red.main;
    case 'warning':
      return defaultColors.yellow.main;
    case 'info':
      return defaultColors.purple.main;
    case 'success':
      return defaultColors.green.main;
    default:
      return defaultColors.textColor;
  }
};

interface IStyledAlertProps {
  hasDescription: boolean;
}

export const StyledAlert = styled(Alert)<IStyledAlertProps>((props) => ({
  borderRadius: '12px',
  display: 'flex',
  alignItems: props.hasDescription ? 'start' : 'center',
  background: getBackgroundColor(props.severity),
  color: getTextColor(props.severity),
  '& .MuiAlert-icon': {
    color: getTextColor(props.severity),
  },
}));

export const StyledTitle = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: '12px',
}));

export const StyledDescription = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: '11px',
  marginTop: '4px',
}));
