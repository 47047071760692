import React, { useEffect, useState } from 'react';

import PageStructure from '../../../shared/components/pageStructure';
import { Box } from '@mui/material';
import CustomButton from '../../../shared/components/button';
import { ButtonsContainer } from './styles';
import { getSuitabilityQuizService, updateSuitabilityQuizService } from '../../../services/suitabilityQuiz';
import Question from '../components/question';
import Error from '../../../shared/components/error';
import { SuitabilityEnum, scoreToBeBold, userSuitabilityKey } from '../../../shared/util/constants';
import CustomAlert from '../../../shared/components/alert';
import CustomSnackBar from '../../../shared/components/toast';
import UserSuitability from '../../../shared/components/userSuitability';
import QuestionSkeleton from '../../../shared/components/questionSkeleton';

const SuitabilityQuiz: React.FC = () => {
  const [suitabilityQuiz, setSuitabilityQuiz] = useState<any[]>([]);
  const [userSuitabilityQuiz, setUserSuitabilityQuiz] = useState<any[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState<any>();
  const [snackOpen, setSnackOpen] = useState(false);
  const [showUserSuitability, setShowUserSuitability] = useState(false);
  const [userSuitability, setUserSuitability] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const getSuitabilityQuiz = async () => {
    const response = await getSuitabilityQuizService();

    if (response.status === 200) {
      const suitabilityQuiz = response.data.result;

      setSuitabilityQuiz(suitabilityQuiz);
      setUserSuitabilityQuiz(suitabilityQuiz);
    } else {
      setErrorMessage(
        'Ops! Parece que algo deu errado e não conseguimos carregar o teste de perfil agora. Provavelmente é algo momentâneo e estamos trabalhando para resolver. Em breve tudo estará normalizado.',
      );
    }
  };

  const handleNextClick = async () => {
    if (suitabilityQuiz.indexOf(currentQuestion) === suitabilityQuiz.length - 1) {
      setIsLoading(true);
      let score = 0;
      for (const question of userSuitabilityQuiz) {
        const selectedAnswer = question.answers.find((item: any) => item.selected);
        if (selectedAnswer) {
          score = score + selectedAnswer.score;
        }
      }

      const newSuitability = score > scoreToBeBold ? SuitabilityEnum.bold.value : SuitabilityEnum.conservative.value;

      const response = await updateSuitabilityQuizService(newSuitability);

      if (response.status === 201) {
        setShowUserSuitability(true);
        setUserSuitability(response.data.result.suitability);
        localStorage.setItem(userSuitabilityKey, response.data.result.suitability);
      } else {
        setSnackOpen(true);
      }
      setIsLoading(false);

      return;
    }
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleBackClick = () => {
    setCurrentQuestionIndex(currentQuestionIndex - 1);
  };

  const handleRadioChange = (event: any) => {
    const updatedUserSuitabilityQuiz = [];
    for (const item of userSuitabilityQuiz) {
      let updatedItem;
      if (item.question === currentQuestion.question) {
        updatedItem = {
          question: item.question,
          answers: item.answers.map((answer: any) => {
            return {
              ...answer,
              selected: answer.description === event.target.value,
            };
          }),
        };
      } else {
        updatedItem = {
          question: item.question,
          answers: item.answers,
        };
      }
      updatedUserSuitabilityQuiz.push(updatedItem);
    }
    setUserSuitabilityQuiz(updatedUserSuitabilityQuiz);
  };

  const getSelectedValueFromUserQuiz = () => {
    if (userSuitabilityQuiz.length > 0) {
      const userAnsweredQuestion = userSuitabilityQuiz.find((item) => item.question === currentQuestion.question);

      const selectedValue = userAnsweredQuestion.answers.find((item: any) => item.selected === true);

      if (selectedValue?.selected) {
        return selectedValue.description;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  };

  useEffect(() => {
    getSuitabilityQuiz();
  }, []);

  useEffect(() => {
    if (suitabilityQuiz.length > 0) {
      setCurrentQuestion(suitabilityQuiz[currentQuestionIndex]);
    }
  }, [suitabilityQuiz, currentQuestionIndex]);

  return (
    <Box height={'100%'}>
      <PageStructure
        title="Teste de perfil"
        footerContent={
          !errorMessage && !showUserSuitability ? (
            <ButtonsContainer sx={{ paddingBottom: '24px' }}>
              <CustomButton
                classification="secondary"
                uppercase={false}
                title="Voltar"
                onClick={() => handleBackClick()}
                disabled={suitabilityQuiz.indexOf(currentQuestion) === 0 || suitabilityQuiz.length === 0}
              />
              <CustomButton
                classification="primary"
                uppercase={false}
                title={suitabilityQuiz.indexOf(currentQuestion) === suitabilityQuiz.length - 1 ? 'Concluir' : 'Avançar'}
                onClick={() => handleNextClick()}
                disabled={!getSelectedValueFromUserQuiz()}
                isLoading={isLoading}
              />
            </ButtonsContainer>
          ) : (
            <></>
          )
        }
      >
        <Box mt={'24px'} mb={'24px'}>
          {userSuitabilityQuiz.length === 0 && !errorMessage ? (
            <QuestionSkeleton />
          ) : errorMessage ? (
            <Error message={errorMessage} />
          ) : showUserSuitability ? (
            <UserSuitability showSecondaryButton={false} suitability={userSuitability} />
          ) : (
            currentQuestion && (
              <Question
                question={currentQuestion?.question}
                answers={currentQuestion?.answers}
                onChange={(event) => handleRadioChange(event)}
                selectedValue={getSelectedValueFromUserQuiz()}
              />
            )
          )}
        </Box>
        <CustomSnackBar isOpen={snackOpen}>
          <CustomAlert
            showLeftIcon={true}
            showCloseIcon={true}
            severity={'error'}
            title={'Ops! Não foi possível atualizar seu perfil agora. Tente novamente mais tarde.'}
            onClose={() => setSnackOpen(false)}
          />
        </CustomSnackBar>
      </PageStructure>
    </Box>
  );
};

export default SuitabilityQuiz;
