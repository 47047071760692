import React from 'react';

import { Box, Skeleton } from '@mui/material';
import { defaultColors } from '../../../../../../styles/theme';

const skeletonNubmer = 8;

const SkeletonAchievements: React.FC = () => {
  return (
    <>
      {Array(skeletonNubmer)
        .fill(1)
        .map((index) => (
          <Box key={index} display={'flex'} flexDirection={'column'} gap="18px" alignItems={'center'}>
            <Skeleton
              sx={{ bgcolor: defaultColors.purple.light, borderRadius: '8px' }}
              variant="rectangular"
              animation="wave"
              width={150}
              height={125}
            />
            <Box display={'flex'} flexDirection={'column'} gap="8px" alignItems={'center'}>
              <Skeleton
                sx={{ bgcolor: defaultColors.purple.light, borderRadius: '8px' }}
                variant="rectangular"
                animation="wave"
                width={118}
                height={12}
              />
              <Skeleton
                sx={{ bgcolor: defaultColors.purple.light, borderRadius: '8px' }}
                variant="rectangular"
                animation="wave"
                width={60}
                height={12}
              />
            </Box>
          </Box>
        ))}
    </>
  );
};

export default SkeletonAchievements;
