import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { defaultShadow } from '../../../styles/theme';

interface IModalProps {
  maxWidth?: string;
}

export const StyledModalDefault = styled(Box)<IModalProps>((props) => ({
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '32px',
  backgroundColor: props.theme.palette.white.main,
  borderRadius: '14px',
  boxShadow: defaultShadow,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  minWidth: '332px',
  maxWidth: props.maxWidth ? props.maxWidth : 'none',
}));

export const StyledModalLined = styled(Box)((props) => ({
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '32px',
  backgroundColor: props.theme.palette.white.main,
  borderRadius: '14px',
  boxShadow: defaultShadow,
  minWidth: '536px',
}));

export const StyledModalTitle = styled(Typography)((props) => ({
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '20px',
  color: props.theme.palette.textColor,
}));

export const StyledModalContent = styled(Typography)((props) => ({
  marginTop: '12px',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '24px',
  color: props.theme.palette.gray.main,
}));

export const StyledButtonsContainer = styled(Box)(() => ({
  marginTop: '24px',
  display: 'flex',
  gap: '32px',
  width: '100%',
}));

export const StyledButtonsContainerLined = styled(Box)(() => ({
  marginTop: '24px',
  display: 'flex',
  gap: '32px',
  width: '55%',
  justifyContent: 'flex-end',
}));
