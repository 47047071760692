import { IAnsweredQuestionInput } from '../interfaces/input/answeredQuestion';
import axiosInstance from '../shared/util/axiosInstance';

export const postAnsweredQuestionRepository = async (answeredQuestion: IAnsweredQuestionInput) => {
  const result: any = await axiosInstance
    .post(`/answerQuestion`, answeredQuestion)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

export const getAnsweredQuestionsRepository = async () => {
  const result: any = await axiosInstance
    .get(`/getAnsweredQuestions`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

export const updateUserAnsweredQuestionsRepository = async () => {
  const result: any = await axiosInstance
    .patch(`/updateUserAnsweredQuestions`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return result;
};
