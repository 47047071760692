import { Box, FormControlLabel, Radio, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledRadioButton = styled(Radio)((props) => ({
  color: props.theme.palette.gray.main,
  '&.Mui-checked': {
    color: props.theme.palette.purple.main,
  },
}));

export const StyledLabel = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '14px',
}));

export const StyledDescription = styled(Typography)((props) => ({
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '14px',
  color: props.theme.palette.textColor,
  marginTop: '6px',
}));

export const StyledFormControlLabel = styled(FormControlLabel)((props) => ({
  alignItems: 'center',
  display: 'flex',
  paddingBottom: '16px',
  margin: '0px',
  '& + &': {
    borderTop: '1px solid',
    paddingTop: '16px',
    borderColor: props.theme.palette.gray.light,
  },
}));

export const StyledLabelContainer = styled(Box)(() => ({
  width: '343px',
}));
