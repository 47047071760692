import React, { useState } from 'react';
import { createContext } from 'react';

interface IUserContext {
  userData: any;
  setUserData: any;
}

interface IUserData {
  userData: any;
  setUserData: any;
}

export const UserContext = createContext<IUserContext>({} as IUserContext);

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [userData, setUserData] = useState<IUserData>();
  return (
    <UserContext.Provider value={{ userData: userData, setUserData: setUserData }}>
      <>{children}</>
    </UserContext.Provider>
  );
};
