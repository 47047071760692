import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { defaultColors } from '../../../styles/theme';
import CloseIcon from '../../../images/icons/close';

export interface StyledBadgeProps {
  color: string;
}

export const getFontColor = (colorParam: any) => {
  switch (colorParam) {
    case 'purple':
      return defaultColors.purple.main;
    case 'red':
      return defaultColors.red.main;
    case 'yellow':
      return defaultColors.yellow.main;
    case 'green':
      return defaultColors.green.main;
    case 'gray':
      return defaultColors.gray.main;
    default:
      return defaultColors.white.main;
  }
};

export const getBackgroundColor = (colorParam: any) => {
  switch (colorParam) {
    case 'purple':
      return defaultColors.purple.light;
    case 'red':
      return defaultColors.red.light;
    case 'yellow':
      return defaultColors.yellow.light;
    case 'green':
      return defaultColors.green.light;
    case 'gray':
      return defaultColors.gray.light;
    default:
      return defaultColors.white.main;
  }
};

export const StyledBadge = styled(Box)<StyledBadgeProps>((props) => ({
  padding: '6px 12px',
  background: getBackgroundColor(props.color),
  display: 'flex',
  borderRadius: '100px',
  height: '22px',
  fontWeight: '400',
  fontSize: '10px',
  lineHeight: '10px',
  color: getFontColor(props.color),
}));

export const StyledCloseIcon = styled(CloseIcon)(() => ({
  marginLeft: '6px',
  cursor: 'pointer',
}));
