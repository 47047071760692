import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

export const StyledBreadcrumbItem = styled(Chip)((props) => ({
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '14px',
  color: props.theme.palette.gray.main,
  background: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textDecoration: 'underline',
  textAlign: 'center',
  cursor: 'pointer',
})) as typeof Chip;
