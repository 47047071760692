import axiosInstance from '../shared/util/axiosInstance';

export const getUserNotificationsRepository = async () => {
  const result: any = await axiosInstance
    .get(`/getUserNotifications`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

export const updateUserNotificationsRepository = async (notificationIds: string[]) => {
  const body = {
    notificationIds: notificationIds,
  };
  const result: any = await axiosInstance
    .patch(`/updateUserNotifications`, body)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return result;
};
