import * as React from 'react';

import { Pagination } from '@mui/material';

import { StyledPaginationItem } from './styles';

interface ICustomPaginationProps {
  count: number;
  onChange?: (event: any, page: number) => void;
}

const CustomPagination: React.FC<ICustomPaginationProps> = ({ count, onChange }) => {
  return (
    <Pagination
      count={count}
      renderItem={(item) => <StyledPaginationItem {...item} />}
      onChange={onChange}
    />
  );
};

export default CustomPagination;
