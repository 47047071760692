import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { defaultShadow } from '../../../styles/theme';

interface IStyledContainer {
  imagePath: string;
}

export const StyledContainer = styled(Box)<IStyledContainer>((props) => ({
  backgroundColor: props.theme.palette.white.main,
  boxShadow: defaultShadow,
  display: 'flex',
  flexDirection: 'column',
  padding: '16px 24px',
  borderRadius: '16px',
  backgroundImage: `url(${props.imagePath})`,
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: 'right',
  backgroundPositionY: '-10px',
  backgroundSize: '40%',
}));

export const StyledSpan = styled(Typography)((props) => ({
  color: props.theme.palette.textColor,
  fontSize: '12px',
  fontWeight: 500,
  //   letterSpacing: '0.5px',
}));

export const DaysLeft = styled(Typography)((props) => ({
  color: props.theme.palette.purple.main,
  fontSize: '28px',
  fontWeight: 600,
  marginBottom: '-6px',
  marginTop: '-6px',
}));
