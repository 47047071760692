import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface ITimeTag {
  isSelected: boolean;
}

export const Header = styled(Box)(() => ({
  padding: '5% 10%',
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',

  '@media (max-width: 768px)': {
    padding: '10% 10%',
  },
}));

export const Container = styled(Box)(() => ({
  width: '45vw',
  padding: '15% 10%',
  height: '100%',
  justifyContent: 'space-between',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '24px',

  '@media (max-width: 768px)': {
    width: '100vw',
    padding: '0% 10%',
  },
}));

export const TimeTagsContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '16px',
  marginBottom: '16px',
  gap: '12px',
  userSelect: 'none',

  '@media (max-width: 768px)': {
    marginTop: '16px',
    marginBottom: '20%',
    flexDirection: 'column',
  },
}));

export const Title = styled(Typography)(() => ({
  fontSize: '36px',
  fontWeight: 400,
  marginBottom: '7%',

  '@media (max-width: 768px)': {
    fontSize: '24px',
  },
}));

export const Span = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: 400,

  '@media (max-width: 768px)': {
    fontSize: '14px',
  },
}));

export const TimeTag = styled(Typography)<ITimeTag>((props) => ({
  fontSize: '12px',
  color: props.isSelected ? props.theme.palette.white.main : props.theme.palette.gray.dark,
  backgroundColor: props.isSelected ? props.theme.palette.purple.main : 'transparent',
  padding: '4px 6px',
  border: `1px solid ${props.theme.palette.gray.main}`,
  borderRadius: '10px',
  cursor: 'pointer',
  fontWeight: 400,
  justifyContent: 'center',
  textAlign: 'center',
}));
