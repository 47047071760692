import {
  acquireAchievementToUserRepository,
  getAchievementsRepository,
  getUserAchievementsRepository,
} from '../repositories/achievements';

export const getAchievementsService = async () => {
  return await getAchievementsRepository();
};

export const getUserAchievementsService = async () => {
  return await getUserAchievementsRepository();
};

export const acquireAchievementToUserService = async (achievementsIds: string[]) => {
  return await acquireAchievementToUserRepository(achievementsIds);
};
