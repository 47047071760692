import * as React from 'react';

import { Box } from '@mui/material';
import Modal from '@mui/material/Modal';

import { StyledButtonsContainer, StyledModalDefault, StyledModalContent, StyledModalTitle } from './styles';
import CustomButton from '../../../../shared/components/button';
import { useEffect, useState } from 'react';
import CustomTextInput from '../../../../shared/components/textInput';

interface ICustomModalProps {
  title: string;
  primaryButtonTitle: string;
  open: boolean;
  onClose: () => void;
  primaryButtonOnClick: () => void;
  showSecondaryButton?: boolean;
  secondaryButtonTitle?: string;
  maxWidth?: string;
  secondaryButtonOnClick: () => void;
}

const ChangePasswordModal: React.FC<ICustomModalProps> = ({
  title,
  primaryButtonTitle,
  open,
  onClose,
  primaryButtonOnClick,
  showSecondaryButton,
  secondaryButtonTitle,
  secondaryButtonOnClick,
  maxWidth,
}) => {
  const [previousPassword, setPreviousPassword] = useState<string>('');
  const [previousPasswordError, setPreviousPasswordError] = useState<string>();
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordError, setNewPasswordError] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>();

  const handlePreviousPasswordChange = (event: any) => {
    const previousPassword = event.target.value;

    if (!previousPassword) {
      setPreviousPasswordError('A senha antiga é obrigatória');
    } else {
      setPreviousPasswordError(undefined);
    }

    setPreviousPassword(previousPassword);
  };

  const handlePasswordChange = (event: any) => {
    const password = event.target.value;

    if (!password || password.length < 6) {
      setNewPasswordError('A nova senha deve ter no mínimo 06 caracteres');
    } else {
      setNewPasswordError(undefined);
    }

    setNewPassword(password);
  };

  const handleConfirmPasswordChange = (event: any) => {
    const password = event.target.value;

    if (!password || password.length < 6) {
      setConfirmPasswordError('A nova senha deve ter no mínimo 06 caracteres');
    } else if (confirmPassword !== newPassword) {
      setConfirmPasswordError('Os valores informados são diferentes');
    } else {
      setConfirmPasswordError(undefined);
    }

    setConfirmPassword(password);
  };

  const handlePreviousPasswordBlur = () => {
    if (!previousPassword) {
      setPreviousPasswordError('A senha antiga é obrigatória');
    } else {
      setPreviousPasswordError(undefined);
    }
  };

  const handlePasswordBlur = () => {
    if (!newPassword) {
      setNewPasswordError('A nova senha é obrigatória');
    } else {
      setNewPasswordError(undefined);
    }
  };

  const handleConfirmPasswordBlur = () => {
    if (!confirmPassword) {
      setConfirmPasswordError('O campo de confirmação é obrigatório');
    } else if (confirmPassword !== newPassword) {
      setConfirmPasswordError('Os valores informados são diferentes');
    } else {
      setConfirmPasswordError(undefined);
    }
  };

  const handleModalClose = () => {
    setPreviousPasswordError(undefined);
    setNewPasswordError(undefined);
    setConfirmPasswordError(undefined);
    setPreviousPassword('');
    setNewPassword('');
    setConfirmPassword('');
    onClose();
    secondaryButtonOnClick();
  };

  useEffect(() => {
    if (confirmPassword !== newPassword) {
      setConfirmPasswordError('Os valores informados são diferentes');
    } else {
      setConfirmPasswordError(undefined);
    }
  }, [confirmPassword, newPassword]);

  return (
    <div>
      <Modal open={open} onClose={() => handleModalClose()}>
        <StyledModalDefault maxWidth={maxWidth}>
          <StyledModalTitle>{title}</StyledModalTitle>
          <Box display={'flex'} mt={'24px'} flexDirection={'column'} gap={'6px'}>
            <CustomTextInput
              fullWidth
              value={previousPassword}
              placeholder="Senha antiga*"
              maxLength={15}
              onChange={(event: any) => handlePreviousPasswordChange(event)}
              errorMessage={previousPasswordError}
              onBlur={() => handlePreviousPasswordBlur()}
              type="password"
            />
            <CustomTextInput
              fullWidth
              value={newPassword}
              placeholder="Nova senha*"
              maxLength={15}
              onChange={(event: any) => handlePasswordChange(event)}
              errorMessage={newPasswordError}
              onBlur={() => handlePasswordBlur()}
              type="password"
            />
            <CustomTextInput
              fullWidth
              value={confirmPassword}
              placeholder="Confirme a nova senha*"
              maxLength={15}
              onChange={(event: any) => handleConfirmPasswordChange(event)}
              errorMessage={confirmPasswordError}
              onBlur={() => handleConfirmPasswordBlur()}
              type="password"
            />
          </Box>
          <StyledButtonsContainer justifyContent="center">
            {showSecondaryButton && (
              <CustomButton
                classification="secondary"
                uppercase={false}
                title={secondaryButtonTitle}
                onClick={() => handleModalClose()}
                fullWidth={showSecondaryButton}
              />
            )}
            <CustomButton
              classification="primary"
              uppercase={false}
              title={primaryButtonTitle}
              onClick={primaryButtonOnClick}
              fullWidth={showSecondaryButton}
            />
          </StyledButtonsContainer>
        </StyledModalDefault>
      </Modal>
    </div>
  );
};

export default ChangePasswordModal;
