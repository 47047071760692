import axiosInstance from '../shared/util/axiosInstance';

export const getOneQuestionRepository = async () => {
  const result: any = await axiosInstance
    .get(`/getOneQuestion`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

export const getQuestionsRepository = async (questionsQuantity: string) => {
  const result: any = await axiosInstance
    .get(`/getQuestions?questionsQuantity=${questionsQuantity}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return result;
};
