import * as React from 'react';

import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';

import {
  StyledStep,
  StyledActiveIcon,
  StyledConnector,
  StyledStepContainer,
  StyledLabel,
  StyledContent,
} from './styles';
import { defaultColors } from '../../../styles/theme';
import CheckIcon from '../../../images/icons/check';

interface IStep {
  label: string;
  content?: string;
}

interface ICustomStepperProps {
  activeStep: number;
  width: number;
  steps: IStep[];
  orientation: 'vertical' | 'horizontal';
  showLabel?: boolean;
  showContent?: boolean;
}

const CustomStepper: React.FC<ICustomStepperProps> = ({
  activeStep,
  width,
  steps,
  orientation,
  showLabel,
  showContent,
}) => {
  const stepsLabelArray: string[] = steps.map((step) => step.label);
  const isActive = (param: string) => {
    return stepsLabelArray.findIndex((stepsLabelArray) => stepsLabelArray === param) === activeStep;
  };
  const isCompleted = (param: string) => {
    return stepsLabelArray.findIndex((stepsLabelArray) => stepsLabelArray === param) < activeStep;
  };

  return (
    <Stack width={width}>
      <Stepper
        orientation={orientation}
        activeStep={activeStep}
        connector={<StyledConnector orientation={orientation} />}
      >
        {stepsLabelArray.map((step) => (
          <StyledStepContainer orientation={orientation} key={step}>
            <StyledStep active={isActive(step)} completed={isCompleted(step)}>
              {isActive(step) ? (
                <StyledActiveIcon />
              ) : (
                isCompleted(step) && <CheckIcon fill={defaultColors.white.main} />
              )}
            </StyledStep>
            <Box display={'flex'} flexDirection={'column'}>
              {showLabel && (
                <StyledLabel active={isActive(step)} completed={isCompleted(step)}>
                  {step}
                </StyledLabel>
              )}
              {showContent && <StyledContent>{steps.find((s) => s.label === step)?.content}</StyledContent>}
            </Box>
          </StyledStepContainer>
        ))}
      </Stepper>
    </Stack>
  );
};

export default CustomStepper;
