import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './privateRoute';
import { routes } from './routes';

const Router: React.FC = () => {
  return (
    <Routes>
      {Object.values(routes).map((route) =>
        route.private ? (
          <Route key={route.path} path={route.path} element={<PrivateRoute>{route.component}</PrivateRoute>} />
        ) : (
          <Route key={route.path} path={route.path} element={route.component} />
        ),
      )}
      <Route path={'*'} element={<Navigate to={routes.login.path ?? ''} />} />
    </Routes>
  );
};

export default Router;
