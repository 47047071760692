import React, { useState, createContext } from 'react';

interface INotificationContext {
  userNotifications: any[];
  setUserNotifications: any;
}

export const NotificationContext = createContext<INotificationContext>({} as INotificationContext);

export const NotificationProvider = ({ children }: { children: React.ReactNode }) => {
  const [userNotifications, setUserNotifications] = useState<any[]>([]);
  return (
    <NotificationContext.Provider value={{ userNotifications, setUserNotifications }}>
      {children}
    </NotificationContext.Provider>
  );
};
