/* eslint-disable react/react-in-jsx-scope */
import DashboardIcon from '../images/icons/aside/dashboard';
import ExitIcon from '../images/icons/aside/exit';
import FreeModeIcon from '../images/icons/aside/freeMode';
import HelpIcon from '../images/icons/aside/help';
import ProfileIcon from '../images/icons/aside/profile';
import RankingIcon from '../images/icons/aside/ranking';
import TestsIcon from '../images/icons/aside/tests';
import Components from '../pages/components/views';
import Dashboard from '../pages/dashboard/views';
import FreeMode from '../pages/freeMode/views';
import Help from '../pages/help/views';
import Login from '../pages/login/views';
import Profile from '../pages/profile/views';
import Ranking from '../pages/ranking/views';
import SuitabilityQuiz from '../pages/suitabilityQuiz/views';
import Tests from '../pages/tests/views';

interface IRoute {
  name: string;
  path?: string;
  component?: React.ReactElement;
  showOnAsideMenu: boolean;
  private: boolean;
  icon?: React.ReactElement;
  labelAsideMenu?: string;
}

interface IRoutes {
  [key: string]: IRoute;
}

export const routes: IRoutes = {
  login: {
    name: 'login',
    path: '/login',
    component: <Login />,
    private: false,
    showOnAsideMenu: false,
  },
  components: {
    name: 'components',
    path: '/componentes',
    component: <Components />,
    private: false,
    showOnAsideMenu: false,
  },
  dashboard: {
    name: 'dashboard',
    path: '/dashboard',
    component: <Dashboard />,
    private: true,
    showOnAsideMenu: true,
    icon: <DashboardIcon fill="white" />,
    labelAsideMenu: 'Dashboard',
  },
  tests: {
    name: 'tests',
    path: '/simulados',
    component: <Tests />,
    private: true,
    showOnAsideMenu: true,
    icon: <TestsIcon fill="white" />,
    labelAsideMenu: 'Simulados',
  },
  freeMode: {
    name: 'freeMode',
    path: '/modo-livre',
    component: <FreeMode />,
    private: true,
    showOnAsideMenu: true,
    icon: <FreeModeIcon fill="white" />,
    labelAsideMenu: 'Modo livre',
  },
  // ranking: {
  //   name: 'ranking',
  //   path: '/ranking',
  //   component: <Ranking />,
  //   private: true,
  //   showOnAsideMenu: true,
  //   icon: <RankingIcon fill="white" />,
  //   labelAsideMenu: 'Ranking',
  // },
  profile: {
    name: 'profile',
    path: '/perfil',
    component: <Profile />,
    private: true,
    showOnAsideMenu: true,
    icon: <ProfileIcon fill="white" />,
    labelAsideMenu: 'Perfil',
  },
  // help: {
  //   name: 'help',
  //   path: '/ajuda',
  //   component: <Help />,
  //   private: true,
  //   showOnAsideMenu: true,
  //   icon: <HelpIcon fill="white" />,
  //   labelAsideMenu: 'Ajuda',
  // },
  suitabilityQuiz: {
    name: 'suitabilityQuiz',
    path: '/teste-de-perfil',
    component: <SuitabilityQuiz />,
    private: true,
    showOnAsideMenu: false,
  },
  exit: {
    name: 'exit',
    private: true,
    showOnAsideMenu: true,
    icon: <ExitIcon fill="white" />,
    labelAsideMenu: 'Sair',
  },
};

export const profileTabOptions = {
  general: {
    tabId: 0,
    param: 'informacoes-gerais',
  },
  profileTest: {
    tabId: 1,
    param: 'teste-de-perfil',
  },
  signature: {
    tabId: 2,
    param: 'assinatura',
  },
};

export const dashboarTabOptions = {
  general: {
    tabId: 0,
    param: 'informacoes-gerais',
  },
  achievements: {
    tabId: 1,
    param: 'conquistas',
  },
  missions: {
    tabId: 2,
    param: 'missoes',
  },
  historyOfQuestions: {
    tabId: 3,
    param: 'historico-de-questoes',
  },
};
