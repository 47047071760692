import * as React from 'react';

import { Box } from '@mui/material';

import {
  StyledCheckbox,
  StyledLabel,
  StyledDescription,
  StyledContainer,
} from './styles';

interface ICustomCheckboxProps {
  checked: boolean;
  onclick?: () => void;
  label?: string;
  description?: string;
}

const CustomCheckbox: React.FC<ICustomCheckboxProps> = ({
  checked,
  onclick,
  label,
  description,
}) => {
  return (
    <StyledContainer onClick={onclick}>
      <Box display={'flex'} alignItems={'center'}>
        <StyledCheckbox size="small" checked={checked} />
        <StyledLabel >{label}</StyledLabel>
      </Box>
      <StyledDescription>{description}</StyledDescription>
    </StyledContainer>
  );
};

export default CustomCheckbox;
