import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  justifyContent: 'space-between',
  height: '100%',
  width: '100%',
  paddingTop: '25px',
  paddingLeft: '25%',
  paddingRight: '25%',
  paddingBottom: '25px',

  '@media (max-width: 768px)': {
    paddingLeft: '10%',
    paddingRight: '10%',
    paddingTop: '30%',
    paddingBottom: '30px',
  },
}));

export const StyledTitle = styled(Typography)((props) => ({
  fontSize: '32px',
  fontStyle: 'normal',
  fontWeight: '600',
  color: props.theme.palette.purple.main,

  '@media (max-width: 768px)': {
    marginTop: '10%',
    fontSize: '28px',
  },
}));

export const StyledSpan = styled(Typography)((props) => ({
  fontSize: '16px',
  fontStyle: 'normal',
  color: props.theme.palette.textColor,
}));

export const ButtonsContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  marginTop: '10%',
  marginBottom: '10%',
  justifyContent: 'center',
  width: '75%',
}));

export const StyledLink = styled('a')((props) => ({
  cursor: 'pointer',
  color: props.theme.palette.purple.main,
}));
