import { FormHelperText, MenuItem, Typography } from '@mui/material';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';

export const StyledSelect = styled(Select)((props) => ({
  height: '48px',
  background: props.theme.palette.white.main,
  border: props.error ? `0px solid ${props.theme.palette.red.main}` : `1px solid ${props.theme.palette.gray.light}`,
  borderRadius: '10px',
  fontWeight: 400,
  fontSize: '14px',
  color: props.theme.palette.gray.main,
  paddingLeft: '8px',
  '&.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      border: props.error
        ? `1px solid ${props.theme.palette.red.main}`
        : `1px solid ${props.theme.palette.purple.main}`,
    },
  },
  '&:hover': {
    '&& fieldset': {
      border: props.error ? `1px solid ${props.theme.palette.red.main}` : `1px solid ${props.theme.palette.gray.light}`,
    },
  },
}));

export const StyledLabel = styled(Typography)((props) => ({
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '14px',
  color: props.theme.palette.textColor,
  marginBottom: '6px',
}));

export const StyledMenuItem = styled(MenuItem)((props) => ({
  display: 'flex',
  justifyContent: 'space-between',
  background: props.selected ? props.theme.palette.purple.main : props.theme.palette.white.main,
  color: props.theme.palette.gray.main,
  paddingTop: '17px',
  paddingBottom: '17px',
  margin: '0px',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '14px',

  '&.MuiPaper-root': {
    backgroundColor: 'red',
  },
  '&.MuiButtonBase-root': {
    backgroundColor: props.theme.palette.white.main,
    '&.Mui-selected': {
      backgroundColor: props.theme.palette.purple.main,
      fontWeight: 700,
      fonSize: '14px',
      color: props.theme.palette.white.main,
    },
  },
  '&:hover': {
    backgroundColor: props.theme.palette.purple.main,
    fontWeight: 700,
    fonSize: '14px',
    color: props.theme.palette.white.main,
  },
}));

export const StyledErrorText = styled(FormHelperText)((props) => ({
  marginTop: '6px',
  marginLeft: '0px',
  fontWeight: 400,
  fontSize: '11px',
  lineHeight: '11px',
  color: props.theme.palette.red.main,
}));
