import React, { useContext, useEffect, useState } from 'react';

import { ButtonsContainer, StyledLink, InputsContainer, StyledContainer, StyledTitle } from './styles';
import CustomTextInput from '../../../../shared/components/textInput';
import CustomButton from '../../../../shared/components/button';
import { defaultColors, mobileBreakPoint } from '../../../../styles/theme';
import { Box } from '@mui/material';
import HelpIcon from '../../../../images/icons/help';
import {
  tokenKey,
  userFirstAccessKey,
  userIdKey,
  userNameStorage,
  userSuitabilityKey,
} from '../../../../shared/util/constants';
import { ILoginInput } from '../../../../interfaces/input/login';
import { postLoginService } from '../../../../services/login';
import { AuthenticationContext } from '../../../../contexts/authentication';
import { ToastContext } from '../../../../contexts/toast';
import LoginLogoMobile from '../../../../images/logo/login_mobile';

interface ILoginForm {
  handleShowSignUpForm: () => void;
  setIsAuthenticated: () => void;
}

const LoginForm: React.FC<ILoginForm> = ({ handleShowSignUpForm, setIsAuthenticated }) => {
  const isMobile = window.screen.width <= mobileBreakPoint;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { setUser } = useContext(AuthenticationContext);
  const { setMessage } = useContext(ToastContext);
  const [disableButton, setDisableButton] = useState(false);

  async function login() {
    setIsLoading(true);
    const input: ILoginInput = {
      email: email,
      password: password,
    };
    const login = await postLoginService(input);

    if (login.status === 200) {
      localStorage.setItem(tokenKey, login.data.result.token);
      localStorage.setItem(userNameStorage, login.data.result.userData.name);
      localStorage.setItem(userFirstAccessKey, login.data.result.userData.firstAccess);
      localStorage.setItem(userIdKey, login.data.result.userData.id);
      localStorage.setItem(userSuitabilityKey, login.data.result.userData.suitability);

      setUser(login.data.result.userData);
      setIsAuthenticated();
    } else {
      setMessage(undefined);
      setMessage({
        message: login.response.data.result,
        type: 'error',
      });
    }

    setIsLoading(false);
  }

  useEffect(() => {
    if (!email || !password) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [email, password]);

  return (
    <StyledContainer>
      <form>
        {isMobile && <LoginLogoMobile />}

        <StyledTitle>Faça seu login</StyledTitle>
        <InputsContainer>
          <CustomTextInput
            value={email}
            onChange={(event: any) => setEmail(event.target.value)}
            placeholder="E-mail"
            maxLength={100}
          />
          <CustomTextInput
            value={password}
            onChange={(event: any) => setPassword(event.target.value)}
            placeholder="Senha"
            maxLength={100}
            type="password"
          />
        </InputsContainer>

        {/* <StyledLink to={'/login'}>Esqueci a senha</StyledLink> */}

        {!isMobile && (
          <Box width={'100%'} justifyContent={'center'} display={'flex'}>
            <ButtonsContainer>
              <CustomButton
                classification="secondary"
                uppercase={false}
                title="Entrar"
                typeSubmit
                onClick={login}
                isLoading={isLoading}
                disabled={disableButton}
              />
              {/* <CustomButton
                classification="primary"
                uppercase={false}
                title="Criar conta"
                onClick={handleShowSignUpForm}
              /> */}
            </ButtonsContainer>
          </Box>
        )}
      </form>
      <Box display={'flex'} flexDirection={'column'}>
        {/* <StyledLink href={'https://api.whatsapp.com/send?phone=+5535992575433&text=Olá!'} target="_blank">
          <Box display={'flex'} sx={{ cursor: 'pointer' }} alignItems={'center'} justifyContent={'center'}>
            <HelpIcon fill={defaultColors.purple.main} style={{ marginRight: '4px' }} />
            ajuda
          </Box>
        </StyledLink> */}
        {isMobile && (
          <Box mt={'16px'} gap={'16px'} display={'flex'} flexDirection={'column'}>
            <CustomButton
              classification="secondary"
              uppercase={false}
              title="Entrar"
              typeSubmit
              onClick={login}
              isLoading={isLoading}
              disabled={disableButton}
            />
            {/* <CustomButton
              classification="primary"
              uppercase={false}
              title="Criar conta"
              onClick={handleShowSignUpForm}
            /> */}
          </Box>
        )}
      </Box>
    </StyledContainer>
  );
};

export default LoginForm;
