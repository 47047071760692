import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled(Box)((props) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '12px',
}));

export const ChartTitle = styled(Typography)((props) => ({
  color: props.theme.palette.textColor,
  marginTop: '16px',
  letterSpacing: '0.5px',
  fontSize: '12px',
  fontWeight: '600',

  '@media (max-width: 768px)': {
    fontSize: '10px',
    marginBottom: '8px',
    padding: '0px 8px',
  },
}));
