import React from 'react';

import { Box } from '@mui/material';
import { Container, Explanation } from './styles';
import FreeModePresentationImg from '../../../../images/illustrations/freeModePresentation';
import CustomButton from '../../../../shared/components/button';

interface IPresentation {
  onClickStart: () => void;
}

const Presentation: React.FC<IPresentation> = ({ onClickStart }) => {
  return (
    <Container>
      <FreeModePresentationImg />
      <Explanation>Responda questões aleatórias e pratique de uma forma mais leve e descompromissada.</Explanation>
      <Box mt={'18px'}>
        <CustomButton classification="primary" uppercase={false} title="Começar" onClick={onClickStart} />
      </Box>
    </Container>
  );
};

export default Presentation;
