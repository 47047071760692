import * as React from 'react';
import { SVGProps } from 'react';

const ArrowDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" {...props}>
    <path
      d="M2.34326 5.55373L7.99993 11.1206L13.6566 5.55373L12.7139 4.62537L7.99993 9.2652L3.28593 4.62537L2.34326 5.55373Z"
      fill={props.fill}
    />
  </svg>
);

export default ArrowDownIcon;
