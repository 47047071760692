import * as React from 'react';
import { SVGProps } from 'react';

const PlusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4.02173" y="0.5" width="2.95652" height="10" rx="1.47826" fill="white" stroke="white" />
    <rect
      x="10.5"
      y="4.02148"
      width="2.95652"
      height="10"
      rx="1.47826"
      transform="rotate(90 10.5 4.02148)"
      fill="white"
      stroke="white"
    />
  </svg>
);

export default PlusIcon;
