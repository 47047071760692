import * as React from 'react';

const CloseIcon = (props: any) => (
  <svg onClick={props.onClick} width={props.size} height={props.size} {...props}>
    <path
      d="M5 5.75 1.667 9.083c-.1.1-.22.15-.359.15a.516.516 0 0 1-.374-.167.516.516 0 0 1-.167-.374c0-.14.055-.264.167-.375L4.25 5 .917 1.666a.525.525 0 0 1 .017-.75.517.517 0 0 1 .374-.166c.14 0 .264.056.375.167L5 4.25 8.334.917a.526.526 0 0 1 .75 0c.11.11.166.236.166.375a.517.517 0 0 1-.167.374L5.751 5l3.332 3.334c.1.1.153.219.159.358a.489.489 0 0 1-.159.374.517.517 0 0 1-.375.168.516.516 0 0 1-.374-.168L5 5.75Z"
      fill={props.fill}
    />
  </svg>
);

export default CloseIcon;
