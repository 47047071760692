import * as React from 'react';
import { SVGProps } from 'react';

const FreeModeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={28} height={28} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.5 1.75H10.5V4.08333H17.5V1.75ZM12.8333 16.9167H15.1667V9.91667H12.8333V16.9167ZM22.2017 9.205L23.8583 7.54833C23.3567 6.95333 22.8083 6.39333 22.2133 5.90333L20.5567 7.56C18.7483 6.11333 16.4733 5.25 14 5.25C8.20167 5.25 3.5 9.95167 3.5 15.75C3.5 21.5483 8.19 26.25 14 26.25C19.81 26.25 24.5 21.5483 24.5 15.75C24.5 13.2767 23.6367 11.0017 22.2017 9.205ZM14 23.9167C9.485 23.9167 5.83333 20.265 5.83333 15.75C5.83333 11.235 9.485 7.58333 14 7.58333C18.515 7.58333 22.1667 11.235 22.1667 15.75C22.1667 20.265 18.515 23.9167 14 23.9167Z"
      fill={props.fill}
    />
  </svg>
);

export default FreeModeIcon;
