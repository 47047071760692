import React, { useContext, useEffect, useState } from 'react';
import { RouteProps, Navigate } from 'react-router-dom';
import { tokenKey, userFirstAccessKey } from '../shared/util/constants';
import Layout from '../shared/layout/layout';
import { AuthenticationContext } from '../contexts/authentication';
import FirstAccessDrawer from '../shared/components/firstAccessDrawer';
import { getUserNotificationsService } from '../services/communication';
import { NotificationContext } from '../contexts/notification';
import { getUserDataService } from '../services/user';
import { UserContext } from '../contexts/user';

interface PrivateRouteProps extends RouteProps {}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const isAuthenticated = !!localStorage.getItem(tokenKey);
  const { setUserData, userData } = useContext(UserContext);
  const { setUserNotifications } = useContext(NotificationContext);

  const userFirstAccess = userData?.firstAccess || parseInt(localStorage.getItem(userFirstAccessKey)!);
  const [firstAccessOpen, setFirstAccessOpen] = useState(userFirstAccess);

  const getUserNotifications = async () => {
    const response = await getUserNotificationsService();

    if (response.status === 200) {
      const notificationsResult = response.data.result;

      setUserNotifications(notificationsResult);
    }
  };

  const getUserData = async () => {
    const response = await getUserDataService();

    if (response.status === 200) {
      const user = response.data.result;
      setUserData(user);
    } else {
      const user = { error: true };
      setUserData(user);
    }
  };

  useEffect(() => {
    Promise.all([getUserNotifications(), getUserData()]);
  }, [children]);

  return !isAuthenticated ? (
    <Navigate to={'/login'} />
  ) : (
    <Layout>
      <>
        {children}
        {<FirstAccessDrawer isOpen={firstAccessOpen} onClose={() => setFirstAccessOpen(false)} />}
      </>
    </Layout>
  );
};

export default PrivateRoute;
