import * as React from 'react';
import { SVGProps } from 'react';

const ExitIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={28} height={28} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.8333 9.33333L18.1883 10.9783L20.0317 12.8333H10.5V15.1667H20.0317L18.1883 17.01L19.8333 18.6667L24.5 14L19.8333 9.33333ZM5.83333 5.83333H14V3.5H5.83333C4.55 3.5 3.5 4.55 3.5 5.83333V22.1667C3.5 23.45 4.55 24.5 5.83333 24.5H14V22.1667H5.83333V5.83333Z"
      fill={props.fill}
    />
  </svg>
);

export default ExitIcon;
