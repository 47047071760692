import React, { useEffect, useState } from 'react';
import { tokenKey, userNameStorage } from '../../util/constants';
import { routes } from '../../../routes/routes';

import { Container, MenuItemLink, LabelItem, ButtonSubItem } from './styles';
import { useLocation } from 'react-router-dom';
import { mobileBreakPoint } from '../../../styles/theme';

interface IAsideProps {
  expandAsideMenu: boolean;
  setExpandAsideMenu: (arg0: boolean) => void;
}

const Aside: React.FC<IAsideProps> = ({ expandAsideMenu, setExpandAsideMenu }) => {
  const [optionSelected, setOptionSelected] = useState<string>();
  const { pathname } = useLocation();
  const isMobile = window.screen.width <= mobileBreakPoint;

  function logout() {
    localStorage.removeItem(tokenKey);
    localStorage.removeItem(userNameStorage);
    localStorage.removeItem('optionSelected');
    window.location.reload();
  }

  const handleOptionSelected = (option: string) => {
    if (isMobile) {
      setExpandAsideMenu(!expandAsideMenu);
    }
    if (option === routes.exit.name) {
      logout();
      return;
    }
  };

  const getOptionFronRoute = (route: string) => {
    switch (route) {
      case routes.dashboard.path:
        return routes.dashboard.name;
      case routes.tests.path:
        return routes.tests.name;
      case routes.freeMode.path:
        return routes.freeMode.name;
      // case routes.ranking.path:
      //   return routes.ranking.name;
      case routes.profile.path:
        return routes.profile.name;
      case routes.help.path:
        return routes.help.name;
      default:
        return '';
    }
  };

  useEffect(() => {
    const option = getOptionFronRoute(pathname);

    setOptionSelected(option);
  }, [pathname]);

  return (
    <Container expandAsideMenu={expandAsideMenu}>
      {Object.values(routes).map(
        (route) =>
          route.showOnAsideMenu && (
            <div key={route.path}>
              <MenuItemLink to={route.path ?? ''}>
                <ButtonSubItem
                  selected={optionSelected === route.name}
                  onClick={() => handleOptionSelected(route.name)}
                >
                  {route.icon}
                  <LabelItem>{route.labelAsideMenu}</LabelItem>
                </ButtonSubItem>
              </MenuItemLink>
            </div>
          ),
      )}
    </Container>
  );
};

export default Aside;
