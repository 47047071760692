import React, { useState, createContext } from 'react';

interface IAuthenticationContext {
  user: any;
  setUser: any;
}

export const AuthenticationContext = createContext<IAuthenticationContext>({} as IAuthenticationContext);

export const AuthenticationProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState();
  return <AuthenticationContext.Provider value={{ user, setUser }}>{children}</AuthenticationContext.Provider>;
};
