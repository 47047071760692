import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

export const StyledContainer = styled(Box)((props) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
}));

export const StyledLabel = styled(Typography)((props) => ({
  fontSize: '12px',
  fontWeight: 400,
}));

export const StyledProgressBar = styled(LinearProgress)((props) => ({
  height: '10px',
  borderRadius: '5px',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: props.theme.palette.purple.light,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: '5px',
    backgroundColor: props.theme.palette.purple.main,
  },
}));
