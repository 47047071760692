import { Box, Checkbox, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledCheckbox = styled(Checkbox)((props) => ({
  color: props.theme.palette.gray.main,
  width: '12px',
  height: '12px',
  '&.Mui-checked': {
    color: props.theme.palette.purple.main,
  },
}));

export const StyledLabel = styled(Typography)((props) => ({
  marginLeft: '7px',
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '12px',
  color: props.theme.palette.textColor,
}));

export const StyledDescription = styled(Typography)((props) => ({
  marginLeft: '25px',
  marginTop: '6px',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '14px',
  color: props.theme.palette.textColor,
}));

export const StyledContainer = styled(Box)(() => ({
  cursor: 'pointer',
}));
