import React, { useContext, useEffect, useState } from 'react';

import PageStructure from '../../../shared/components/pageStructure';
import { Box } from '@mui/material';
import Question from '../../../shared/components/question';
import CustomButton from '../../../shared/components/button';
import { ButtonsContainer } from './styles';
import Presentation from '../components/presentation';
import { getOneQuestionService } from '../../../services/questions';
import Error from '../../../shared/components/error';
import * as bcrypt from 'bcryptjs';
import { SuitabilityEnum, correctAnswerKey } from '../../../shared/util/constants';
import QuestionSkeleton from '../../../shared/components/questionSkeleton';
import { postAnsweredQuestionService, updateUserAnsweredQuestionsService } from '../../../services/answeredQuestions';
import { IAnsweredQuestionInput } from '../../../interfaces/input/answeredQuestion';
import CustomSnackBar from '../../../shared/components/toast';
import CustomAlert from '../../../shared/components/alert';
import { UserContext } from '../../../contexts/user';
import { MissionStatus } from '../../dashboard/components/missions';
import { isExpiredDate } from '../../../shared/util/functions';
import { updateUserMissionsService } from '../../../services/mission';
import { acquireAchievementToUserService } from '../../../services/achievements';

const FreeMode = () => {
  const [radioValue, setRadioValue] = useState();
  const [showPresentation, setShowPresentation] = useState(true);
  const [answered, setAnswered] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState('');
  const [question, setQuestion] = useState<any>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [snackOpen, setSnackOpen] = useState(false);
  const [startMomment, setStartMomment] = useState<any>();
  const [endMomment, setEndMomment] = useState<any>();
  const [loadingAnswer, setLoadingAnswer] = useState(false);
  const [loadingQuestion, setLoadingQuestion] = useState(false);
  const { userData } = useContext(UserContext);

  const getOneQuestion = async () => {
    setLoadingQuestion(true);
    const response = await getOneQuestionService();

    setRadioValue(undefined);
    setAnswered(false);
    setQuestion(undefined);
    setCorrectAnswer('');

    if (response.status === 200) {
      const questionToShow = response.data.result;

      setStartMomment(new Date());
      setQuestion(questionToShow);
    } else {
      setErrorMessage(
        'Ops! Parece que algo deu errado e não conseguimos carregar o modo livre agora. Provavelmente é algo momentâneo e estamos trabalhando para resolver. Em breve tudo estará normalizado.',
      );
    }
    setLoadingQuestion(false);
  };

  const getMissionStatus = (mission: any) => {
    if (mission.isAccomplished) {
      return MissionStatus.acomplished;
    } else if (isExpiredDate(mission.expirationDate)) {
      return MissionStatus.expired;
    } else {
      return MissionStatus.pending;
    }
  };

  const handleAnswerQuestion = async () => {
    if (answered) {
      setLoadingAnswer(true);
      const answerPaylod: IAnsweredQuestionInput = {
        answerDate: new Date().toDateString(),
        answeredCorrectly: radioValue === correctAnswer,
        questionId: question.id,
        spendedTime: endMomment - startMomment,
      };

      const answeredQuestionResponse = await postAnsweredQuestionService(answerPaylod);

      if (answeredQuestionResponse.status === 201) {
        await Promise.all([getOneQuestion(), updateUserAnsweredQuestionsService()]);

        setLoadingAnswer(false);

        const userAnsweredQuestionsWeek = userData.weekData.answeredQuestionsOnFreeMode;

        //Lidando com a missão
        if (userData.suitability === SuitabilityEnum.conservative.value) {
          if (userAnsweredQuestionsWeek >= 10) {
            const missionId = 'f9c1c136-f759-4c97-94c6-774192322478';
            const mission = userData.missions.filter(
              (item: any) => getMissionStatus(item) === MissionStatus.pending && item.missionId === missionId,
            );

            if (mission.length > 0) {
              await updateUserMissionsService([mission[0].id]);
            }
          }
        } else {
          if (userAnsweredQuestionsWeek >= 10) {
            const missionId = 'fcbd22a1-e20f-45e1-b10e-8770bf439361';
            const mission = userData.missions.filter(
              (item: any) => getMissionStatus(item) === MissionStatus.pending && item.missionId === missionId,
            );

            if (mission.length > 0) {
              await updateUserMissionsService([mission[0].id]);
            }
          }
        }

        //Lidando com as conquistas
        const userAnsweredQuestions = userData.answeredQuestionsOnFreeMode;
        let achievementId: string | null = null;

        if (userAnsweredQuestions >= 0 && userAnsweredQuestions < 10) {
          achievementId = '3a35d388-065b-4233-afc2-57440b2e2c54';
        } else if (userAnsweredQuestions >= 10 && userAnsweredQuestions < 30) {
          achievementId = 'd27ce5d8-297d-408d-8d8c-a589e32ca57b';
        } else if (userAnsweredQuestions >= 30 && userAnsweredQuestions < 50) {
          achievementId = '1eaa530d-b0c0-416d-8fe6-40adcc19fd35';
        } else if (userAnsweredQuestions >= 50 && userAnsweredQuestions < 100) {
          achievementId = '4c7db0e2-316b-4626-8589-ce38b2849456';
        } else if (userAnsweredQuestions >= 100) {
          achievementId = '75e75200-12b7-4e37-82eb-2b2cdbd8fbe6';
        }
        const achievement = userData.acquiredAchievements.find((item: string) => item === achievementId);

        if (!achievement && !!achievementId) {
          await acquireAchievementToUserService([achievementId]);
        }
      } else {
        setSnackOpen(true);
      }

      setLoadingAnswer(false);

      return;
    }
    const fullCorrectAnswer = question.answers.find((item: any) => {
      if (bcrypt.compareSync(correctAnswerKey, item.isCorrect)) {
        return item;
      }
    });

    setCorrectAnswer(fullCorrectAnswer.description);
    setAnswered(true);
    setEndMomment(new Date());
  };

  useEffect(() => {
    getOneQuestion();
  }, []);

  return (
    <Box height={'100%'}>
      <PageStructure
        title="Modo livre"
        footerContent={
          !showPresentation && !errorMessage ? (
            <ButtonsContainer>
              <CustomButton
                classification="secondary"
                uppercase={false}
                title="Pular"
                onClick={() => getOneQuestion()}
                disabled={!question || answered || loadingQuestion}
              />
              <CustomButton
                classification="primary"
                uppercase={false}
                isLoading={loadingAnswer}
                title={answered ? 'Próxima' : 'Responder'}
                onClick={() => handleAnswerQuestion()}
                disabled={!radioValue}
              />
            </ButtonsContainer>
          ) : null
        }
      >
        <Box mt={'24px'}>
          {showPresentation ? (
            <Presentation onClickStart={() => setShowPresentation(false)} />
          ) : !errorMessage && !question ? (
            <QuestionSkeleton />
          ) : errorMessage ? (
            <Error message={errorMessage} />
          ) : (
            question && (
              <Question
                answered={answered}
                correctAnswer={correctAnswer}
                question={question.question}
                answers={question.answers}
                onChange={(event) => setRadioValue(event.target.value)}
                selectedValue={radioValue}
              />
            )
          )}
        </Box>
        <CustomSnackBar isOpen={snackOpen}>
          <CustomAlert
            showLeftIcon={true}
            showCloseIcon={true}
            severity={'error'}
            title={'Ops! Não foi possível registrar essa resposta. Tente novamente mais tarde.'}
            onClose={() => setSnackOpen(false)}
          />
        </CustomSnackBar>
      </PageStructure>
    </Box>
  );
};

export default FreeMode;
