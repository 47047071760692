import * as React from 'react';
import { SVGProps } from 'react';

const ProfileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={28} height={28} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14 6.88341C15.3533 6.88341 16.45 7.98008 16.45 9.33342C16.45 10.6867 15.3533 11.7834 14 11.7834C12.6466 11.7834 11.55 10.6867 11.55 9.33342C11.55 7.98008 12.6466 6.88341 14 6.88341ZM14 17.3834C17.465 17.3834 21.1166 19.0867 21.1166 19.8334V21.1167H6.88329V19.8334C6.88329 19.0867 10.535 17.3834 14 17.3834ZM14 4.66675C11.4216 4.66675 9.33329 6.75508 9.33329 9.33342C9.33329 11.9117 11.4216 14.0001 14 14.0001C16.5783 14.0001 18.6666 11.9117 18.6666 9.33342C18.6666 6.75508 16.5783 4.66675 14 4.66675ZM14 15.1667C10.885 15.1667 4.66663 16.7301 4.66663 19.8334V23.3334H23.3333V19.8334C23.3333 16.7301 17.115 15.1667 14 15.1667Z"
      fill={props.fill}
    />
  </svg>
);

export default ProfileIcon;
