import React, { useEffect, useState } from 'react';

import { ButtonsContainer, StyledLink, InputsContainer, StyledContainer, StyledTitle } from './styles';
import CustomTextInput from '../../../../shared/components/textInput';
import CustomButton from '../../../../shared/components/button';
import { defaultColors, mobileBreakPoint } from '../../../../styles/theme';
import { Box } from '@mui/material';
import HelpIcon from '../../../../images/icons/help';
import LoginLogoMobile from '../../../../images/logo/login_mobile';
import { isEmailValid, isPhoneNumberValid, formatPhone } from '../../../../shared/util/functions';
import CustomSelect from '../../../../shared/components/select';
import { educationOptions, ufs } from '../../../../shared/util/constants';
import { userSignUpService } from '../../../../services/user';
import { IUserInput } from '../../../../interfaces/input/user';
import CustomAlert from '../../../../shared/components/alert';
import CustomSnackBar from '../../../../shared/components/toast';

interface ISignUpForm {
  handleShowSignUpForm: () => void;
  handleShowNextStep: () => void;
}

const SignUpForm: React.FC<ISignUpForm> = ({ handleShowSignUpForm, handleShowNextStep }) => {
  const isMobile = window.screen.width <= mobileBreakPoint;
  const [disableButton, setDisableButton] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // states user data
  const [fullName, setFullName] = useState<string>('');
  const [nickName, setNickName] = useState<string>('');
  const [state, setState] = useState<string>('Estado*');
  const [education, setEducation] = useState<string>('Escolaridade*');
  const [email, setEmail] = useState<string>('');
  const [whatsapp, setWhatsapp] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  // states user data errors
  const [fullNameError, setFullNameError] = useState<string>();
  const [stateError, setStateError] = useState<string>();
  const [educationError, setEducationError] = useState<string>();
  const [emailError, setEmailError] = useState<string>();
  const [whatsappError, setWhatsappError] = useState<string>();
  const [passwordError, setPasswordError] = useState<string>();
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>();

  const handleUserSignUp = async () => {
    setIsLoading(true);

    const payload: IUserInput = {
      email: email,
      name: fullName,
      password: password,
      phoneNumber: whatsapp,
      state: state,
      scholarity: education,
      nickName: nickName,
    };
    const response = await userSignUpService(payload);

    if (response.status !== 201) {
      setSnackOpen(true);
    } else {
      handleShowNextStep();
    }
    setIsLoading(false);
  };

  const handleNameChange = (event: any) => {
    const name = event.target.value;

    const fullName = name.split(' ');
    const regex = /[^a-zà-ú]/gi;

    const fullNameToTest = name.replace(' ', '');

    if (fullName.length <= 1 || fullName[1] == '' || fullNameToTest.match(regex)) {
      setFullNameError('Nome inválido');
    } else {
      setFullNameError(undefined);
    }

    setFullName(name);
  };

  const handleNameBlur = () => {
    if (!fullName) {
      setFullNameError('O nome completo é obrigatório');
    } else {
      setFullNameError(undefined);
    }
  };

  const handleEmailBlur = () => {
    if (!email) {
      setEmailError('O e-mail é obrigatório');
    } else {
      setEmailError(undefined);
    }
  };

  const handleWhatsappBlur = () => {
    if (!whatsapp) {
      setWhatsappError('O whatsapp é obrigatório');
    } else {
      setWhatsappError(undefined);
    }
  };

  const handleEmailChange = (event: any) => {
    const email = event.target.value;

    if (!isEmailValid(email) || !email) {
      setEmailError('E-mail inválido');
    } else {
      setEmailError(undefined);
    }

    setEmail(email);
  };

  const handleWhatsappChange = (event: any) => {
    const whatsapp = event.target.value;

    if (!isPhoneNumberValid(whatsapp) || !whatsapp) {
      setWhatsappError('Whatsapp inválido');
    } else {
      setWhatsappError(undefined);
    }

    setWhatsapp(formatPhone(whatsapp));
  };

  const handlePasswordChange = (event: any) => {
    const password = event.target.value;

    if (!password || password.length < 6) {
      setPasswordError('A senha deve ter no mínimo 06 caracteres');
    } else {
      setPasswordError(undefined);
    }

    setPassword(password);
  };

  const handleConfirmPasswordChange = (event: any) => {
    const password = event.target.value;

    if (!password || password.length < 6) {
      setConfirmPasswordError('A senha deve ter no mínimo 06 caracteres');
    } else if (confirmPassword !== password) {
      setConfirmPasswordError('Os valores informados são diferentes');
    } else {
      setConfirmPasswordError(undefined);
    }

    setConfirmPassword(password);
  };

  const handlePasswordBlur = () => {
    if (!password) {
      setPasswordError('A senha é obrigatória');
    } else {
      setPasswordError(undefined);
    }
  };

  const handleConfirmPasswordBlur = () => {
    if (!confirmPassword) {
      setConfirmPasswordError('O campo de confirmação é obrigatório');
    } else if (confirmPassword !== password) {
      setConfirmPasswordError('Os valores informados são diferentes');
    } else {
      setConfirmPasswordError(undefined);
    }
  };

  const handleEducationBlur = () => {
    if (education === 'Escolaridade*') {
      setEducationError('A escolaridade é obrigatória');
    } else {
      setEducationError(undefined);
    }
  };

  const handleStateBlur = () => {
    if (state === 'Estado*') {
      setStateError('O estado é obrigatório');
    } else {
      setStateError(undefined);
    }
  };

  useEffect(() => {
    if (state === 'Estado*') {
      setStateError('O estado é obrigatório');
    } else {
      setStateError(undefined);
    }
  }, [state]);

  useEffect(() => {
    if (education === 'Escolaridade*') {
      setEducationError('A escolaridade é obrigatória');
    } else {
      setEducationError(undefined);
    }
  }, [education]);

  useEffect(() => {
    if (confirmPassword !== password) {
      setConfirmPasswordError('Os valores informados são diferentes');
    } else {
      setConfirmPasswordError(undefined);
    }
  }, [confirmPassword, password]);

  useEffect(() => {
    if (
      !fullName ||
      fullNameError ||
      stateError ||
      educationError ||
      !email ||
      emailError ||
      !whatsapp ||
      whatsappError ||
      !password ||
      passwordError ||
      !confirmPassword ||
      confirmPasswordError
    ) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [
    fullName,
    email,
    fullNameError,
    stateError,
    educationError,
    emailError,
    whatsapp,
    whatsappError,
    password,
    passwordError,
    confirmPassword,
    confirmPasswordError,
  ]);

  useEffect(() => {
    setStateError(undefined);
    setEducationError(undefined);
  }, []);

  return (
    <StyledContainer>
      <div>
        {isMobile && <LoginLogoMobile />}

        {!isMobile && <StyledTitle>Primeiro acesso</StyledTitle>}
        <form>
          <InputsContainer>
            <CustomTextInput
              value={fullName}
              onChange={(event: any) => handleNameChange(event)}
              placeholder="Nome completo*"
              maxLength={100}
              errorMessage={fullNameError}
              onBlur={() => handleNameBlur()}
            />
            <Box textAlign={'start'} mt={'4px'} mb={'4px'}>
              <CustomSelect
                fullWidth={true}
                onChange={(event: any) => setState(event.target.value)}
                value={state}
                options={ufs}
                errorMessage={stateError}
                onBlur={() => handleStateBlur()}
              />
            </Box>

            <Box textAlign={'start'} mt={'4px'} mb={'4px'}>
              <CustomSelect
                onChange={(event: any) => setEducation(event.target.value)}
                value={education}
                options={educationOptions}
                minWidth={'100%'}
                errorMessage={educationError}
                onBlur={() => handleEducationBlur()}
              />
            </Box>

            <CustomTextInput
              value={email}
              onChange={(event: any) => handleEmailChange(event)}
              placeholder="E-mail*"
              maxLength={100}
              errorMessage={emailError}
              onBlur={() => handleEmailBlur()}
            />
            <CustomTextInput
              value={whatsapp}
              onChange={(event: any) => handleWhatsappChange(event)}
              placeholder="Whatsapp*"
              maxLength={15}
              errorMessage={whatsappError}
              onBlur={() => handleWhatsappBlur()}
            />
            <CustomTextInput
              value={password}
              onChange={(event: any) => handlePasswordChange(event)}
              placeholder="Senha*"
              maxLength={15}
              type={'password'}
              errorMessage={passwordError}
              onBlur={() => handlePasswordBlur()}
            />
            <CustomTextInput
              value={confirmPassword}
              onChange={(event: any) => handleConfirmPasswordChange(event)}
              placeholder="Confirme sua senha*"
              maxLength={15}
              type={'password'}
              errorMessage={confirmPasswordError}
              onBlur={() => handleConfirmPasswordBlur()}
            />
          </InputsContainer>

          {!isMobile && (
            <Box width={'100%'} justifyContent={'center'} display={'flex'}>
              <ButtonsContainer>
                <CustomButton
                  classification="primary"
                  uppercase={false}
                  title="Cadastrar"
                  onClick={() => handleUserSignUp()}
                  disabled={disableButton}
                  isLoading={isLoading}
                />
                <CustomButton
                  classification="secondary"
                  uppercase={false}
                  title="Voltar"
                  onClick={handleShowSignUpForm}
                />
              </ButtonsContainer>
            </Box>
          )}
        </form>
      </div>
      <Box display={'flex'} flexDirection={'column'}>
        {isMobile && (
          <Box mt={'16px'} mb={'32px'} gap={'16px'} display={'flex'} flexDirection={'column'}>
            <CustomButton
              classification="primary"
              uppercase={false}
              title="Cadastrar"
              onClick={() => handleUserSignUp()}
              disabled={disableButton}
              isLoading={isLoading}
            />
            <CustomButton classification="secondary" uppercase={false} title="Voltar" onClick={handleShowSignUpForm} />
          </Box>
        )}
        {/* <StyledLink href={'https://api.whatsapp.com/send?phone=+5535992575433&text=Olá!'} target="_blank">
          <Box
            display={'flex'}
            sx={{ cursor: 'pointer', marginBottom: '16px' }}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <HelpIcon fill={defaultColors.purple.main} style={{ marginRight: '4px' }} />
            ajuda
          </Box>
        </StyledLink> */}
      </Box>
      <CustomSnackBar isOpen={snackOpen}>
        <CustomAlert
          showLeftIcon={true}
          showCloseIcon={true}
          severity={'error'}
          title={'Ops! Não conseguimos salvar seus dados agora.'}
          onClose={() => setSnackOpen(false)}
        />
      </CustomSnackBar>
    </StyledContainer>
  );
};

export default SignUpForm;
