import * as React from 'react';

import { StyledContainer, StyledSpan, DaysLeft } from './styles';

import calendar from '../../../images/pageStructureImages/calendar.png';

interface INexTestIndicator {
  dateString: string;
}

const NextTestIndicator: React.FC<INexTestIndicator> = ({ dateString }) => {
  function daysUntilDate(dateString: string) {
    // Convert the input date string to a Date object
    const inputDate = new Date(dateString);

    // Get today's date
    const today = new Date();

    // Calculate the difference in time (milliseconds) between the two dates
    const timeDifference = inputDate.getTime() - today.getTime();

    // Convert time difference from milliseconds to days
    const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

    return daysDifference;
  }

  function formatDateToDDMMYYYY(dateString: string) {
    // Convert the input date string to a Date object
    const inputDate = new Date(dateString);

    // Extract day, month, and year from the Date object
    const day = String(inputDate.getDate()).padStart(2, '0');
    const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = inputDate.getFullYear();

    // Create the formatted date string in "dd/mm/yyyy" format
    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  }
  return (
    <StyledContainer imagePath={calendar}>
      <DaysLeft>{daysUntilDate(dateString)} dias</DaysLeft>
      <StyledSpan>até o próximo exame - {formatDateToDDMMYYYY(dateString)}</StyledSpan>
    </StyledContainer>
  );
};

export default NextTestIndicator;
