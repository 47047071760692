import * as React from 'react';
import { SVGProps } from 'react';

const TestsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={28} height={28} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.16667 18.6666H16.3333V20.9999H8.16667V18.6666ZM8.16667 13.9999H19.8333V16.3333H8.16667V13.9999ZM8.16667 9.33325H19.8333V11.6666H8.16667V9.33325ZM22.1667 4.66659H17.29C16.8 3.31325 15.5167 2.33325 14 2.33325C12.4833 2.33325 11.2 3.31325 10.71 4.66659H5.83333C5.67 4.66659 5.51833 4.67825 5.36667 4.71325C4.91167 4.80659 4.50333 5.03992 4.18833 5.35492C3.97833 5.56492 3.80333 5.82159 3.68667 6.10159C3.57 6.36992 3.5 6.67325 3.5 6.99992V23.3333C3.5 23.6483 3.57 23.9633 3.68667 24.2433C3.80333 24.5233 3.97833 24.7683 4.18833 24.9899C4.50333 25.3049 4.91167 25.5382 5.36667 25.6316C5.51833 25.6549 5.67 25.6666 5.83333 25.6666H22.1667C23.45 25.6666 24.5 24.6166 24.5 23.3333V6.99992C24.5 5.71659 23.45 4.66659 22.1667 4.66659ZM14 4.37492C14.4783 4.37492 14.875 4.77159 14.875 5.24992C14.875 5.72825 14.4783 6.12492 14 6.12492C13.5217 6.12492 13.125 5.72825 13.125 5.24992C13.125 4.77159 13.5217 4.37492 14 4.37492ZM22.1667 23.3333H5.83333V6.99992H22.1667V23.3333Z"
      fill={props.fill}
    />
  </svg>
);

export default TestsIcon;
