import axiosInstance from '../shared/util/axiosInstance';

export const getUserMissionsRepository = async () => {
  const result: any = await axiosInstance
    .get(`/getUserMissions`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

export const updateUserMissionsRepository = async (missions: string[]) => {
  const result: any = await axiosInstance
    .patch(`/acquireMissionsToUser`, { missionsIds: missions })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return result;
};
