import axiosInstance from '../shared/util/axiosInstance';

export const getAchievementsRepository = async () => {
  const result: any = await axiosInstance
    .get(`/achievements`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

export const getUserAchievementsRepository = async () => {
  const result: any = await axiosInstance
    .get(`/getUserAchievements`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

export const acquireAchievementToUserRepository = async (achievementsIds: string[]) => {
  const result: any = await axiosInstance
    .post(`/acquireAchievementToUser`, { achievementsIds: achievementsIds })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return result;
};
