import * as React from 'react';
import { SVGProps } from 'react';

const DefaultTestImg = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={240} height={205} fill="none" {...props}>
    <path
      fill="#E8E8E8"
      d="M25.84 163.923h176.802c.375 0 .375-.593 0-.593H25.841c-.376 0-.376.593 0 .593ZM146.68 167.6h59.895c.376 0 .376-.593 0-.593H146.68c-.376 0-.376.593 0 .593ZM13.763 169.181h53.312c.376 0 .376-.593 0-.593H13.764c-.396 0-.396.593 0 .593ZM41.339 172.324h56.199c.375 0 .375-.593 0-.593H41.339c-.376 0-.376.593 0 .593ZM4.037 177.325H49.74c.375 0 .375-.593 0-.593H4.037c-.376 0-.376.593 0 .593ZM200.27 178.373h39.397c.376 0 .376-.593 0-.593H200.27c-.375 0-.375.593 0 .593ZM111.039 195.907h60.39c.376 0 .376-.593 0-.593h-60.39c-.375 0-.375.593 0 .593ZM141.244 200.117h53.689c.375 0 .375-.593 0-.593h-53.689c-.375 0-.375.593 0 .593Z"
    />
    <path
      fill="#000"
      d="M24.003 166.888h70.59c.77 0 .77-1.186 0-1.186h-70.59c-.752 0-.771 1.186 0 1.186ZM125.529 165.939h60.923c.771 0 .771-1.186 0-1.186h-60.923c-.771 0-.771 1.186 0 1.186ZM20.563 198.753h66.538c.77 0 .77-1.186 0-1.186H20.563c-.751 0-.751 1.186 0 1.186ZM.578 199.05h14.688c.77 0 .77-1.186 0-1.186H.577c-.77 0-.77 1.186 0 1.186ZM78.976 203.122h16.565c.771 0 .771-1.186 0-1.186H78.976c-.75 0-.75 1.186 0 1.186ZM125.213 200.928h11.247c.771 0 .771-1.187 0-1.187h-11.247c-.752 0-.752 1.187 0 1.187ZM133.653 205h54.677c.771 0 .771-1.186 0-1.186H133.653c-.771 0-.771 1.186 0 1.186ZM156.465 200.315h44.358c.771 0 .771-1.186 0-1.186h-44.358c-.771 0-.771 1.186 0 1.186ZM207.366 199.05h7.809c.77 0 .77-1.186 0-1.186h-7.809c-.751 0-.751 1.186 0 1.186ZM183.942 169.695h15.003c.771 0 .771-1.186 0-1.186h-15.003c-.751 0-.751 1.186 0 1.186Z"
    />
    <path
      fill="#E8E8E8"
      d="M26.355 163.211a34.667 34.667 0 0 1-3.519-13.62c-.02-.375-.613-.375-.593 0a35.491 35.491 0 0 0 3.598 13.917c.178.336.672.039.514-.297Z"
    />
    <path
      fill="#E8E8E8"
      d="M22.935 149.611c2.886.356 5.812.909 8.718.988 2.806.06 5.574-.553 8.302-1.146.376-.079.217-.653-.158-.573-2.807.612-5.634 1.245-8.52 1.107-2.787-.119-5.575-.633-8.322-.969-.396-.04-.396.553-.02.593Z"
    />
    <path
      fill="#E8E8E8"
      d="M39.54 149.334c-.099 4.705-1.087 9.33-2.866 13.699-.139.356.435.514.573.158a38.354 38.354 0 0 0 2.886-13.857c.02-.376-.573-.376-.593 0ZM26.138 149.393a18.723 18.723 0 0 1-2.333-3.341 20.358 20.358 0 0 1-.91-1.878c-.236-.573-1.086-2.985.633-2.629.198.04.455-.138.356-.356-.613-1.68-.988-3.419-1.384-5.159-.415-1.799-.85-3.598-1.047-5.436a16.12 16.12 0 0 1-.1-2.589c.02-.593.16-3.203 1.543-1.819.553.553.929 1.443 1.324 2.135.435.751.83 1.522 1.226 2.293 3.044 6.108 4.645 12.888 4.704 19.728 0 .375.594.375.594 0-.06-6.187-1.345-12.315-3.835-17.989a45.797 45.797 0 0 0-2.096-4.171c-.514-.889-1.146-2.51-2.174-2.925-2.432-.969-1.898 4.368-1.78 5.357.198 1.759.574 3.479.97 5.218.434 1.859.81 3.756 1.462 5.535l.356-.355c-.672-.139-1.305.138-1.621.771-.395.77-.099 1.68.198 2.431.81 2.076 1.996 3.953 3.44 5.634.296.257.71-.158.474-.455Z"
    />
    <path
      fill="#E8E8E8"
      d="M29.617 141.368c-.1-.751-.238-5.06 1.304-4.744.198.039.356-.079.376-.277.336-2.827.672-5.713 1.818-8.342.297-.692.653-1.384 1.088-1.996.375-.534 1.087-.752 1.502-.119.158.237.237.554.316.83.396 1.542.554 3.163.633 4.744.277 6.306-1.344 12.513-2.926 18.582-.099.376.475.534.573.158 1.918-7.294 3.895-14.964 2.53-22.535-.157-.909-.335-2.431-1.383-2.827-1.206-.454-1.977 1.186-2.412 2.017-1.542 2.925-1.937 6.266-2.312 9.488.118-.099.257-.198.375-.277-2.273-.474-2.214 4.033-2.056 5.318.04.356.633.375.574-.02ZM22.184 150.165a40.96 40.96 0 0 0 10.774 13.521c.296.237.711-.178.415-.415a40.186 40.186 0 0 1-10.675-13.403c-.158-.336-.672-.039-.514.297ZM26.691 149.987c1.878 2.055 3.776 4.131 5.653 6.187 1.582 1.739 3.124 3.657 5.22 4.803.335.178.632-.336.296-.514-2.155-1.166-3.736-3.202-5.357-4.981-1.8-1.977-3.598-3.934-5.397-5.911-.257-.276-.672.139-.415.416ZM32.582 150.54a34.73 34.73 0 0 0 6.049 6.464c.296.237.711-.178.415-.415a36.178 36.178 0 0 1-5.95-6.346c-.218-.296-.732 0-.514.297ZM37.346 149.829l2.194 2.49c.257.297.672-.138.415-.415l-2.194-2.49c-.257-.277-.672.138-.415.415ZM179.455 56.565v4.764c0 .376.593.376.593 0v-4.764c0-.375-.593-.375-.593 0Z"
    />
    <path
      fill="#E8E8E8"
      d="M179.752 56.861h41.808c.376 0 .376-.593 0-.593h-41.808c-.376 0-.376.593 0 .593ZM179.831 61.645h41.808c.375 0 .375-.593 0-.593h-41.808c-.376 0-.376.593 0 .593Z"
    />
    <path
      fill="#E8E8E8"
      d="M221.323 56.565v4.764c0 .376.593.376.593 0v-4.764c0-.375-.593-.375-.593 0ZM181.096 46.879c.573 3.498 1.305 7.155 4.052 9.646.277.257.712-.158.415-.415-2.668-2.412-3.34-5.99-3.894-9.37-.059-.395-.632-.217-.573.139Z"
    />
    <path
      fill="#E8E8E8"
      d="M181.471 46.76a199.29 199.29 0 0 0 18.186-.118c.376-.02.376-.613 0-.593-6.049.316-12.117.356-18.186.118-.375-.02-.375.574 0 .593Z"
    />
    <path
      fill="#E8E8E8"
      d="M199.579 46.543c.672 3.617-.673 7.353-3.46 9.725-.296.257.139.672.415.416a10.95 10.95 0 0 0 3.598-10.32c-.059-.355-.633-.197-.553.179ZM181.096 45.238a2.797 2.797 0 0 1-.613-1.206c-.039-.138-.198-1.225 0-1.364.475-.296 1.404 1.325 1.542 1.562.672 1.206 1.245 2.49 1.838 3.736a608.297 608.297 0 0 1 3.855 8.164c.158.356.672.04.514-.297-1.838-3.973-3.558-8.084-5.693-11.88-.316-.573-1.483-2.431-2.352-1.838-.455.316-.396 1.285-.336 1.72.098.711.395 1.304.85 1.858.197.276.632-.158.395-.455Z"
    />
    <path
      fill="#E8E8E8"
      d="M180.147 44.665a57.346 57.346 0 0 0 7.472 11.86c.237.297.652-.118.415-.415a55.295 55.295 0 0 1-7.373-11.742c-.159-.336-.673-.04-.514.297ZM179.989 42.826c.039-.02.059-.04.099-.059.257-.198.118-.633-.238-.534-.415.238-.692.119-.81-.336-.316-.197-.395-.415-.198-.632 0-.08.04-.158.04-.257-.02-.257-.119-.336-.317-.514-.217-.198-.316-.06-.454-.376-.158-.356 0-.198 0-.494 0-.198-.158-.494-.158-.613-.099-.099-.02-.198.217-.296.198-.396.336-.356.395.098.06.139.099.277.178.396.178.356.415.415.672.652.297.317.277.514.317.91.019.098 0 .197.039.276.119.198.653.376.415.712-.237.296.277.593.514.296.376-.494.198-.85-.178-1.245-.059-.06-.276-.06-.296-.119.02.04.119-.553.099-.612-.04-.277-.158-.416-.356-.593-.138-.139-.316-.178-.474-.317-.257-.237-.317-.474-.455-.75-.139-.278-.257-.554-.534-.712-.474-.297-.988-.08-1.225.395-.119.217-.178.534-.119.77.02.12.119.238.158.337.138.415.059.237.02.514-.079.534-.079.731.395 1.047.139.1.415.12.475.337-.04-.139-.218.573-.218.494-.039.237.04.474.158.672.396.652 1.186.652 1.858.474l-.237-.533c-.039.02-.059.04-.099.059-.296.257 0 .77.317.553Z"
    />
    <path
      fill="#E8E8E8"
      d="M182.203 43.103c-.079-.197-.376-.89-.257-1.067.296-.138.593-.257.889-.395.139.158.257.316.356.514.316.514.534 1.107.771 1.66a79.694 79.694 0 0 1 1.582 4.072 68.24 68.24 0 0 1 2.194 7.848c.079.376.652.217.573-.158a67.12 67.12 0 0 0-3.657-11.663c-.415-.988-.949-3.025-2.273-3.202-1.384-.178-1.028 1.818-.732 2.55.119.336.692.197.554-.159Z"
    />
    <path
      fill="#E8E8E8"
      d="M181.491 40.672c-.672-.455-.83-.87-.514-1.246a.839.839 0 0 0 .178-.277c.02-.059.04-.118.04-.197-.04-.455-.85-.534-.198-1.206.099-.119.118-.316 0-.415-.435-.475-.455-.712-.059-.692.197-.099.276-.02.237.237.079.119.059.297.138.435.198.376.751.553.811 1.008.059.356-.238.712-.178 1.068.059.375.632.711.217 1.146-.257.277.158.692.415.415.257-.276.514-.593.435-1.008-.059-.336-.415-.454-.455-.81-.059-.416.257-.732.099-1.186-.178-.534-.711-.672-.909-1.226-.198-.514-.474-1.384-1.206-.89-.613.416-.454 1.503.04 1.918v-.415c-.297.316-.475.711-.356 1.146.02.1.316.475.316.534 0 .158-.277.455-.316.652-.198.85.455 1.957 1.423 1.582.336-.139.198-.712-.158-.573ZM190.683 56.011c-.316-.553 1.325-1.581 1.641-2.036.237-.336.376-.672.534-1.048.316-.731.87-.612 1.542-.573.652.04 1.265-.158 1.7-.652.217-.238.356-.514.533-.791.593-.89 1.443-.692 2.274-.297.336.159.652-.336.296-.514-.909-.434-1.977-.79-2.747.04-.495.534-.653 1.324-1.443 1.562-.613.178-1.226-.218-1.839 0-.395.138-.573.395-.751.731-.415.751-.593 1.344-1.305 1.878-.652.494-1.482 1.028-.949 1.937.198.396.712.099.514-.237Z"
    />
    <path
      fill="#E8E8E8"
      d="M193.549 55.971c.929-.158 1.661-.652 2.313-1.324.83-.87 1.779-3.084 3.301-2.333.336.178.633-.336.297-.514-1.028-.533-2.016-.04-2.728.791-.988 1.146-1.68 2.53-3.321 2.827-.395.04-.237.613.138.553ZM193.687 52.117c.396-.612.653-1.265.732-2.016.059-.652-.119-1.127.395-1.7.198-.217.455-.356.653-.593.81-1.008.355-2.55 1.284-3.46-.118.02-.237.04-.355.04.375.218.435.653.731.91.277.257.672.296 1.008.454.751.356.455 1.285.455 1.997 0 .553.356 1.443-.099 1.838-.297.257.138.672.415.415.395-.356.435-.77.376-1.265-.079-.81.059-1.68-.079-2.47-.079-.515-.317-.93-.791-1.167-.474-.237-.87-.376-1.245-.79-.159-.179-.257-.317-.495-.436a.33.33 0 0 0-.355.04c-1.028 1.008-.495 2.629-1.463 3.637-.396.415-.85.633-.989 1.245-.098.416-.039.85-.079 1.246-.059.652-.276 1.206-.632 1.76-.178.335.336.632.533.315ZM186.73 61.566c.316.909.612 1.838.889 2.767.04.119.158.218.277.218 1.186 0 2.392 0 3.578.04.375.019.375-.574 0-.594-1.186-.04-2.392-.06-3.578-.04.099.08.198.139.277.218a34.679 34.679 0 0 0-.89-2.767c-.118-.356-.692-.198-.553.158Z"
    />
    <path
      fill="#E8E8E8"
      d="m192.106 61.427-.949 2.846c-.118.356.455.514.573.159l.949-2.847c.119-.376-.454-.534-.573-.158ZM207.762 56.407c.02-.494-.296-1.265.119-1.64.395-.337 1.324-.198 1.798-.198a255.566 255.566 0 0 1 7.987.099c.573.02 1.443-.12 1.976.079.732.257.495 1.028.653 1.62.099.376.672.218.573-.158-.158-.533.119-1.206-.277-1.68-.296-.356-.751-.376-1.166-.415-1.997-.158-4.033-.099-6.049-.119-.969-.02-1.957-.02-2.926-.02-.731 0-1.522-.078-2.253.06-.395.079-.83.237-1.048.613-.336.553.04 1.166 0 1.759 0 .376.593.376.613 0Z"
    />
    <path
      fill="#E8E8E8"
      d="M205.469 53.857c-.059-.238-.178-.712-.079-.93.217-.474.81-.454 1.225-.494.514-.04 1.028-.059 1.542-.059 1.167 0 2.313.08 3.479.08 1.088 0 2.135-.12 3.203-.218.494-.04.988-.1 1.482-.06.672.04.732.396.87 1.008.079.297.079.653.119.97.039.375.652.375.593 0-.139-1.068.039-2.333-1.226-2.531-.85-.139-1.739.04-2.57.119-.949.098-1.897.138-2.846.138-1.74-.02-3.558-.237-5.278.04-1.166.197-1.325 1.067-1.068 2.114.06.356.633.198.554-.177Z"
    />
    <path
      fill="#E8E8E8"
      d="M205.133 54.43c1.067.158 2.115.197 3.202.099.376-.04.376-.633 0-.594-1.008.1-2.036.08-3.044-.079-.375-.04-.534.534-.158.574ZM208.612 61.685c.317.85.593 1.72.85 2.59.04.138.158.217.277.217 1.127.04 2.57.336 3.657-.04.356-.118.198-.692-.158-.573-.989.356-2.451.06-3.499.02.099.079.198.138.277.217a39.8 39.8 0 0 0-.85-2.59c-.119-.355-.692-.217-.554.159Z"
    />
    <path
      fill="#E8E8E8"
      d="M213.771 61.586c-.257.83-.514 1.641-.771 2.471-.118.356.455.514.574.158.257-.83.514-1.64.771-2.47.118-.356-.455-.514-.574-.159ZM179.455 22.841v4.764c0 .376.593.376.593 0v-4.764c0-.395-.593-.395-.593 0Z"
    />
    <path
      fill="#E8E8E8"
      d="M179.752 23.138h41.808c.376 0 .376-.593 0-.593h-41.808c-.376 0-.376.593 0 .593ZM179.831 27.901h41.808c.375 0 .375-.593 0-.593h-41.808c-.376 0-.376.593 0 .593Z"
    />
    <path
      fill="#E8E8E8"
      d="M221.342 22.841v4.764c0 .376.593.376.593 0v-4.764c0-.395-.593-.395-.593 0ZM186.73 27.822c.316.91.612 1.839.889 2.768.04.118.158.217.277.217 1.186 0 2.392 0 3.578.04.375.02.375-.574 0-.593a71.987 71.987 0 0 0-3.578-.04c.099.08.198.139.277.218a34.69 34.69 0 0 0-.89-2.768c-.099-.356-.672-.198-.553.158Z"
    />
    <path
      fill="#E8E8E8"
      d="m192.126 27.684-.949 2.847c-.118.356.455.514.573.158l.949-2.846c.119-.376-.454-.534-.573-.159ZM208.612 27.942c.317.85.593 1.72.85 2.59.04.137.158.216.277.216 1.127.04 2.57.336 3.657-.039.356-.119.198-.692-.158-.573-.989.355-2.451.059-3.499.02.099.079.198.138.277.217-.257-.87-.534-1.74-.85-2.59-.099-.355-.672-.217-.554.159Z"
    />
    <path
      fill="#E8E8E8"
      d="M213.771 27.842c-.257.83-.514 1.641-.771 2.471-.118.356.455.514.574.159.257-.83.514-1.641.771-2.471.118-.356-.455-.514-.574-.159ZM181.037 6.177a181.322 181.322 0 0 0-.158 16.407c.019.376.612.376.593 0a181.392 181.392 0 0 1 .158-16.407c.02-.376-.573-.376-.593 0Z"
    />
    <path
      fill="#E8E8E8"
      d="M181.59 5.88a21.48 21.48 0 0 0 1.107-2.371c.118-.317.217-.91.455-1.107.257-.198.988-.277 1.304-.416.356-.138.198-.711-.158-.573a6.476 6.476 0 0 1-1.502.396c-.099.02-.178.118-.198.197a23.463 23.463 0 0 1-1.522 3.558c-.178.356.336.653.514.317ZM186.591 7.976a136.988 136.988 0 0 0-.355 14.529c.019.376.612.376.593 0-.139-4.843-.02-9.686.355-14.53.04-.375-.553-.375-.593 0ZM190.94 7.304a415.406 415.406 0 0 0-.277 14.905c0 .375.593.375.593 0 0-4.962.099-9.943.277-14.905 0-.375-.593-.375-.593 0Z"
    />
    <path
      fill="#E8E8E8"
      d="M184.535 1.69c.139-.277.237-.574.336-.87-.099.079-.197.138-.276.217.652-.02 1.482-.197 2.115-.098.237-.08.415 0 .514.257.019.177.059.336.079.513.059.337.118.692.178 1.028.059.376.632.218.573-.158-.099-.573-.04-1.878-.672-2.174-.317-.158-.771-.06-1.087-.04-.574.02-1.147.06-1.72.08-.139 0-.257.078-.277.217a4.6 4.6 0 0 1-.277.731c-.158.336.336.633.514.297Z"
    />
    <path
      fill="#E8E8E8"
      d="M187.54 2.896c.791.375 2.155.75 2.629 1.522.534.85.731 2.135.909 3.123.06.376.633.217.574-.158-.178-1.087-.416-2.49-1.048-3.44-.534-.79-1.937-1.166-2.768-1.561-.336-.158-.652.355-.296.514ZM181.056 13.037c1.839.138 3.697.237 5.535.276.376.02.376-.573 0-.593-1.858-.059-3.696-.138-5.535-.277-.395-.039-.395.554 0 .594ZM180.977 19.026c.949.396 1.898.277 2.906.218.771-.04 1.68.257 2.372-.119.336-.178.04-.692-.296-.514-.336.178-1.048.02-1.424.02-.316 0-.652.04-.968.06-.83.059-1.621.118-2.412-.218-.375-.158-.534.415-.178.553Z"
    />
    <path
      fill="#E8E8E8"
      d="M186.888 13.273c1.324-.098 2.648-.178 3.973-.276.376-.02.376-.613 0-.593-1.325.098-2.649.178-3.973.276-.376.04-.376.633 0 .593ZM186.512 19.244a24.01 24.01 0 0 0 4.27-.672c.375-.1.217-.672-.158-.573a20.515 20.515 0 0 1-4.112.652c-.376.02-.376.613 0 .593ZM182.321 5.683c1.483 0 2.946.06 4.408.119.376.02.376-.574 0-.593a81.988 81.988 0 0 0-4.408-.119c-.375 0-.375.593 0 .593ZM188.607 5.722h2.096c.375 0 .375-.593 0-.593h-2.096c-.375 0-.375.593 0 .593ZM203.176 22.426c-.118-.297-.02-.692.297-.81.355-.14.711.138.79.493.079.376.653.218.574-.158-.139-.652-.85-1.127-1.523-.909-.612.198-.948.949-.711 1.542.138.356.711.198.573-.158Z"
    />
    <path
      fill="#E8E8E8"
      d="M204.56 21.063c-.099-.198.118-.435.316-.435.277 0 .475.316.494.573.02.375.613.375.593 0-.039-.613-.553-1.265-1.225-1.147-.593.1-.969.771-.692 1.325.178.316.672.02.514-.317Z"
    />
    <path
      fill="#E8E8E8"
      d="M205.765 22.031c-.158-.573.673-.87.89-.296.138.355.712.197.573-.159-.197-.533-.751-.889-1.324-.711-.554.158-.85.79-.712 1.324.119.376.672.218.573-.158Z"
    />
    <path
      fill="#E8E8E8"
      d="M206.141 20.29c-.079-.039-.079-.157-.039-.236.059-.139.237-.159.375-.139.337.06.554.376.732.652.198.317.712.02.514-.296-.356-.573-.989-1.186-1.74-.87-.573.257-.692 1.048-.158 1.404.336.217.633-.297.316-.514Z"
    />
    <path
      fill="#E8E8E8"
      d="M207.466 21.576c-.06-.237.336-.277.474-.198.237.119.356.416.455.653.138.356.711.197.573-.158-.198-.514-.553-1.127-1.186-1.127-.534 0-1.028.455-.89 1.008.099.336.653.178.574-.178Z"
    />
    <path
      fill="#E8E8E8"
      d="M208.889 22.287c.099-.514.712-.494.811 0 .059.376.632.218.573-.158a1.052 1.052 0 0 0-.969-.85c-.474 0-.889.376-.968.83-.079.396.494.554.553.178ZM207.802 20.232c-.02-.336.435-.356.652-.218.277.178.435.534.573.83.158.337.672.04.514-.296-.257-.534-.632-1.166-1.285-1.226-.514-.04-1.087.337-1.047.89.019.395.613.395.593.02Z"
    />
    <path
      fill="#E8E8E8"
      d="M208.948 21.458c0-.514.633-.672.87-.217.178.336.692.04.514-.297-.237-.415-.731-.652-1.206-.534-.474.119-.771.574-.771 1.068 0 .356.593.375.593-.02ZM199.677 17.108c.455 2.135 1.443 4.072 2.886 5.733.257.296.672-.139.415-.415a11.967 11.967 0 0 1-2.728-5.476c-.079-.375-.652-.217-.573.158ZM201.101 17.108c4.507.317 9.014.257 13.521-.158.375-.04.375-.632 0-.593a83.459 83.459 0 0 1-13.521.158c-.376-.02-.376.573 0 .593Z"
    />
    <path
      fill="#E8E8E8"
      d="M214.345 17.108c.039 1.977-.574 3.835-1.839 5.357-.237.297.178.712.415.416 1.364-1.641 2.076-3.638 2.017-5.773-.02-.375-.613-.375-.593 0ZM209.818 20.29c-.138-.236-.099-.592.158-.75.257-.139.593 0 .732.237.177.336.691.04.514-.297-.297-.533-1.009-.77-1.542-.454-.534.316-.692 1.047-.376 1.561.198.337.712.04.514-.296ZM211.202 21.457c-.158-.08-.119-.336.02-.415.257-.158.533.138.632.336.158.336.672.04.514-.297-.257-.533-1.048-.909-1.561-.474-.416.356-.435 1.107.079 1.364.355.178.652-.336.316-.514Z"
    />
    <path
      fill="#E8E8E8"
      d="M212.407 19.876c-.197-.099-.296-.435-.099-.593.178-.158.514-.02.574.178.118.356.692.218.573-.158-.178-.613-.988-.91-1.502-.494-.514.415-.396 1.285.158 1.581.336.178.632-.336.296-.514Z"
    />
    <path
      fill="#E8E8E8"
      d="M210.827 19.105c0-.751.869-1.048 1.344-.474.257.296.672-.139.415-.415-.83-.969-2.372-.356-2.352.909 0 .356.593.356.593-.02ZM200.31 16.337c-.218-.138.039-.355.197-.355.297-.02.495.217.633.434.198.317.712.02.514-.296-.336-.514-.969-.949-1.581-.633a.789.789 0 0 0-.06 1.364c.317.198.613-.316.297-.514Z"
    />
    <path
      fill="#E8E8E8"
      d="M201.081 17.346c-.079-.04-.119-.138-.059-.217.118-.158.336.02.415.118.118.158.118.396.059.574-.02.059-.178.474-.198.474-.336-.158-.632.336-.296.514.494.237.87-.218 1.028-.652.178-.514.138-1.147-.336-1.483-.356-.257-.91-.277-1.167.119-.217.355-.158.89.238 1.067.375.158.672-.356.316-.514Z"
    />
    <path
      fill="#E8E8E8"
      d="M201.871 16.378c-.099-.257.178-.712.475-.534.237.138.217.514.039.672-.296.237.119.652.415.415.534-.435.356-1.403-.276-1.64-.732-.297-1.523.533-1.246 1.264.158.317.732.178.593-.177Z"
    />
    <path
      fill="#E8E8E8"
      d="M202.365 17.76c-.079-.296.257-.474.495-.315.276.197.197.573-.02.77-.277.277.158.692.415.416.514-.534.494-1.443-.237-1.78-.692-.316-1.443.317-1.246 1.048.119.376.692.218.593-.138Z"
    />
    <path
      fill="#E8E8E8"
      d="M202.089 19.006c-.039-.059.04-.138.099-.138.099-.02.178.06.217.119.178.237.02.553-.098.79-.178.336.336.633.514.297.316-.574.415-1.463-.336-1.76-.594-.237-1.206.416-.91.989.178.336.692.04.514-.297Z"
    />
    <path
      fill="#E8E8E8"
      d="M202.069 20.963c.593.178 1.107-.198 1.443-.652.158-.198.317-.475.317-.732s-.198-.514-.455-.553c-.376-.06-.534.514-.158.573.099.02-.277.494-.316.553-.178.178-.396.317-.633.238-.395-.1-.554.474-.198.573Z"
    />
    <path
      fill="#E8E8E8"
      d="M203.65 18.71c0-.217.218-.474.475-.395.178.079.277.336.197.514-.177.336.337.633.514.296.257-.514.02-1.205-.553-1.383-.613-.178-1.226.336-1.226.968 0 .396.593.396.593 0Z"
    />
    <path
      fill="#E8E8E8"
      d="M204.935 17.74c-.059-.276.316-.276.455-.177.257.198.237.534.158.81-.099.376.474.534.573.159.178-.633 0-1.404-.692-1.602a.845.845 0 0 0-1.067.97c.079.355.652.197.573-.16ZM204.145 19.461c.474.554 1.364.455 1.759-.118.217-.317-.297-.613-.514-.297-.198.277-.633.237-.83 0-.257-.277-.672.139-.415.415Z"
    />
    <path
      fill="#E8E8E8"
      d="M203.888 16.792c.118-.198.375-.237.573-.138.277.138.376.474.395.751.02.376.613.376.593 0-.039-.534-.276-1.127-.83-1.324-.454-.178-1.008-.02-1.245.415-.198.316.316.613.514.296ZM25.723 78.032h26.883c.376 0 .376-.593 0-.593H25.723c-.376 0-.376.593 0 .593Z"
    />
    <path
      fill="#E8E8E8"
      d="M49.147 77.736v7.116c0 .375.593.375.593 0v-7.117c0-.375-.593-.375-.593 0ZM44.443 85.94h18.443c.375 0 .375-.594 0-.594H44.443c-.396 0-.396.593 0 .593ZM181.748 89.893h27.675c.375 0 .375-.593 0-.593h-27.675c-.375 0-.375.593 0 .593ZM173.584 83.034h18.72c.376 0 .376-.593 0-.593h-18.72c-.375 0-.375.593 0 .593Z"
    />
    <path fill="#E8E8E8" d="M189.635 83.27v6.07c0 .375.594.375.594 0v-6.07c0-.375-.594-.375-.594 0Z" />
    <path
      fill="#fff"
      d="m99.811 135.754.356 3.479c14.45-.099 28.92-.198 43.37-.277-.158-1.107-.316-2.194-.475-3.301a56.942 56.942 0 0 0-19.886-4.191 57.212 57.212 0 0 0-23.365 4.29Z"
    />
    <path
      fill="#161616"
      d="M100.107 139.449h43.331c.771 0 .771-1.186 0-1.186h-43.331c-.77 0-.77 1.186 0 1.186ZM100.661 136.031c10.595-5.021 22.832-6.128 34.139-2.926a49.84 49.84 0 0 1 7.788 2.926c.692.336 1.285-.692.593-1.028-11.287-5.377-24.334-6.385-36.293-2.669a53.139 53.139 0 0 0-6.84 2.669c-.672.336-.06 1.364.613 1.028Z"
    />
    <path
      fill="#161616"
      d="M99.238 135.733v2.511c0 .771 1.186.771 1.186 0v-2.511c0-.77-1.186-.77-1.186 0ZM142.825 135.733v3.341c0 .771 1.186.771 1.186 0v-3.341c0-.77-1.186-.77-1.186 0ZM121.693 139.272v42.164c0 .771 1.186.771 1.186 0v-42.164c0-.752-1.186-.752-1.186 0Z"
    />
    <path
      fill="#161616"
      d="M121.476 181.377c-3.242 1.503-6.464 2.985-9.706 4.487-.692.317-.099 1.345.593 1.028 3.242-1.502 6.464-2.984 9.706-4.487.692-.336.099-1.344-.593-1.028Z"
    />
    <path
      fill="#161616"
      d="m121.99 182.167 10.971 4.804c.692.296 1.305-.712.593-1.028l-10.971-4.803c-.692-.297-1.285.731-.593 1.027Z"
    />
    <path
      fill="#161616"
      d="M112.146 187.387c6.958-.06 13.897-.218 20.855-.495.751-.039.771-1.225 0-1.186-6.939.277-13.897.435-20.855.494-.771 0-.771 1.187 0 1.187ZM113.549 139.865a93.913 93.913 0 0 1 7.611 9.667c.435.632 1.463.039 1.028-.593a94.25 94.25 0 0 0-7.808-9.924c-.495-.553-1.325.277-.831.85Z"
    />
    <path
      fill="#161616"
      d="M132.388 138.778c-3.993 2.965-7.571 6.425-10.556 10.418-.455.612.573 1.205 1.028.593 2.846-3.816 6.306-7.156 10.121-9.983.613-.455.039-1.483-.593-1.028Z"
    />
    <path
      fill="#161616"
      stroke="#161616"
      strokeWidth={0.5}
      d="M77.553 168.192c.475 4.21.93 8.421 1.404 12.631-6.958 5.752-10.121 9.133-9.488 10.141.75 1.186 6.68-.83 17.83-6.108a4519.972 4519.972 0 0 0-1.74-16.249l-8.006-.415Z"
    />
    <path
      fill="#3EF7AE"
      d="M111.217 109.917c1.009 2.629 2.294 7.235 1.107 12.632-.612 2.807-1.838 5.554-5.653 10.674-3.143 4.211-8.065 10.042-15.419 16.289a34.519 34.519 0 0 0-2.668 9.962 33.636 33.636 0 0 0 1.107 12.909c-.95.593-3.638 2.095-7.453 2.075a14.084 14.084 0 0 1-8.144-2.649 71.859 71.859 0 0 1-.91-14.726c.238-5.792 1.167-10.813 2.195-14.885a111.933 111.933 0 0 1 9.429-8.975c6.148-5.199 8.876-6.424 11.485-9.963 1.977-2.708 4.131-7.096 3.934-14.173 3.637.257 7.314.553 10.99.83Z"
    />
    <path
      fill="#3D3D3D"
      d="M108.628 109.996c-2.372 1.324-1.087 6.325-1.522 11.129-.534 5.811-3.44 9.725-7.512 15.221-5.1 6.879-10.99 10.931-14.845 13.125l-2.847 25.026a15.6 15.6 0 0 0 3.044-.375 15.76 15.76 0 0 0 4.665-1.898 23.001 23.001 0 0 1-1.047-4.29c-.297-1.996-.257-3.499-.198-5.416.08-2.273.138-4.388.771-7.136.316-1.443.89-3.44 1.898-5.713a184.734 184.734 0 0 0 4.19-3.894c3.025-2.906 5.278-5.061 7.888-8.085 4.349-5.001 6.306-8.461 6.859-9.409.929-1.681 1.443-2.59 1.898-3.993 1.423-4.31.85-8.125.573-9.805a22.427 22.427 0 0 0-1.245-4.566c-.732-.218-1.72-.396-2.57.079Z"
      opacity={0.12}
    />
    <path
      fill="#3D3D3D"
      d="M90.936 149.769c-2.412 3.479-3.519 6.582-4.092 8.757-.336 1.265-.494 2.312-1.147 7.61-.395 3.282-.711 6.049-.948 8.085 1.58-.593 3.162-1.206 4.764-1.799a34.609 34.609 0 0 1-1.246-9.132c0-.989.04-3.539.672-6.761a35.46 35.46 0 0 1 1.997-6.76Z"
      opacity={0.16}
    />
    <path
      fill="#161616"
      stroke="#161616"
      strokeWidth={0.5}
      d="M95.7 169.358c.474 4.21.929 8.421 1.403 12.631-6.958 5.753-10.12 9.133-9.488 10.141.75 1.186 6.681-.83 17.83-6.108-.573-5.416-1.146-10.833-1.739-16.249l-8.006-.415Z"
    />
    <path
      fill="#3EF7AE"
      d="M122.07 113.041a38.25 38.25 0 0 1 1.304 11.406c-.02.276-.217 4.922-1.304 8.421-3.045 9.864-14.668 15.655-17.949 17.178.276 2.352.593 4.704.929 7.096a402.217 402.217 0 0 0 2.411 15.201c-.454.475-3.143 3.242-7.768 3.479-4.606.238-7.63-2.214-8.144-2.648-1.68-3.717-3.776-9.845-3.52-17.515a38.848 38.848 0 0 1 2.037-11.069 811.554 811.554 0 0 1 10.556-14.213c4.625-6.089 5.792-7.413 6.839-10.418 1.661-4.685.929-7.333 3.143-8.757 1.582-1.008 4.685-1.403 11.466 1.839Z"
    />
    <path
      fill="#3D3D3D"
      d="M116.654 115.056c-2.471 3.282-1.503 6.524-2.59 10.695-1.522 5.89-5.06 9.864-10.081 15.497a67.02 67.02 0 0 1-6.267 6.148c1.345 9.35 2.708 18.7 4.053 28.03a8.498 8.498 0 0 0 5.535-3.202 258.417 258.417 0 0 1-.574-3.874 1881.977 1881.977 0 0 1-2.629-18.364 68.133 68.133 0 0 0 4.665-2.491c2.135-1.265 4.033-2.392 6.148-4.25.949-.83 2.451-2.175 3.993-4.329.712-.989 3.202-4.567 3.993-9.311.178-1.047.277-2.313.336-2.965.04-.376.237-2.787.198-4.863-.02-1.7-.237-4.685-1.344-8.658-.317-.277-.791-.613-1.424-.692-1.838-.237-3.419 1.839-4.012 2.629Z"
      opacity={0.18}
    />
    <path
      fill="#3D3D3D"
      d="M104.042 150.223a272.48 272.48 0 0 0-.969 24.927 12.818 12.818 0 0 0 2.234-1.107 13.35 13.35 0 0 0 2.075-1.641 6776.712 6776.712 0 0 0-3.34-22.179Z"
      opacity={0.16}
    />
    <path
      fill="#fff"
      stroke="#161616"
      strokeWidth={0.5}
      d="M98.032 73.288c-1.838 2.669-1.897 6.049-1.976 10.734-.08 4.665.672 7.926 1.324 11.188.87 4.349 1.917 10.615 2.629 18.601a43.88 43.88 0 0 0 14.371 1.325 44.177 44.177 0 0 0 8.006-1.325c.039-6.701.534-12.078.988-15.715.593-4.784 1.028-6.266 1.325-10.417.533-7.967.81-11.96-1.087-14.727-4.745-6.84-20.737-6.721-25.58.336Z"
    />
    <path
      fill="#3D3D3D"
      d="M113.925 68.366c1.878 8.5 3.321 20.835.475 35.028a81.212 81.212 0 0 1-3.38 12.019 89.127 89.127 0 0 0 5.831-.692 69.775 69.775 0 0 0 5.377-1.048c.02-1.68.099-4.25.356-7.353.355-4.369.731-5.95 1.759-13.304 1.008-7.314 1.305-10.496.81-14.588-.435-3.736-1.146-5.1-1.858-6.069-2.807-3.716-7.907-3.973-9.37-3.993Z"
      opacity={0.09}
    />
    <path
      fill="#3D3D3D"
      d="M123.967 73.505c-.316 0-.316 2.59-1.166 8.402-.474 3.32-1.067 6.305-2.214 12.255-.751 3.835-1.127 5.753-1.285 6.425-.889 3.538-2.688 8.599-6.78 14.47 3.143-.435 6.306-.85 9.449-1.285.296-3.914.593-7.275.81-9.923.475-5.338.712-8.026 1.048-11.209.652-5.95.83-6.128.929-8.638.237-5.397-.356-10.497-.791-10.497Z"
      opacity={0.09}
    />
    <path
      fill="#fff"
      d="M99.99 46.917c-1.226 2.53-1.167 4.744-1.048 8.994.099 4.092.178 7.176 2.214 8.639 1.917 1.384 3.835.079 5.772 1.977.356.336 1.838 1.799 1.7 3.558-.059.81-.435 1.245-.178 1.858.415.969 1.838 1.186 2.155 1.226 1.443.217 2.55-.495 2.866-.692.593-.396.949-.85 1.107-1.048 1.483-1.977 2.016-4.962 2.807-6.721 1.66-3.697 4.784-2.53 6.602-6.246 1.443-2.985.613-6.148.297-7.354-.85-3.202-2.748-5.14-4.369-6.82-2.392-2.47-4.349-4.447-7.294-4.961-5.12-.89-10.477 3.123-12.631 7.59Z"
    />
    <path
      fill="#000"
      d="M108.786 60.933c.138-.06.277.158.672.415.712.454 1.7.652 2.55.435.791-.198 1.364-.752 1.72-1.107.593-.593.711-.989.968-.95.376.06.692 1.108.356 1.918-.197.475-.553.752-.929 1.028-.178.139-.968.672-2.135.79-.672.06-1.166-.039-1.561-.118-.613-.118-.929-.178-1.186-.415-.712-.573-.752-1.858-.455-1.996Z"
    />
    <path
      fill="#161616"
      d="M100.325 50.594c.771 6.88 3.42 14.371 10.398 17.1.356.138.514-.436.158-.574-6.701-2.61-9.211-9.943-9.963-16.526-.059-.375-.652-.375-.593 0Z"
    />
    <path
      fill="#161616"
      d="M121.734 50.634c-.85 6.879-3.816 14.015-10.813 16.525-.356.119-.198.692.158.574 7.274-2.61 10.378-9.924 11.248-17.1.039-.375-.554-.375-.593 0ZM110.091 51.78c-.158 2.155-.138 4.33.119 6.484.039.375 0 .949.494 1.008.494.06 1.146-.712 1.463-1.008.276-.257-.139-.672-.416-.415-.118.099-.889.83-.948.79-.02 0-.02-.652-.04-.75-.079-.673-.119-1.365-.158-2.037a34.948 34.948 0 0 1 .079-4.072c.04-.395-.554-.376-.593 0ZM104.22 52.966c1.205-.712 2.629-.633 3.795.158.316.218.613-.297.296-.514-1.324-.91-3.004-.949-4.388-.158-.316.198-.02.712.297.514ZM114.953 52.966c1.206-.712 2.629-.633 3.795.158.317.218.613-.297.297-.514-1.324-.91-3.005-.949-4.388-.158-.336.198-.04.712.296.514Z"
    />
    <path
      fill="#161616"
      d="M109.003 62.474c-.553-1.245-.217-1.72 1.008-1.423.079.06.159.099.238.158.257.08.296.198.573.277.375.099.771.04 1.127-.118.593-.238.988-.712 1.541-1.028.495-.297.771-.356 1.246.02.593.493.455 1.047.02 1.62-.238.297.276.593.514.297.573-.751.711-2.115-.257-2.65-1.147-.612-2.056.535-2.926 1.01-1.107.612-1.443-.218-2.412-.357-.435-.059-.85.119-1.166.435-.672.672-.652 1.838.237 2.293.297.158.593-.356.257-.534Z"
    />
    <path
      fill="#161616"
      d="M108.43 62.653c1.759 2.174 5.317 1.186 6.938-.593.257-.277-.158-.712-.415-.415-1.423 1.561-4.546 2.53-6.088.593-.257-.317-.672.118-.435.415ZM105.089 55.24l.04-.04a.288.288 0 0 0 0-.415.286.286 0 0 0-.415 0l-.04.04a.288.288 0 0 0 0 .415c.119.118.316.118.415 0ZM106.79 54.469v.04c0 .157.138.296.296.296a.305.305 0 0 0 .297-.297v-.04a.305.305 0 0 0-.297-.296c-.158.02-.296.139-.296.297ZM106.552 56.03v.04c0 .158.138.296.297.296a.304.304 0 0 0 .296-.296v-.04a.304.304 0 0 0-.296-.296.291.291 0 0 0-.297.296ZM115.704 53.856l-.039.04a.286.286 0 0 0 0 .414.286.286 0 0 0 .415 0l.039-.04a.286.286 0 0 0 0-.414c-.099-.119-.296-.119-.415 0ZM117.681 53.777v.099c0 .158.139.296.297.296a.304.304 0 0 0 .296-.296v-.1a.304.304 0 0 0-.296-.296.305.305 0 0 0-.297.297ZM117.207 55.556v.04c0 .157.138.296.296.296a.305.305 0 0 0 .297-.297v-.04a.305.305 0 0 0-.297-.296.29.29 0 0 0-.296.297ZM104.279 49.428c.751-1.146 2.155-1.482 3.36-.85.336.178.633-.336.297-.514-1.522-.79-3.222-.355-4.171 1.068-.218.316.296.613.514.296ZM115.072 48.4c1.226-1.008 2.748-.277 3.756.653.277.257.692-.159.415-.416-1.265-1.166-3.103-1.897-4.586-.652-.297.237.119.652.415.415ZM107.896 66.25c.356 1.819.415 3.638.218 5.476-.04.376.553.376.593 0a18.603 18.603 0 0 0-.257-5.634c-.06-.375-.633-.217-.554.158ZM114.539 65.52a21.519 21.519 0 0 0-.198 5.969c.039.376.632.376.593 0a20.455 20.455 0 0 1 .178-5.812c.059-.375-.514-.533-.573-.158Z"
    />
    <path
      fill="#161616"
      d="M108.233 71.745c-.158.475.474.89.79 1.127a3.945 3.945 0 0 0 2.116.771c1.522.099 3.182-.692 3.736-2.135.138-.356-.435-.514-.574-.158-.435 1.107-1.739 1.72-2.866 1.72a3.388 3.388 0 0 1-1.581-.376c-.119-.06-1.107-.632-1.068-.79.139-.376-.435-.534-.553-.159ZM108.193 66.922c.988.672 2.115 1.325 3.341 1.147 1.225-.178 2.332-1.107 3.242-1.878.296-.257-.139-.672-.415-.415-.791.672-1.839 1.562-2.906 1.7-1.107.158-2.076-.435-2.965-1.048-.317-.237-.613.277-.297.494Z"
    />
    <path
      fill="#161616"
      d="M108.43 67.396c.633 1.088 1.819 1.147 2.945.91 1.206-.237 2.669-.712 3.321-1.839.198-.336-.316-.632-.514-.296-.751 1.324-4.329 2.53-5.258.929-.178-.336-.692-.04-.494.296Z"
    />
    <path
      fill="#161616"
      d="M108.628 67.535c1.581 1.997 4.961 1.384 6.108-.77.178-.337-.336-.633-.514-.297-.988 1.858-3.835 2.352-5.179.652-.237-.296-.652.119-.415.415ZM101.768 57.69c-1.759.93-3.874-3.44-.85-3.795.376-.04.376-.633 0-.593-1.423.158-2.47 1.403-2.095 2.866.356 1.305 1.858 2.748 3.242 2.036.336-.178.039-.692-.297-.514ZM121.714 53.58c.613-.297 1.225.75 1.344 1.166.158.533.079 1.106-.079 1.62-.415 1.384-1.344 1.621-2.649 1.503-.376-.04-.376.553 0 .593.81.079 1.759.138 2.412-.475.593-.533.909-1.522.968-2.293.099-1.126-.83-3.36-2.293-2.629-.336.178-.039.692.297.514Z"
    />
    <path
      fill="#6149F7"
      d="M107.837 36.5c-2.234.613-4.823 1.345-6.86 3.44-2.194 2.254-2.688 5.04-2.965 6.543-.217 1.167-.178 2.096-.178 2.096 0 1.245.218 1.74.277 3.044 0 0 .04.949-.099 2.253-.079.672-1.028 3.875-2.57 7.314-1.146 2.57-1.798 2.709-2.174 4.35-.198.869-.85 3.696.85 6.305 2.056 3.143 5.792 3.34 6.069 3.34 3.815.14 6.108-2.668 6.464-3.103.988-1.245 1.383-2.53 1.561-3.32-.039-.475-.079-.99-.158-1.503a101.43 101.43 0 0 0-.178-1.107 16.465 16.465 0 0 1-.751-.573 14.683 14.683 0 0 1-2.57-2.728 18.352 18.352 0 0 1-2.49-4.705c-.158.08-.396.198-.712.218-1.087.079-2.095-.93-2.431-1.957-.08-.257-.257-.81-.04-1.443.198-.534.593-.85.81-1.028a2.7 2.7 0 0 1 1.088-.534c-.099-.672-.218-1.344-.317-2.016.06-.336.159-.85.455-1.404.85-1.62 2.214-1.76 5.634-3.696 1.977-1.127 3.024-1.938 3.914-3.064.375-.475.632-.89.81-1.167.238.475.593 1.167 1.167 1.898.612.79 1.265 1.364 2.293 2.155 2.075 1.581 4.309 2.925 4.586 3.103 1.522.91 2.313 1.285 2.609 2.214.217.712.04 1.404-.138 1.858.237 0 .949 0 1.364.475.138.158.197.336.276.612.04.139.218.653.218 1.246 0 .356-.02 1.562-.969 2.313-.988.79-2.214.533-2.372.494a17.277 17.277 0 0 1-2.016 3.716 16.04 16.04 0 0 1-3.222 3.301c-.119.93-.237 1.938-.277 3.005-.04.692-.04 1.344-.04 1.977.139.435.396 1.028.85 1.62 1.74 2.254 4.804 2.314 5.575 2.333 2.313.06 4.032-.85 5.179-1.443.81-.435 1.917-1.008 2.965-2.154 1.74-1.878 2.194-3.974 2.392-4.863.652-3.084-.217-5.595-.672-6.88-.988-2.747-1.838-2.727-3.519-5.752-1.897-3.479-1.126-4.15-3.637-10.299-1.403-3.44-2.293-4.803-3.696-5.95-.633-.514-2.135-1.68-4.231-1.897-.672-.06-1.166 0-3.914.533-2.589.475-3.854.732-4.21.83Z"
    />
    <path
      fill="#6149F7"
      d="M111.415 42.253c-.02-.04-.04-.06-.04-.1-.019.12-.039.238-.039.357.039-.02.059-.06.099-.08-.119.02-.237.04-.356.04.395.178.83 1.048 1.127 1.404.415.494.83.968 1.285 1.423.81.81 1.719 1.542 2.668 2.194 2.036 1.404 5.634 2.412 5.753 5.377.019.375.612.375.593 0-.099-2.807-2.867-3.954-4.942-5.18a17.85 17.85 0 0 1-3.637-2.806 16.303 16.303 0 0 1-1.641-1.878c-.257-.336-.494-.89-.89-1.048-.138-.06-.257-.04-.356.04-.039.02-.059.059-.098.079-.099.099-.099.237-.04.355.02.04.04.06.04.1.118.355.612.059.474-.277ZM106.275 36.915c-3.479.83-6.464 3.34-7.828 6.662-1.858 4.507.1 9.547-1.68 14.094-1.463 3.795-5.001 7.65-3.756 12.019.87 3.064 3.697 5.475 6.88 5.752 4.032.356 7.57-2.649 8.341-6.523.079-.376-.494-.534-.573-.159-.692 3.48-3.696 6.207-7.334 6.109-3.222-.08-6.266-2.669-6.859-5.852-.751-4.111 2.61-7.729 3.954-11.326 1.482-3.954.177-8.125 1.047-12.157.85-3.954 4.072-7.097 7.967-8.026.375-.119.217-.692-.159-.593Z"
    />
    <path
      fill="#6149F7"
      d="M110.98 36.441c1.562-.731 3.321-1.146 5.041-.949 2.332.257 4.191 1.8 5.554 3.618 2.63 3.538 2.551 8.124 4.567 11.96 1.759 3.38 4.368 6.424 5.297 10.18 1.028 4.23-.731 8.697-4.329 11.109-2.036 1.364-4.546 2.095-6.978 1.482a10.017 10.017 0 0 1-3.123-1.364c-.711-.474-1.917-1.324-1.72-2.332.079-.376-.494-.534-.573-.158-.296 1.522 1.344 2.648 2.471 3.281 1.838 1.048 3.954 1.582 6.069 1.245 4.467-.711 8.104-4.487 8.895-8.875.83-4.606-1.66-8.52-3.973-12.276-1.305-2.135-2.491-4.29-3.104-6.74-.652-2.57-1.107-5.08-2.609-7.315-1.344-1.996-3.242-3.854-5.693-4.348-2.076-.416-4.211.04-6.088.929-.356.217-.06.731.296.553Z"
    />
    <path
      fill="#6149F7"
      d="M111.02 40.157c.355 3.776-3.835 5.238-6.524 6.662-2.471 1.324-4.803 3.261-5.258 6.187-.06.375.514.534.573.158.574-3.697 4.053-5.357 7.057-6.86 2.392-1.205 5.041-3.103 4.745-6.147-.04-.376-.633-.376-.593 0Z"
    />
    <path
      fill="#3D3D3D"
      d="M125.905 62.553c-.218.376-2.985 5.239-5.654 4.508-2.016-.554-2.629-3.895-2.688-4.23-.87.81-1.74 1.62-2.629 2.43a17.91 17.91 0 0 0-.396 3.223c-.059 1.384-.079 2.095.198 2.767.632 1.483 2.214 2.076 3.637 2.63.83.316 2.57.948 4.843.671 2.53-.316 4.191-1.581 4.843-2.075 2.511-1.957 3.44-4.507 3.776-5.713.929-3.34 0-6.128-.396-7.334-.593-1.799-1.7-3.538-3.894-6.998-.593-.909-1.087-1.66-1.403-2.154.632 1.76 2.49 7.353-.237 12.276Z"
      opacity={0.19}
    />
    <path
      fill="#3D3D3D"
      d="M97.3 56.485a11.97 11.97 0 0 0-.671 4.784c.118 1.74.257 3.538 1.62 4.645 1.622 1.324 4.705 1.463 5.911-.277.87-1.245.376-2.906.277-3.222l3.775 3.835a7.48 7.48 0 0 1-.059 3.103c-.415 1.76-1.384 2.867-1.818 3.361-1.206 1.364-2.531 1.957-2.827 2.076-.593.257-2.234.948-4.31.533-1.877-.375-3.044-1.423-3.696-2.016-.633-.593-2.135-1.996-2.55-4.31-.316-1.739.118-3.142.474-4.23.455-1.462.93-1.956 1.957-3.894.495-1.028 1.206-2.49 1.918-4.388Z"
      opacity={0.23}
    />
    <path
      fill="#3D3D3D"
      d="M101.175 52.828a26.329 26.329 0 0 0 2.985 6.207 26.314 26.314 0 0 0 7.215 7.333c.692-.138 1.364-.296 2.056-.434.198.79.336 1.779-.178 2.293-.791.81-2.372-.139-3.222.553-.534.435-.85 1.502.059 4.408-.514-.375-1.028-.751-1.561-1.127a608.352 608.352 0 0 0-.317-6.029 17.702 17.702 0 0 1-4.092-4.408 17.778 17.778 0 0 1-1.917-3.855c-.119.08-.731.514-1.423.317-.514-.139-.771-.554-1.048-.989-.356-.553-.79-1.225-.553-1.996.217-.692.909-1.226 1.739-1.364.04-.297.138-.613.257-.91Z"
      opacity={0.06}
    />
    <path
      fill="#000"
      d="M175.58 131.207v66.953c0 .77 1.186.77 1.186 0v-66.953c0-.771-1.186-.771-1.186 0ZM43.79 131.207v66.439c0 .771 1.186.771 1.186 0v-66.439c0-.771-1.186-.771-1.186 0ZM57.864 132.097v33.664c0 .771 1.186.771 1.186 0v-33.664c0-.771-1.186-.771-1.186 0ZM166.408 130.337v33.664c0 .771 1.186.771 1.186 0v-33.664c0-.771-1.186-.771-1.186 0Z"
    />
    <path
      fill="#000"
      stroke="#161616"
      strokeWidth={0.5}
      d="m41.576 131.701 137.958-.178c-1.364-5.416-2.708-10.852-4.072-16.269l-119.554.356c-4.784 5.357-9.548 10.734-14.332 16.091Z"
    />
    <path fill="#161616" d="M40.963 129.23v2.491c0 .375.593.375.593 0v-2.491c0-.376-.593-.376-.593 0Z" />
    <path
      fill="#161616"
      d="M41.121 129.645c.06.553.139 1.127.198 1.68.04.376.633.376.593 0a13.542 13.542 0 0 1-.079-1.799c-.178.06-.375.099-.553.159.197.474.04 1.008.237 1.482.118.297.593.277.573-.079 0-.494.02-.988.06-1.502-.198.019-.396.059-.574.079.139.375.257.771.356 1.166.079.277.494.277.573 0 .06-.257.08-.652.237-.85-.059.04-.118.099-.177.138.02 0 .059-.019.079-.019-.08-.02-.159-.02-.238-.04.02.02.04.04.06.04-.04-.079-.1-.178-.139-.257.1-.119.1-.178-.02-.198-.079-.02-.138-.059-.217-.099-.06-.02-.217-.138-.237-.059l-.198.198c.475-.099.949-.02 1.404.079.375.079.533-.495.158-.574a4.439 4.439 0 0 0-1.443-.059c.02.198.06.395.079.573a5.46 5.46 0 0 1 1.502-.079 4.545 4.545 0 0 1-.276-.375c-.04.237-.178.336-.396.375-.158.02-.494.06-.633 0-.118-.039-.118-.138-.197-.237a.429.429 0 0 0-.158-.099c-.218-.039.04-.02-.06.158-.118.04-.217.099-.336.139.06.019.119.039.159.059.355.119.514-.455.158-.573-.06-.02-.119-.04-.158-.06a.293.293 0 0 0-.337.139c-.138.237-.079.534.178.672.06.02.139.02.178.039-.098-.059 0 .06.02.099a.566.566 0 0 0 .376.257c.692.139 1.62.02 1.779-.79.04-.198-.08-.356-.277-.376a5.224 5.224 0 0 0-1.66.099c-.317.059-.258.632.079.573.434-.059.85-.059 1.285.04.059-.198.098-.376.158-.574-.574-.118-1.147-.197-1.72-.079-.099.02-.178.119-.198.198-.099.455.297.633.652.791.297.118.712.296.93-.06.158-.276-.06-.612-.376-.553-.455.059-.474.83-.553 1.146h.573c-.1-.395-.218-.79-.356-1.166-.119-.316-.554-.257-.573.079-.04.494-.06.989-.06 1.502.198-.019.396-.059.574-.079-.218-.533-.06-1.107-.297-1.64-.118-.277-.553-.119-.553.158 0 .593.02 1.186.079 1.799h.593c-.06-.554-.139-1.127-.198-1.681-.079-.336-.672-.336-.633.04ZM179.296 128.933v2.57c0 .376.593.376.593 0v-2.57c0-.395-.593-.395-.593 0Z"
    />
    <path
      fill="#161616"
      d="M179.02 129.032v.613c0 .158.138.296.296.296a.304.304 0 0 0 .297-.296c.02-.198 0-.395-.02-.593-.02-.158-.119-.297-.296-.297a.304.304 0 0 0-.297.297v.415c0 .376.593.376.593 0v-.415H179c.02.198.02.395.02.593h.593v-.613c-.02-.375-.613-.375-.593 0Z"
    />
    <path
      fill="#fff"
      stroke="#161616"
      strokeWidth={0.5}
      d="m41.576 128.934 137.958-.178c-1.364-5.416-2.708-10.852-4.072-16.269l-119.554.356c-4.784 5.377-9.548 10.734-14.332 16.091Z"
    />
    <path
      fill="#3EF7AE"
      d="M152.275 114.642c-.237.059-.257 1.245.415 5.08.178.297.474.732 1.008 1.048.633.395 1.364.395 2.767.356 1.621-.02 2.076-.257 2.274-.356.494-.277.81-.652 1.008-.909l.356-5.041c-.218.277-.554.593-1.008.633-.811.059-1.068-.91-1.997-1.009-.474-.039-.455.198-2.036.771-1.067.396-1.364.376-1.581.317-.791-.178-1.009-.929-1.206-.89Z"
    />
    <path
      fill="#fff"
      d="M152.017 108.89c.06 1.858.139 3.716.198 5.594.336.494.85 1.087 1.542 1.166 1.028.099 1.403-1.126 2.629-1.126.929 0 1.186.691 2.392.711a2.99 2.99 0 0 0 1.364-.277c-.237-1.7-.04-2.965.197-3.815.238-.83.613-1.66.238-2.115-.277-.316-.811-.316-1.226-.277.02-.395.099-.85.277-1.364.395-1.186 1.028-1.68.85-2.411-.059-.277-.257-.613-.435-.613-.316 0-.474.909-.988 2.491-.257.79-.514 1.443-.673 1.878-1.146.079-2.055 0-2.648-.099-.949-.139-1.7-.396-2.689-.139-.474.099-.81.277-1.028.396Z"
    />
    <path
      fill="#161616"
      d="M151.78 109.067c0 3.617.217 7.235.632 10.832.04.277.475.277.455 0a99.303 99.303 0 0 1-.633-10.832c0-.297-.454-.297-.454 0Z"
    />
    <path
      fill="#161616"
      d="M152.531 120.038c.89 1.067 2.214 1.384 3.539 1.344 1.304-.02 2.728-.138 3.676-1.127.198-.197-.118-.514-.316-.316-.87.89-2.194.969-3.36.988-1.226.02-2.412-.237-3.222-1.225-.178-.198-.495.118-.317.336Z"
    />
    <path
      fill="#161616"
      d="M160.162 110.036a188.875 188.875 0 0 1-.751 10.042c-.02.277.415.277.454 0 .336-3.341.573-6.681.751-10.042 0-.296-.434-.296-.454 0ZM152.63 109.503c2.214 1.107 4.705 1.344 7.077.613.277-.08.158-.514-.119-.435a9.106 9.106 0 0 1-6.721-.574c-.276-.118-.494.277-.237.396Z"
    />
    <path
      fill="#161616"
      d="M158.106 110.154c.751-1.581 1.067-3.301 1.7-4.922.455-1.146.415-.237.257.218-.178.474-.356.968-.534 1.443-.356.968-.711 1.917-1.048 2.886-.098.277.337.375.435.118.534-1.443 1.068-2.886 1.582-4.329.158-.415.553-1.443-.158-1.64-.692-.198-.989 1.245-1.127 1.68-.494 1.443-.811 2.945-1.483 4.329-.138.257.238.474.376.217Z"
    />
    <path
      fill="#161616"
      d="M152.156 109.028c.178-.554 2.629-.218 3.103-.218 1.087.02 2.194-.02 3.282.119.276.039.276-.415 0-.455-1.582-.198-3.242-.119-4.843-.138-.653 0-1.72-.218-1.977.573-.079.257.336.376.435.119Z"
    />
    <path
      fill="#161616"
      d="M159.094 109.008c.395-.059.988-.158 1.206.277.178.316-.198.553-.158.909.039.277.474.158.435-.118 0-.06.138-.238.177-.317a.783.783 0 0 0 .06-.296c0-.218-.119-.455-.277-.613-.415-.415-1.028-.356-1.562-.277-.276.04-.158.475.119.435ZM152.037 114.641c.672 1.424 2.234 1.523 3.321.534 1.226-1.107 2.115.237 3.341.415.632.099 1.146-.237 1.502-.731.158-.237-.217-.455-.376-.218-.751 1.088-1.621.178-2.411-.158-.455-.178-.969-.276-1.463-.158-.376.079-.613.297-.89.534-.889.791-2.075.731-2.629-.455-.138-.237-.514-.019-.395.237Z"
    />
    <path
      fill="#fff"
      stroke="#161616"
      strokeWidth={0.5}
      d="M97.029 74.642a29.398 29.398 0 0 0-8.611 6.016c-6.811 6.913-8.079 15.26-8.385 18.367a31.26 31.26 0 0 0 4.09 10.476c1.408 2.283 3.485 5.576 7.549 8.16a20.143 20.143 0 0 0 3.479 1.791c.92-.569 1.821-1.142 2.74-1.712a339.233 339.233 0 0 1-6.145-10.572 330.247 330.247 0 0 1-4.47-8.244 20.575 20.575 0 0 1 4.348-9.038 20.533 20.533 0 0 1 4.747-4.14c.218-3.696.437-7.392.658-11.104Z"
    />
    <path
      fill="#6D6C6C"
      d="M96.721 78.356c-5.701 4.519-8.842 9.186-10.516 12.177-2.92 5.178-2.995 8.138-2.93 9.568.064 1.126.298 3.074 1.83 6.24 1.331 2.743 4.263 7.752 10.654 12.556.717-.486 1.43-.955 2.147-1.441a248.92 248.92 0 0 1-4.812-7.947 222.365 222.365 0 0 1-5.798-10.598 21.154 21.154 0 0 1 2.45-6.339c2.143-3.638 4.909-5.802 6.589-6.917.106-2.431.245-4.857.386-7.299Z"
      opacity={0.13}
    />
    <path
      fill="#6D6C6C"
      d="M87.242 99.121a24.754 24.754 0 0 0 1.02 7.386c1.884 6.232 5.821 10.163 7.866 11.93l1.53-.939c-3.476-6.132-6.936-12.261-10.416-18.377Z"
      opacity={0.16}
    />
    <path
      fill="#fff"
      stroke="#161616"
      strokeWidth={0.5}
      d="M124.818 74.346c2.523 1.217 5.811 3.181 8.8 6.295 6.784 7.052 7.404 15.188 7.47 18.21a30.07 30.07 0 0 1-5.363 9.894c-1.735 2.113-4.281 5.178-8.912 7.46a25.227 25.227 0 0 1-3.947 1.541c-.931-.6-1.879-1.198-2.81-1.798a358.553 358.553 0 0 0 7.605-9.867 447.312 447.312 0 0 0 5.578-7.709 18.272 18.272 0 0 0-3.912-8.98c-1.61-1.982-3.375-3.355-4.782-4.27.107-3.6.199-7.18.273-10.776Z"
    />
    <path
      fill="#fff"
      stroke="#161616"
      strokeWidth={0.5}
      d="M124.818 74.346c2.523 1.217 5.811 3.181 8.8 6.295 6.784 7.052 7.404 15.188 7.47 18.21a30.07 30.07 0 0 1-5.363 9.894c-1.735 2.113-4.281 5.178-8.912 7.46a25.227 25.227 0 0 1-3.947 1.541c-.931-.6-1.879-1.198-2.81-1.798a358.553 358.553 0 0 0 7.605-9.867 447.312 447.312 0 0 0 5.578-7.709 18.272 18.272 0 0 0-3.912-8.98c-1.61-1.982-3.375-3.355-4.782-4.27.107-3.6.199-7.18.273-10.776Z"
    />
    <path
      fill="#6D6C6C"
      d="M125.094 78.319a38.167 38.167 0 0 1 8.122 9.785c1.27 2.196 3.333 5.762 3.712 9.28 1.167 10.7-13.269 19.56-14.107 20.07-.919-.469-1.82-.924-2.738-1.393 4.378-5.863 8.739-11.723 13.117-17.586-.271-1.75-1.001-4.987-3.301-8.284a19.423 19.423 0 0 0-5.505-5.269c.244-2.196.473-4.391.7-6.603Z"
      opacity={0.16}
    />
    <path
      fill="#6D6C6C"
      d="M133.368 98.508a23.037 23.037 0 0 1-.839 4.691c-2.157 7.521-7.571 12.053-10.339 14.037-.746-.387-1.476-.776-2.221-1.164 4.46-5.854 8.921-11.708 13.399-17.564Z"
      opacity={0.12}
    />
    <path fill="#fff" stroke="#161616" d="M89 96.151h41.283v26.14H89z" />
    <path
      fill="#161616"
      d="M109.046 111.714c-2.183.269-3.24-2.384-2.67-4.146.554-1.747 2.619-2.569 4.298-1.965 1.662.605 2.468 2.636 1.881 4.265-.655 1.813-2.938 2.551-4.617 1.746-.286-.135-.554.302-.252.436 1.494.722 3.324.453 4.516-.722 1.158-1.158 1.326-2.988.604-4.415-1.645-3.257-6.732-2.216-7.084 1.36-.185 1.914 1.192 4.214 3.324 3.962.319-.051.319-.554 0-.521Z"
    />
    <path
      fill="#6D6C6C"
      d="M104.496 96.084c5.591 1.947 9.402 6.933 9.519 12.289.151 6.716-5.49 13.028-13.179 13.666l29.38.252c-.033-8.764-.05-17.527-.084-26.274-8.545 0-17.09.033-25.636.067Z"
      opacity={0.14}
    />
    <path
      fill="#6D6C6C"
      d="M130.216 96c-.151 5.339-1.208 9.502-2.098 12.239-1.31 3.979-2.25 6.883-4.953 9.452-3.677 3.526-8.26 4.18-10.275 4.348 5.725.051 11.45.101 17.175.168.017-4.986.051-9.972.084-14.975 0-3.744.034-7.488.067-11.232Z"
      opacity={0.26}
    />
  </svg>
);

export default DefaultTestImg;
