import React, { useState } from 'react';

import { Container, StyledTab, StyledTabs } from './styles';

interface ITabBar {
  value: number;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const TabBar: React.FC<ITabBar> = ({ value, handleChange }) => {
  return (
    <Container>
      <StyledTabs value={value} onChange={handleChange}>
        <StyledTab label="Geral" />
        <StyledTab label="Conquistas" />
        <StyledTab label="Missões" />
      </StyledTabs>
    </Container>
  );
};

export default TabBar;
