import React, { useEffect, useState } from 'react';

import PageStructure from '../../../shared/components/pageStructure';
import { Box } from '@mui/material';
import TabBar from '../components/tabBar';
import GeneralContent from '../components/generalContent';
import Achievements from '../components/achievements';
import Missions from '../components/missions';
import { dashboarTabOptions } from '../../../routes/routes';
import { useSearchParams } from 'react-router-dom';

const Dashboard = () => {
  const [tabValue, setTabValue] = useState(dashboarTabOptions.general.tabId);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);

    switch (newValue) {
      case dashboarTabOptions.general.tabId:
        searchParams.set('tab', dashboarTabOptions.general.param);
        break;
      case dashboarTabOptions.achievements.tabId:
        searchParams.set('tab', dashboarTabOptions.achievements.param);
        break;
      case dashboarTabOptions.missions.tabId:
        searchParams.set('tab', dashboarTabOptions.missions.param);
        break;
      default:
        searchParams.set('tab', dashboarTabOptions.historyOfQuestions.param);
    }

    setSearchParams(searchParams);
  };

  useEffect(() => {
    const tabParamValue = searchParams.get('tab');

    switch (tabParamValue) {
      case dashboarTabOptions.historyOfQuestions.param:
        setTabValue(dashboarTabOptions.historyOfQuestions.tabId);
        break;
      case dashboarTabOptions.achievements.param:
        setTabValue(dashboarTabOptions.achievements.tabId);
        break;
      case dashboarTabOptions.missions.param:
        setTabValue(dashboarTabOptions.missions.tabId);
        break;
      default:
        setTabValue(dashboarTabOptions.general.tabId);
    }
  }, [searchParams]);

  return (
    <Box height={'100%'}>
      <PageStructure title="Dashboard">
        <TabBar value={tabValue} handleChange={handleTabChange} />
        {tabValue === 0 ? <GeneralContent /> : tabValue === 1 ? <Achievements /> : <Missions />}
      </PageStructure>
    </Box>
  );
};

export default Dashboard;
