import React, { useEffect, useState } from 'react';

import { AcquirementLabel, AchievementsContainer, Container } from './styles';
import { Box } from '@mui/material';
import AcquiredImg from '../../../../images/illustrations/acquired';
import NotAcquiredImg from '../../../../images/illustrations/notAquired';
import { getAchievementsService, getUserAchievementsService } from '../../../../services/achievements';
import CustomAlert from '../../../../shared/components/alert';
import CustomSnackBar from '../../../../shared/components/toast';
import SkeletonAchievements from './components/achievementsSkeleton';

interface IUserAchievement {
  id: string;
  description: string;
  hited: boolean;
}

const Achievements: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userAchievements, setUserAchievements] = useState<IUserAchievement[]>([]);
  const [allAchievements, setAllAchievements] = useState([]);
  const [snackOpen, setSnackOpen] = useState(false);

  const getUserAchievements = async () => {
    setIsLoading(true);

    const [achievementsResponse, userAchievementsResponse] = await Promise.all([
      getAchievementsService(),
      getUserAchievementsService(),
    ]);

    if (achievementsResponse.status === 200 && userAchievementsResponse.status === 200) {
      const userAchievements = userAchievementsResponse.data.result;
      const achievements = achievementsResponse.data.result;

      const userAchievementsResult: IUserAchievement[] = [];

      for (const achievement of achievements) {
        const wasHitedTheAchievement = userAchievements.some((item: string) => item === achievement.id);

        userAchievementsResult.push({
          description: achievement.description,
          id: achievement.id,
          hited: wasHitedTheAchievement,
        });
      }

      setUserAchievements(userAchievementsResult);
    } else if (userAchievementsResponse.status !== 200) {
      const achievements = achievementsResponse.data.result;
      const userAchievementsResult: IUserAchievement[] = [];

      for (const achievement of achievements) {
        userAchievementsResult.push({
          description: achievement.description,
          id: achievement.id,
          hited: false,
        });
      }

      setAllAchievements(achievements);
      setUserAchievements(userAchievementsResult);
      setSnackOpen(true);
    } else {
      setSnackOpen(true);
    }
  };

  useEffect(() => {
    getUserAchievements();
  }, []);

  useEffect(() => {
    if (userAchievements.length > 0 || allAchievements.length > 0) {
      setIsLoading(false);
    }
  }, [userAchievements, allAchievements]);

  return (
    <Container>
      <AchievementsContainer>
        {isLoading ? (
          <SkeletonAchievements />
        ) : (
          userAchievements.map((item) => {
            return (
              <Box key={item.id} display={'flex'} flexDirection={'column'} gap="18px" alignItems={'center'}>
                {item.hited ? <AcquiredImg /> : <NotAcquiredImg />}
                <AcquirementLabel hited={item.hited}>{item.description}</AcquirementLabel>
              </Box>
            );
          })
        )}
      </AchievementsContainer>
      <CustomSnackBar isOpen={snackOpen}>
        <CustomAlert
          showLeftIcon={true}
          showCloseIcon={true}
          severity={'error'}
          title={'Ops! Não conseguimos carregar suas conquistas no momento.'}
          onClose={() => setSnackOpen(false)}
        />
      </CustomSnackBar>
    </Container>
  );
};

export default Achievements;
