import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { defaultShadow } from '../../../styles/theme';

interface ILeftColumn {
  fullWidth: boolean;
}

export const Container = styled(Box)((props) => ({
  paddingBottom: '32px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  '@media (max-width: 768px)': {
    paddingTop: '24px',
    paddingBottom: '24px',
  },
}));

export const ContentContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  gap: '28px',
  marginTop: '12px',
  marginBottom: '24px',

  '@media (max-width: 768px)': {
    marginTop: '12px',
    marginBottom: '12px',
  },
}));

export const StyledTitle = styled(Typography)((props) => ({
  fontSize: '24px',
  fontWeight: 700,
  color: props.theme.palette.purple.main,
  letterSpacing: '0.8px',
  userSelect: 'none',
  marginBottom: '0px',
}));

export const LeftColumn = styled(Box)<ILeftColumn>((props) => ({
  width: props.fullWidth ? '100%' : '82%',

  '@media (max-width: 768px)': {
    width: '100%',
  },
}));

export const RightColumn = styled(Box)(() => ({
  width: '18%',
  minWidth: '320px',
}));

export const CountDownContainer = styled(Box)((props) => ({
  display: 'flex',
  backgroundColor: props.theme.palette.white.main,
  borderRadius: '16px',
  padding: '16px 32px',
  flexDirection: 'column',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '20px',
  boxShadow: defaultShadow,
}));

export const CountDown = styled(Typography)((props) => ({
  color: props.theme.palette.purple.main,
  textAlign: 'center',
  fontSize: '24px',
  fontWeight: 600,
  marginTop: '8px',

  '@media (max-width: 768px)': {
    textAlign: 'end',
    fontSize: '16px',
    marginTop: '0px',
  },
}));

export const StyledSpan = styled(Typography)((props) => ({
  color: props.theme.palette.textColor,
  textAlign: 'center',
  fontSize: '12px',
  fontWeight: 500,
  letterSpacing: '0.5px',

  '@media (max-width: 768px)': {
    textAlign: 'end',
  },
}));
