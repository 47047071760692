import * as React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton } from '@mui/material';

import { StyledAlert, StyledDescription, StyledTitle } from './styles';

interface ICustomAlertProps {
  showLeftIcon: boolean;
  showCloseIcon: boolean;
  severity?: 'warning' | 'error' | 'info' | 'success';
  title: string;
  description?: string;
  onClose?: (event: React.SyntheticEvent) => void;
}

const CustomAlert: React.FC<ICustomAlertProps> = ({
  showLeftIcon,
  showCloseIcon,
  severity,
  title,
  description,
  onClose,
}) => {
  return (
    <StyledAlert
      hasDescription={Boolean(description)}
      icon={showLeftIcon ? null : false}
      severity={severity}
      action={
        showCloseIcon ? (
          <IconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        ) : null
      }
    >
      <Box display={'flex'} flexDirection={'column'}>
        <StyledTitle>{title}</StyledTitle>
        {!!description && <StyledDescription>{description}</StyledDescription>}
      </Box>
    </StyledAlert>
  );
};

export default CustomAlert;
