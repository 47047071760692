import React, { useEffect, useState } from 'react';

import { ButtonsContainer, StyledContainer, StyledLink, StyledSpan, StyledTitle } from './styles';
import CustomButton from '../../../../shared/components/button';
import { Box } from '@mui/material';

interface ICallWhatsapp {
  handleBackButtom: () => void;
}

const CallWhatsapp: React.FC<ICallWhatsapp> = ({ handleBackButtom }) => {
  return (
    <StyledContainer>
      <Box display={'flex'} flexDirection={'column'} gap={'36px'} sx={{ height: '100%', justifyContent: 'center' }}>
        <StyledTitle>Cadastro concluído!</StyledTitle>
        <StyledSpan>
          Agora que finalizamos seu cadastro, é só entrar em contato com o atendimento através do botão abaixo para
          ativar sua conta.
        </StyledSpan>
      </Box>
      <Box width={'100%'} justifyContent={'center'} display={'flex'}>
        <ButtonsContainer>
          <StyledLink href={'https://api.whatsapp.com/send?phone=+5535992575433&text=Olá!'} target="_blank">
            <CustomButton classification="primary" uppercase={false} title="Falar com atendimento" onClick={() => {}} />
          </StyledLink>
          <CustomButton classification="secondary" uppercase={false} title="Voltar" onClick={handleBackButtom} />
        </ButtonsContainer>
      </Box>
    </StyledContainer>
  );
};

export default CallWhatsapp;
