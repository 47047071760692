import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { tokenKey } from '../../../shared/util/constants';
import { Container, LeftColumn, RightColumn } from './styles';
import LoginForm from '../components/loginForm';
import SignUpForm from '../components/signUpForm';
import CustomAlert from '../../../shared/components/alert';
import CustomSnackBar from '../../../shared/components/toast';
import { ToastContext } from '../../../contexts/toast';
import LoginLogo from '../../../images/logo/login';
import CallWhatsapp from '../components/callWhatsapp';

const Login: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem(tokenKey));
  const [showSignUpForm, setShowSignUpForm] = useState<boolean>(false);
  const [showCallWhatsapp, setShowCallWhatsapp] = useState<boolean>(false);
  const { message, type } = useContext(ToastContext);
  const [snackOpen, setSnackOpen] = useState(!!message);

  useEffect(() => {
    setSnackOpen(!!message);
  }, [message]);

  return (
    <>
      {isAuthenticated ? (
        <Navigate to={'/dashboard'} />
      ) : (
        <Container>
          <LeftColumn>
            <LoginLogo />
          </LeftColumn>

          <RightColumn>
            {showSignUpForm ? (
              <SignUpForm
                handleShowSignUpForm={() => {
                  setShowSignUpForm(false);
                  setShowCallWhatsapp(false);
                }}
                handleShowNextStep={() => {
                  setShowSignUpForm(false);
                  setShowCallWhatsapp(true);
                }}
              />
            ) : showCallWhatsapp ? (
              <CallWhatsapp
                handleBackButtom={() => {
                  setShowSignUpForm(false);
                  setShowCallWhatsapp(false);
                }}
              />
            ) : (
              <LoginForm
                setIsAuthenticated={() => setIsAuthenticated(true)}
                handleShowSignUpForm={() => setShowSignUpForm(true)}
              />
            )}
          </RightColumn>
        </Container>
      )}
      <CustomSnackBar isOpen={snackOpen}>
        <CustomAlert
          showLeftIcon={true}
          showCloseIcon={true}
          severity={type}
          title={message}
          onClose={() => setSnackOpen(false)}
        />
      </CustomSnackBar>
    </>
  );
};

export default Login;
