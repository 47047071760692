import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { useState } from 'react';
import { Container, Header, Span, TimeTag, TimeTagsContainer, Title } from './styles';
import CustomButton from '../../../../shared/components/button';
import CustomTextInput from '../../../../shared/components/textInput';
import { onlyIntegers } from '../../../../shared/util/functions';
import CloseIcon from '../../../../images/icons/closeIcon';
import { defaultColors, mobileBreakPoint } from '../../../../styles/theme';

interface ICustomizeTest {
  isOpen: boolean;
  onClose: any;
  handleClickButtom: any;
}

interface ITagTimeOption {
  label: string;
  value: number;
  isSelected: boolean;
  seconds: number;
}

var timeOptions: ITagTimeOption[] = [
  { label: '30 min', value: 1, isSelected: false, seconds: 1800 },
  { label: '1 hora', value: 2, isSelected: false, seconds: 3600 },
  { label: '1h 30m', value: 3, isSelected: false, seconds: 5400 },
  { label: '2 horas', value: 4, isSelected: false, seconds: 7200 },
  { label: '2h 30m', value: 5, isSelected: false, seconds: 9000 },
  { label: '3 horas', value: 6, isSelected: false, seconds: 10800 },
  { label: '3h 30m', value: 7, isSelected: false, seconds: 12600 },
  { label: '4 horas', value: 8, isSelected: false, seconds: 14400 },
];

const CustomizeTest: React.FC<ICustomizeTest> = ({ isOpen, onClose, handleClickButtom }) => {
  const [timeTagOptions, setTimeTagOptions] = useState(timeOptions);
  const [inputValue, setInputValue] = useState('');
  const [tagSelected, setTagSelected] = useState(0);

  const isMobile = window.screen.width <= mobileBreakPoint;

  const handleTagTimeClick = (param: ITagTimeOption) => {
    const newOptions = timeOptions.map((item) => ({ ...item, isSelected: item.value === param.value }));
    setTimeTagOptions(newOptions);
    setTagSelected(param.seconds);
  };

  return (
    <Drawer anchor={'right'} open={isOpen} onClose={onClose}>
      <Header>
        <CloseIcon onClick={onClose} style={{ cursor: 'pointer' }} fill={defaultColors.gray.main} />
      </Header>
      <Container>
        <Box>
          <Title>Personalizar simulado</Title>
          <Span>Quantas questões gostaria de responder?</Span>
          <Box mb={'5%'} mt={'12px'}>
            <CustomTextInput
              value={inputValue}
              onChange={(event: any) => setInputValue(onlyIntegers(event.target.value))}
              maxLength={2}
              fullWidth={isMobile}
            />
          </Box>

          <Span>Em quanto tempo deseja fazer o simulado?</Span>
          <TimeTagsContainer>
            {timeTagOptions.map((item) => (
              <TimeTag isSelected={item.isSelected} key={item.value} onClick={() => handleTagTimeClick(item)}>
                {item.label}
              </TimeTag>
            ))}
          </TimeTagsContainer>
        </Box>
        {isMobile ? (
          <CustomButton
            classification="primary"
            uppercase={false}
            title="Começar"
            onClick={() => handleClickButtom({ tagSelected, inputValue })}
            disabled={inputValue === '' || tagSelected === 0}
          />
        ) : (
          <Box width={'40%'}>
            <CustomButton
              fullWidth
              classification="primary"
              uppercase={false}
              title="Começar"
              onClick={() => handleClickButtom({ tagSelected, inputValue })}
              disabled={inputValue === '' || tagSelected === 0}
            />
          </Box>
        )}
      </Container>
    </Drawer>
  );
};

export default CustomizeTest;
