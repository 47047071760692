import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { defaultShadow } from '../../../styles/theme';
import { Link } from 'react-router-dom';

interface ITooltipOption {
  hovered?: boolean;
  first?: boolean;
  last?: boolean;
  notification?: boolean;
  single?: boolean;
}

export const Container = styled(Box)((props) => ({
  gridArea: 'HD',
  backgroundColor: props.theme.palette.backgroundColor,
  borderRadius: ' 20px 20px 0px 0px',

  '@media (max-width: 768px)': {
    backgroundColor: props.theme.palette.purple.main,
  },
}));

export const Content = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: '3.5%',
  paddingRight: '3.5%',
  paddingTop: '18px',
  height: '100%',

  '@media (max-width: 768px)': {
    paddingTop: '0px',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
}));

export const Column = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  '@media (max-width: 768px)': {
    paddingLeft: '22px',
    paddingRight: '22px',
  },
}));

export const SystemName = styled(Typography)((props) => ({
  color: props.theme.palette.purple.main,
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',

  '@media (max-width: 768px)': {
    color: props.theme.palette.white.main,
    fontSize: '20px',
    fontWeight: 700,
  },
}));

export const UserDataContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  userSelect: 'none',
}));

export const UserName = styled(Typography)((props) => ({
  color: props.theme.palette.purple.main,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '24px',
}));

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    color: 'black',
    width: '220px',
  },
}));

export const TooltipOptionLabel = styled(Typography)<ITooltipOption>((props) => ({
  fontSize: '12px',
  lineHeight: '20px',
  fontWeight: props.hovered ? 600 : 400,
}));

export const TooltipOption = styled(Box)<ITooltipOption>((props) => ({
  display: 'flex',
  padding: '16px',
  minWidth: props.notification ? '300px' : '170px',
  cursor: !props.notification ? 'pointer' : 'default',
  gap: '9px',
  alignItems: 'center',
  background: props.theme.palette.white.main,
  color: props.theme.palette.gray.main,
  borderRadius: props.first ? '10px 10px 0px 0px' : props.last ? '0px 0px 10px 10px' : props.single ? '10px' : '0px',
  boxShadow: defaultShadow,
  userSelect: 'none',

  '&:hover': {
    background: !props.notification && props.theme.palette.purple.light,
    color: !props.notification && props.theme.palette.purple.main,
  },
}));

export const ActionsContainer = styled(Box)(() => ({
  display: 'flex',
  alignSelf: 'flex-end',
  cursor: 'pointer',
  padding: '0px 19px',
}));

export const MenuItemLink = styled(Link)(() => ({
  cursor: 'pointer',
  textDecoration: 'none',
  width: '100%',
  height: '100%',
}));
