import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { defaultShadow } from '../../../styles/theme';

export const OptionsContainer = styled(Box)((props) => ({
  display: 'flex',
  gap: '24px',
  marginTop: '24px',
  width: '50%',

  '@media (max-width: 768px)': {
    flexDirection: 'column',
    width: '100%',
  },
}));

export const TestOptionContainer = styled(Box)((props) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  boxShadow: defaultShadow,
  padding: '12px',
  borderRadius: '24px',
  justifyContent: 'space-between',
  width: '100%',
}));

export const TestOptionTitle = styled(Typography)((props) => ({
  color: props.theme.palette.textColor,
  fontSize: '16px',
  fontWeight: 700,

  '@media (max-width: 768px)': {
    fontSize: '14px',
  },
}));

export const TestOptionContent = styled(Typography)((props) => ({
  color: props.theme.palette.textColor,
  fontSize: '14px',
  fontWeight: 400,
  marginBottom: '8px',

  '@media (max-width: 768px)': {
    fontSize: '12px',
  },
}));

export const Explanation = styled(Typography)((props) => ({
  color: props.theme.palette.textColor,
  fontSize: '14px',
  letterSpacing: '0.5px',
  fontWeight: 400,

  '@media (max-width: 768px)': {
    fontSize: '12px',
  },
}));

export const ButtonsContainer = styled(Box)((props) => ({
  display: 'flex',
  gap: '24px',

  '@media (max-width: 768px)': {
    flexDirection: 'column',
    gap: '16px',
  },
}));
