import React, { useState } from 'react';
import { createContext } from 'react';

interface IToastContext {
  message: any;
  type: any;
  setMessage: any;
}

interface IToastData {
  message: any;
  type: 'warning' | 'error' | 'info' | 'success';
  setMessage: any;
}

export const ToastContext = createContext<IToastContext>({} as IToastContext);

export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const [toastData, setToastData] = useState<IToastData>();
  return (
    <ToastContext.Provider value={{ message: toastData?.message, type: toastData?.type, setMessage: setToastData }}>
      <>{children}</>
    </ToastContext.Provider>
  );
};
