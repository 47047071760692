import React from 'react';

import {
  Container,
  DashboardCard,
  DashboardCardContent,
  DashboardCardTitle,
  HeaderLabel,
  Info,
  InfoContainer,
} from './styles';
import { mobileBreakPoint } from '../../../../styles/theme';
import { Box } from '@mui/material';
import rocketImage from '../../../../images/dashboardCardsImages/rocket.png';
import check_circle_outline from '../../../../images/dashboardCardsImages/check_circle_outline.png';
import trending_up from '../../../../images/dashboardCardsImages/trending_up.png';
import query_builder from '../../../../images/dashboardCardsImages/query_builder.png';

interface IGeneralInfo {
  data: any;
}

const GeneralInfo: React.FC<IGeneralInfo> = ({ data }) => {
  const isMobile = window.screen.width <= mobileBreakPoint;

  const getTimeAverageFormated = () => {
    const averageTime = data.totalSpendedTime / data.answeredQuestions;
    if (averageTime) {
      const averageTimeInSeconds = averageTime / 1000;
      let minutes: any = Math.floor((averageTimeInSeconds % 3600) / 60);
      let seconds: any = Math.floor(averageTimeInSeconds % 60);

      if (minutes < 10) minutes = `0${minutes}`;
      if (seconds < 10) seconds = `0${seconds}`;

      return `${minutes}m ${seconds}s`;
    }
    return '-';
  };

  return (
    <Box>
      {isMobile ? (
        <>
          <Box display={'flex'} gap={'20px'} flexDirection={'column'}>
            <DashboardCard urlImage={rocketImage}>
              <DashboardCardTitle>Questões respondidas</DashboardCardTitle>
              <DashboardCardContent>{data.answeredQuestions}</DashboardCardContent>
            </DashboardCard>
            <DashboardCard urlImage={check_circle_outline}>
              <DashboardCardTitle>Quantidade de acertos</DashboardCardTitle>
              <DashboardCardContent>{data.correctAnswers}</DashboardCardContent>
            </DashboardCard>
            <DashboardCard urlImage={trending_up}>
              <DashboardCardTitle>Aproveitamento</DashboardCardTitle>
              <DashboardCardContent>{data.rate.toFixed(2)}%</DashboardCardContent>
            </DashboardCard>
            <DashboardCard urlImage={query_builder}>
              <DashboardCardTitle>Tempo médio por questão</DashboardCardTitle>
              <DashboardCardContent>{getTimeAverageFormated()}</DashboardCardContent>
            </DashboardCard>
          </Box>
        </>
      ) : (
        <Box>
          <Box display={'flex'} gap={'20px'}>
            <DashboardCard urlImage={rocketImage}>
              <DashboardCardTitle>Questões respondidas</DashboardCardTitle>
              <DashboardCardContent>{data.answeredQuestions}</DashboardCardContent>
            </DashboardCard>
            <DashboardCard urlImage={check_circle_outline}>
              <DashboardCardTitle>Quantidade de acertos</DashboardCardTitle>
              <DashboardCardContent>{data.correctAnswers}</DashboardCardContent>
            </DashboardCard>
            <DashboardCard urlImage={trending_up}>
              <DashboardCardTitle>Aproveitamento</DashboardCardTitle>
              <DashboardCardContent>{data.rate.toFixed(2)}%</DashboardCardContent>
            </DashboardCard>
            <DashboardCard urlImage={query_builder}>
              <DashboardCardTitle>Tempo médio por questão</DashboardCardTitle>
              <DashboardCardContent>{getTimeAverageFormated()}</DashboardCardContent>
            </DashboardCard>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default GeneralInfo;
