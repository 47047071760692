import { Box, Typography, FormControlLabel, Radio } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  height: '100%',
}));

export const StyledLabel = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 400,

  '@media (max-width: 768px)': {
    fontSize: '12px',
  },
}));

export const AnswersContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
}));

export const AnswerLabel = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 400,

  '@media (max-width: 768px)': {
    fontSize: '12px',
  },
}));

export const StyledRadioButton = styled(Radio)((props) => ({
  color: props.theme.palette.gray.main,
  '&.Mui-checked': {
    color: props.theme.palette.purple.main,
  },

  '@media (max-width: 768px)': {
    width: '22px',
    height: '22px',
  },
}));

export const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  display: 'flex',

  '& + &': {
    marginTop: '12px',
  },

  '@media (max-width: 768px)': {
    fontSize: '12px',
  },
}));
