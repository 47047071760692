import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled(Box)((props) => ({
  display: 'flex',
  gap: '24px',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  marginTop: '6vh',
}));

export const Explanation = styled(Typography)((props) => ({
  color: props.theme.palette.textColor,
  fontSize: '16px',
  fontWeight: 400,
  marginTop: '24px',

  '@media (max-width: 768px)': {
    textAlign: 'center',
    fontSize: '12px',
  },
}));
