import { ILoginInput } from '../interfaces/input/login';
import axiosInstance from '../shared/util/axiosInstance';

export const postLoginRepository = async (login: ILoginInput) => {
  const result: any = await axiosInstance
    .post(`/login`, login)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return result;
};
