import * as React from 'react';
import { SVGProps } from 'react';

const NotificationsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={29} height={29} fill="none" {...props}>
    <path
      d="M14.4999 26.2813C15.8291 26.2813 16.9166 25.1938 16.9166 23.8646H12.0833C12.0833 25.1938 13.1708 26.2813 14.4999 26.2813ZM21.7499 19.0313V12.9896C21.7499 9.28 19.7803 6.17458 16.3124 5.35292V4.53125C16.3124 3.52833 15.5028 2.71875 14.4999 2.71875C13.497 2.71875 12.6874 3.52833 12.6874 4.53125V5.35292C9.23159 6.17458 7.24992 9.26792 7.24992 12.9896V19.0313L4.83325 21.4479V22.6563H24.1666V21.4479L21.7499 19.0313ZM19.3333 20.2396H9.66658V12.9896C9.66658 9.99292 11.4912 7.55208 14.4999 7.55208C17.5087 7.55208 19.3333 9.99292 19.3333 12.9896V20.2396Z"
      fill={props.fill}
    />
  </svg>
);

export default NotificationsIcon;
