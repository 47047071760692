import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled(Box)((props) => ({
  display: 'flex',
  gap: '24px',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  marginTop: '2vh',
}));

export const SuitabilityLabel = styled(Typography)((props) => ({
  fontSize: '16px',
  fontWeight: 600,
  marginTop: '20px',
  padding: '8px 24px',
  borderRadius: '16px',
  color: props.theme.palette.purple.main,
  border: `2px dashed ${props.theme.palette.purple.main}`,

  '@media (max-width: 768px)': {
    fontSize: '16px',
  },
}));

export const SuitabilityDescription = styled(Typography)((props) => ({
  fontSize: '14px',
  fontWeight: 500,

  '@media (max-width: 768px)': {
    fontSize: '12px',
    marginTop: '0px',
  },
}));

export const ButtonsContainer = styled(Box)((props) => ({
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
  justifyContent: 'center',

  '@media (max-width: 768px)': {
    flexDirection: 'column',
    width: '100%',
  },
}));
