import * as React from 'react';
import { SVGProps } from 'react';

const CheckedCircleSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} {...props}>
    <path
      d="M6.854 9.646 4.958 7.75a.633.633 0 0 0-.468-.188.634.634 0 0 0-.469.188c-.153.153-.229.316-.229.49 0 .173.076.33.229.468l2.25 2.25c.153.167.337.25.552.25a.859.859 0 0 0 .594-.25l4.562-4.583a.621.621 0 0 0 .188-.458.625.625 0 0 0-.188-.459.648.648 0 0 0-.469-.208c-.173 0-.33.07-.468.208L6.854 9.646ZM8 15.917a7.723 7.723 0 0 1-3.094-.625 7.974 7.974 0 0 1-2.51-1.688 7.974 7.974 0 0 1-1.688-2.51A7.723 7.723 0 0 1 .083 8c0-1.097.208-2.129.625-3.094a7.974 7.974 0 0 1 1.688-2.51A7.974 7.974 0 0 1 4.906.708 7.723 7.723 0 0 1 8 .083c1.097 0 2.129.208 3.094.625.965.417 1.802.98 2.51 1.688a7.974 7.974 0 0 1 1.688 2.51c.417.965.625 1.997.625 3.094a7.723 7.723 0 0 1-.625 3.094 7.974 7.974 0 0 1-1.688 2.51 7.974 7.974 0 0 1-2.51 1.688A7.723 7.723 0 0 1 8 15.917Zm0-1.334c1.82 0 3.371-.642 4.656-1.927 1.285-1.285 1.927-2.837 1.927-4.656 0-1.82-.642-3.371-1.927-4.656C11.371 2.059 9.819 1.417 8 1.417c-1.82 0-3.371.642-4.656 1.927C2.059 4.629 1.417 6.181 1.417 8c0 1.82.642 3.371 1.927 4.656C4.629 13.941 6.181 14.583 8 14.583Z"
      fill={props.fill}
    />
  </svg>
);

export default CheckedCircleSmall;
