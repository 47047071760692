import * as React from 'react';
import { SVGProps } from 'react';

const UnreadNotificationsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={29} height={28} fill="none" {...props}>
    <path
      fill="#6149F7"
      fillRule="evenodd"
      d="m19.62 10.752 1.592 5.944 3.053 1.73.319 1.19-19.328 5.178-.319-1.188 1.78-3.025-1.593-5.944c-.981-3.662.184-7.236 3.422-8.97l-.216-.809c-.265-.986.331-2 1.334-2.269 1.003-.268 2.025.311 2.29 1.298l.216.809c3.684-.121 6.472 2.407 7.45 6.056ZM9.45 21.122l9.665-2.59-1.912-7.132c-.79-2.949-3.257-4.861-6.265-4.055C7.93 8.15 6.75 11.04 7.54 13.989l1.91 7.133ZM10 24.035A2 2 0 0 0 13.864 23L10 24.035Z"
      clipRule="evenodd"
    />
    <circle cx={20.5} cy={19.5} r={8.5} fill="#fff" />
    <path fill="#F76257" d="M28 19.5a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0Z" />
    <rect width={3} height={7} x={19} y={14} fill="#fff" rx={1.5} />
    <circle cx={20.5} cy={23.5} r={1.5} fill="#fff" />
  </svg>
);

export default UnreadNotificationsIcon;
