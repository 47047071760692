import * as React from 'react';
import { SVGProps } from 'react';

const MenuIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={22} height={22} fill="none" {...props}>
    <path
      d="M2.75 16.5007H19.25V14.6674H2.75V16.5007ZM2.75 11.9174H19.25V10.0841H2.75V11.9174ZM2.75 5.50073V7.33407H19.25V5.50073H2.75Z"
      fill={props.fill}
    />
  </svg>
);

export default MenuIcon;
