import React, { useState, useEffect, useRef, useContext } from 'react';
import RankingIndicator from '../rankingIndicator';
import { Box, Skeleton } from '@mui/material';
import {
  Container,
  ContentContainer,
  CountDown,
  CountDownContainer,
  LeftColumn,
  RightColumn,
  StyledSpan,
  StyledTitle,
} from './styles';
import { defaultColors, mobileBreakPoint } from '../../../styles/theme';
import NextTestIndicator from '../nextTestIndicator';
import { UserContext } from '../../../contexts/user';
import WaterMark from '../../../images/logo/water_mark';
interface IPageStructure {
  title?: string;
  footerContent?: React.ReactElement | null;
  hideNextTestIndicator?: boolean;
  timeToCountDown?: number;
  stopCountDown?: boolean;
  onFinishCountDown?: Function;
  setRemainingTime?: Function;
}

const PageStructure: React.FC<IPageStructure> = ({
  title,
  children,
  footerContent,
  hideNextTestIndicator,
  timeToCountDown,
  stopCountDown,
  onFinishCountDown,
  setRemainingTime,
}) => {
  const [countDown, setCountDown] = useState(timeToCountDown);
  const timerId = useRef<any>();
  const isMobile = window.screen.width <= mobileBreakPoint;
  const { userData } = useContext(UserContext);
  const [rankingData, setRankingData] = useState<any>();
  const [nexTestDate, setNexTestDate] = useState<any>();

  const shouldRenderNextTestIndicator = () => {
    if (isMobile) {
      return false;
    } else {
      return hideNextTestIndicator ? false : true;
    }
  };

  const formatTime = (time: any) => {
    if (time) {
      let hours: any = Math.floor(time / 3600);
      let minutes: any = Math.floor((time % 3600) / 60);
      let seconds: any = Math.floor(time % 60);

      if (hours < 10) hours = `0${hours}`;
      if (minutes < 10) minutes = `0${minutes}`;
      if (seconds < 10) seconds = `0${seconds}`;

      return `${hours}:${minutes}:${seconds}`;
    }
  };

  useEffect(() => {
    if (timeToCountDown) {
      if (!countDown) {
        setCountDown(timeToCountDown);
        return;
      }
    }
  }, [timeToCountDown]);

  useEffect(() => {
    if (countDown && countDown <= 0) {
    } else {
      if (countDown === 0) {
        if (onFinishCountDown) onFinishCountDown();
        return;
      }
      timerId.current = setInterval(() => {
        setCountDown((prev: any) => prev - 1);
      }, 1000);
      return () => clearInterval(timerId.current);
    }
  }, [countDown]);

  useEffect(() => {
    if (stopCountDown) {
      if (setRemainingTime) {
        setRemainingTime(countDown);
      }
      setCountDown(0);
    }
  }, [stopCountDown]);

  useEffect(() => {
    if (userData && userData?.isActiveOnRanking) {
      const rankingData = {
        userName: userData.name,
        position: userData.positionOnRanking,
      };
      setRankingData(rankingData);
    }

    if (userData && userData?.nexTest?.length > 0) {
      setNexTestDate(userData?.nexTest[0]);
    }
  }, [userData]);

  return (
    <Container>
      <Box>
        <StyledTitle>{title}</StyledTitle>
        <ContentContainer>
          <LeftColumn fullWidth={!shouldRenderNextTestIndicator()}>
            {
              <>
                {isMobile && timeToCountDown !== 0 && timeToCountDown && !stopCountDown ? (
                  <Box width={'100%'} display={'flex'} flexDirection={'column'}>
                    <StyledSpan>Tempo restante</StyledSpan>
                    <CountDown>{countDown === 0 ? '-' : formatTime(countDown)}</CountDown>
                  </Box>
                ) : (
                  <></>
                )}
                {children}
              </>
            }
          </LeftColumn>
          {shouldRenderNextTestIndicator() && (
            <Box margin={0} padding={0} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
              <RightColumn>
                {timeToCountDown !== 0 && timeToCountDown ? (
                  <CountDownContainer>
                    <StyledSpan>Tempo restante</StyledSpan>
                    <CountDown>{countDown === 0 ? '-' : formatTime(countDown)}</CountDown>
                  </CountDownContainer>
                ) : (
                  <></>
                )}
                {nexTestDate ? (
                  <NextTestIndicator dateString={nexTestDate.nextTestDate} />
                ) : userData?.error || !nexTestDate ? (
                  <></>
                ) : (
                  <Skeleton
                    sx={{ bgcolor: defaultColors.purple.light, borderRadius: '8px' }}
                    variant="rectangular"
                    animation="wave"
                    width={'100%'}
                    height={80}
                  />
                )}

                {rankingData ? (
                  <Box mt={'20px'}>
                    <RankingIndicator rankingData={rankingData} />
                  </Box>
                ) : userData?.error || !userData?.isActiveOnRanking ? (
                  <></>
                ) : (
                  <Box mt={'20px'}>
                    <Skeleton
                      sx={{ bgcolor: defaultColors.purple.light, borderRadius: '8px' }}
                      variant="rectangular"
                      animation="wave"
                      width={'100%'}
                      height={80}
                    />
                  </Box>
                )}
              </RightColumn>
              {/* <Box textAlign={'center'}>
                <WaterMark />
              </Box> */}
            </Box>
          )}
        </ContentContainer>
      </Box>
      {footerContent}
    </Container>
  );
};

export default PageStructure;
