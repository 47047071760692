import * as React from 'react';
import { SVGProps } from 'react';

const CheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={14} height={10} fill="none" {...props}>
    <path
      d="M4.98 9.396a.725.725 0 0 1-.292-.063.857.857 0 0 1-.27-.187L1 5.729a.635.635 0 0 1-.198-.489A.749.749 0 0 1 1 4.75a.67.67 0 0 1 .49-.208c.188 0 .344.07.468.208l3.063 3.062L12.062.771a.594.594 0 0 1 .46-.209c.18 0 .333.07.457.209.14.139.21.295.21.469 0 .173-.07.33-.21.468L5.542 9.146a.857.857 0 0 1-.27.187.732.732 0 0 1-.293.063Z"
      fill={props.fill}
    />
  </svg>
);

export default CheckIcon;
