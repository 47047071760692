import * as React from 'react';

import { Box, FormControl, InputAdornment } from '@mui/material';

import { StyledHelperText, StyledLabel, StyledOption, StyledTextInput, StyledErrorText } from './styles';

interface ICustomTextInputProps {
  label?: string;
  endAdornment?: any;
  startAdornment?: any;
  helperText?: string;
  optional?: string;
  errorMessage?: string;
  value: string | undefined;
  onChange: (event: any) => void;
  fullWidth?: boolean;
  minWidth?: number | string;
  maxLength: number;
  multiline?: boolean;
  rows?: number;
  type?: string;
  onBlur?: () => void;
  placeholder?: string;
  disabled?: boolean;
}

const CustomTextInput: React.FC<ICustomTextInputProps> = ({
  onBlur,
  type,
  label,
  endAdornment,
  startAdornment,
  helperText,
  optional,
  errorMessage,
  value,
  onChange,
  fullWidth,
  minWidth,
  maxLength,
  multiline,
  rows,
  placeholder,
  disabled,
}) => {
  return (
    <FormControl sx={{ minWidth: fullWidth ? '100%' : minWidth }} variant="outlined">
      <Box display={'flex'} justifyContent={'space-between'}>
        <StyledLabel>{label}</StyledLabel>
        <StyledOption>{optional}</StyledOption>
      </Box>
      <StyledTextInput
        error={!!errorMessage}
        type={type}
        inputProps={{ maxLength: maxLength }}
        multiline={multiline}
        rows={rows}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        endAdornment={<InputAdornment position="end">{endAdornment}</InputAdornment>}
        startAdornment={<InputAdornment position="start">{startAdornment}</InputAdornment>}
        onBlur={onBlur}
        disabled={disabled}
      />
      {errorMessage ? (
        <StyledErrorText>{errorMessage}</StyledErrorText>
      ) : (
        <StyledHelperText>{helperText}</StyledHelperText>
      )}
    </FormControl>
  );
};

export default CustomTextInput;
