import { Box, Step, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface IStyledStepItemProps {
  active: boolean;
  completed?: boolean;
}

interface IStyledStepContainer {
  last: boolean;
  first: boolean;
}

export const StyledStep = styled(Box)<IStyledStepItemProps>((props) => ({
  background: props.active
    ? props.theme.palette.white.main
    : props.completed
    ? props.theme.palette.purple.main
    : props.theme.palette.white.main,
  width: '32px',
  height: '32px',
  borderRadius: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: props.active
    ? `1.5px solid ${props.theme.palette.purple.main}`
    : props.completed
    ? 'none'
    : `1.5px solid ${props.theme.palette.gray.light}`,
}));

export const StyledItemContent = styled(Box)<IStyledStepItemProps>((props) => ({
  color: props.active ? props.theme.palette.purple.main : props.theme.palette.gray.main,
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '14px',
}));

export const StyledStepContainer = styled(Step)<IStyledStepContainer>((props) => ({
  padding: '16px 24px',
  backgroundColor: props.theme.palette.white.main,
  display: 'flex',
  gap: '12px',
  alignItems: 'center',
  borderRight: !props.last
    ? `0px solid ${props.theme.palette.gray.light}`
    : `1.5px solid ${props.theme.palette.gray.light}`,
  borderLeft: !props.first
    ? `0.5px solid ${props.theme.palette.gray.light}`
    : `1.5px solid ${props.theme.palette.gray.light}`,
  borderTop: `1.5px solid ${props.theme.palette.gray.light}`,
  borderBottom: props.active
    ? `1.5px solid ${props.theme.palette.purple.main}`
    : `1.5px solid ${props.theme.palette.gray.light}`,
  '&:after': !props.last && {
    content: '""',
    width: '16px',
    height: '16px',
    backgroundColor: props.theme.palette.white.main,
    transform: 'rotate(45deg)',
    marginRight: '-33px',
    borderRight: !props.last
      ? `1.5px solid ${props.theme.palette.gray.light}`
      : `1.5px solid ${props.theme.palette.gray.light}`,
    borderTop: !props.last
      ? `1.5px solid ${props.theme.palette.gray.light}`
      : `1.5px solid ${props.theme.palette.gray.light}`,
  },
}));

export const StyledLabel = styled(Typography)<IStyledStepItemProps>((props) => ({
  color: props.active
    ? props.theme.palette.purple.main
    : props.completed
    ? props.theme.palette.textColor
    : props.theme.palette.gray.main,
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '14px',
}));

export const StyledContent = styled(Typography)((props) => ({
  color: props.theme.palette.gray.main,
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '14px',
  marginTop: '8px',
}));
