import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface IAcquirementLabel {
  hited: boolean;
}

export const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '18px',
  marginBottom: '24px',
}));

export const AchievementsContainer = styled(Box)(() => ({
  display: 'flex',
  gap: '32px',
  marginTop: '-4px',
  flexWrap: 'wrap',

  '@media (max-width: 768px)': {
    justifyContent: 'center',
    gapX: '12px',
    gapY: '32px',
  },
}));

export const AcquirementLabel = styled(Typography)<IAcquirementLabel>((props) => ({
  color: props.hited ? props.theme.palette.purple.main : props.theme.palette.textColor,
  fontSize: '14px',
  fontWeight: props.hited ? 600 : 400,
  maxWidth: '150px',
  textAlign: 'center',
  width: '100%',

  '@media (max-width: 768px)': {
    fontSize: '12px',
    maxWidth: '120px',
  },
}));
