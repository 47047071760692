import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { defaultShadow } from '../../../../styles/theme';

export const Container = styled(Box)((props) => ({
  display: 'flex',
  gap: '24px',
  justifyContent: 'space-between',
  width: '100%',

  '@media (max-width: 768px)': {
    flexDirection: 'column',
  },
}));

export const InfoContainer = styled(Box)((props) => ({
  padding: '16px 24px',
  borderRadius: '16px',
  userSelect: 'none',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  backgroundColor: props.theme.palette.white.main,
  boxShadow: defaultShadow,
}));

export const Title = styled(Typography)((props) => ({
  color: props.theme.palette.textColor,
  textAlign: 'center',
  fontSize: '12px',
  fontWeight: 500,
  letterSpacing: '0.5px',
}));

export const Content = styled(Typography)((props) => ({
  color: props.theme.palette.purple.main,
  textAlign: 'center',
  fontSize: '32px',
  fontWeight: 600,
}));
