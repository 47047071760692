import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

export const StyledSwitch = styled(Switch)((props) => ({
  width: 36,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 4,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: props.theme.palette.purple.main,
        opacity: 1,
        border: 0,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 12,
    height: 12,
  },
  '& .MuiSwitch-track': {
    borderRadius: 50 / 2,
    backgroundColor: props.theme.palette.gray.main,
    opacity: 1,
  },
}));
