import * as React from 'react';

import { StyledContainer, StyledLink, StyledSpan, UserName } from './styles';
import trophy from '../../../images/pageStructureImages/trophy.png';
import trophyWhite from '../../../images/pageStructureImages/trophy_white.png';

import { routes } from '../../../routes/routes';

interface IRankingIndicator {
  rankingData: {
    userName: string;
    position: string;
  };
}

const RankingIndicator: React.FC<IRankingIndicator> = ({ rankingData }) => {
  return (
    <StyledLink to={routes.ranking.path!}>
      <StyledContainer imagePath={trophy} imageHoverPath={trophyWhite}>
        <StyledSpan>Você no ranking</StyledSpan>
        <UserName>
          {rankingData.position}º {rankingData.userName}
        </UserName>
      </StyledContainer>
    </StyledLink>
  );
};

export default RankingIndicator;
