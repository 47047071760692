import { Alert, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled(Box)(() => ({
  display: 'flex',
  height: '100vh',
}));

export const LeftColumn = styled(Box)((props) => ({
  background: props.theme.palette.purple.main,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '@media (max-width: 768px)': {
    display: 'none',
  },
}));

export const RightColumn = styled(Box)((props) => ({
  background: props.theme.palette.backgroundColor,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflowY: 'scroll',

  '&::-webkit-scrollbar': {
    backgroundColor: 'transparent',
    width: '17px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
    margin: '10px',
  },
  '&::-webkit-scrollbar-thumb': {
    padding: ' 0 4px',
    borderRight: '4px solid transparent',
    borderLeft: '4px solid transparent',
    backgroundColor: props.theme.palette.purple.light,
    backgroundClip: 'padding-box',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: props.theme.palette.purple.main,
    },
  },
}));
