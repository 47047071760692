import { FormHelperText, OutlinedInput, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface IStyledTextInput {
  error: boolean;
}

export const StyledTextInput = styled(OutlinedInput)<IStyledTextInput>((props) => ({
  backgroundColor: props.theme.palette.white.main,
  height: !props.multiline ? '48px' : 'none',
  borderRadius: '10px',
  border: props.error ? `0px solid ${props.theme.palette.red.main}` : `1px solid ${props.theme.palette.gray.light}`,
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '14px',
  color: props.theme.palette.gray.main,
  '&.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      border: props.error
        ? `1px solid ${props.theme.palette.red.main}`
        : `1px solid ${props.theme.palette.purple.main}`,
    },
  },
  '&:hover': {
    '&& fieldset': {
      border: props.error ? `1px solid ${props.theme.palette.red.main}` : `1px solid ${props.theme.palette.gray.light}`,
    },
  },
  '& input::placeholder': {
    color: props.theme.palette.gray.main,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '14px',
    opacity: 1,
  },
}));

export const StyledHelperText = styled(FormHelperText)((props) => ({
  marginTop: '6px',
  marginLeft: '0px',
  fontWeight: 400,
  fontSize: '11px',
  lineHeight: '11px',
  color: props.theme.palette.gray.main,
}));

export const StyledErrorText = styled(FormHelperText)((props) => ({
  marginTop: '6px',
  marginLeft: '0px',
  fontWeight: 400,
  fontSize: '11px',
  lineHeight: '11px',
  color: props.theme.palette.red.main,
}));

export const StyledLabel = styled(Typography)((props) => ({
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '14px',
  color: props.theme.palette.textColor,
  marginBottom: '6px',
}));

export const StyledOption = styled(Typography)((props) => ({
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '14px',
  color: props.theme.palette.textColor,
}));
