import * as React from 'react';

import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';

import { StyledStep, StyledItemContent, StyledStepContainer, StyledLabel, StyledContent } from './styles';
import { defaultColors } from '../../../styles/theme';
import CheckIcon from '../../../images/icons/check';

interface IStep {
  label: string;
  content?: string;
}

interface ICustomStepperProps {
  activeStep: number;
  steps: IStep[];
  showLabel?: boolean;
  showContent?: boolean;
}

const CustomCardStepper: React.FC<ICustomStepperProps> = ({ activeStep, steps, showLabel, showContent }) => {
  const stepsLabelArray: string[] = steps.map((step) => step.label);
  const isActive = (param: string) => {
    return stepsLabelArray.findIndex((stepsLabelArray) => stepsLabelArray === param) === activeStep;
  };
  const isCompleted = (param: string) => {
    return stepsLabelArray.findIndex((stepsLabelArray) => stepsLabelArray === param) < activeStep;
  };
  const getIndex = (param: string) => {
    return stepsLabelArray.findIndex((stepsLabelArray) => stepsLabelArray === param);
  };

  return (
    <Stack>
      <Stepper activeStep={activeStep}>
        {stepsLabelArray.map((step) => (
          <StyledStepContainer
            first={getIndex(step) === 0}
            last={getIndex(step) === stepsLabelArray.length - 1}
            key={step}
            active={isActive(step)}
          >
            <StyledStep active={isActive(step)} completed={isCompleted(step)}>
              {isActive(step) ? (
                <StyledItemContent active={isActive(step)}>{getIndex(step) + 1}</StyledItemContent>
              ) : isCompleted(step) ? (
                <CheckIcon fill={defaultColors.white.main} />
              ) : (
                <StyledItemContent active={isActive(step)}>{getIndex(step) + 1}</StyledItemContent>
              )}
            </StyledStep>
            <Box display={'flex'} flexDirection={'column'}>
              {showLabel && (
                <StyledLabel active={isActive(step)} completed={isCompleted(step)}>
                  {step}
                </StyledLabel>
              )}
              {showContent && <StyledContent>{steps.find((s) => s.label === step)?.content}</StyledContent>}
            </Box>
          </StyledStepContainer>
        ))}
      </Stepper>
    </Stack>
  );
};

export default CustomCardStepper;
