import { Box, Typography, FormControlLabel, Radio } from '@mui/material';
import { styled } from '@mui/material/styles';

interface IAnswer {
  isCorrect?: boolean;
  answeredWrong?: boolean;
  fontSizeRate?: number;
}

export const StyledContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '28px',
  height: '100%',
}));

export const StyledLabel = styled(Typography)<IAnswer>((props) => ({
  fontWeight: 400,
  fontSize: `calc(16px * ${props.fontSizeRate})`,

  '@media (max-width: 768px)': {
    fontSize: `calc(12px * ${props.fontSizeRate})`,
  },
}));

export const AnswersContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
}));

export const AnswerLabel = styled(Typography)<IAnswer>((props) => ({
  fontSize: `calc(16px * ${props.fontSizeRate})`,
  fontWeight: 400,
  color: props.isCorrect
    ? props.theme.palette.green.dark
    : props.answeredWrong
    ? props.theme.palette.red.main
    : props.theme.palette.textColor,

  '@media (max-width: 768px)': {
    fontSize: `calc(12px * ${props.fontSizeRate})`,
  },
}));

export const StyledRadioButton = styled(Radio)((props) => ({
  color: props.theme.palette.gray.main,
  '&.Mui-checked': {
    color: props.theme.palette.purple.main,
  },
}));

export const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  alignItems: 'center',
  display: 'flex',
  '& + &': {
    marginTop: '12px',
  },
}));

export const StyledRadioIcon = styled(Box)<IAnswer>((props) => ({
  width: '30px',
  height: '30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: props.isCorrect
    ? props.theme.palette.green.dark
    : props.answeredWrong
    ? props.theme.palette.red.main
    : props.theme.palette.textColor,
  backgroundColor: props.isCorrect
    ? props.theme.palette.green.light
    : props.answeredWrong
    ? props.theme.palette.red.light
    : props.theme.palette.gray.light,
  borderRadius: '100%',

  '@media (max-width: 768px)': {
    width: '22px',
    height: '22px',
  },
}));

export const StyledRadioIconChecked = styled(Box)<IAnswer>((props) => ({
  width: '30px',
  height: '30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: props.isCorrect
    ? props.theme.palette.green.dark
    : props.answeredWrong
    ? props.theme.palette.red.main
    : props.theme.palette.purple.main,
  backgroundColor: props.isCorrect
    ? props.theme.palette.green.light
    : props.answeredWrong
    ? props.theme.palette.red.light
    : props.theme.palette.purple.light,
  borderRadius: '100%',

  '@media (max-width: 768px)': {
    width: '22px',
    height: '22px',
  },
}));
