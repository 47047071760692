import { PaginationItem } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledPaginationItem = styled(PaginationItem)((props) => ({
  background: props.theme.palette.white.main,
  borderRadius: '0px',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '14px',
  paddingTop: '17px',
  paddingBottom: '17px',
  height: '48px',
  width: '48px',
  color: props.theme.palette.textColor,
  borderTop: `1px solid ${props.theme.palette.gray.light}`,
  borderBottom: `1px solid ${props.theme.palette.gray.light}`,
  borderLeft: `0.5px solid ${props.theme.palette.gray.light}`,
  borderRight: `0.5px solid ${props.theme.palette.gray.light}`,
  margin: '0px',
  '&.Mui-selected': {
    border: `1px solid ${props.theme.palette.purple.main}`,
    fontWeight: 700,
    color: props.theme.palette.purple.main,
  },
  '&.MuiPaginationItem-previousNext': {
    borderRadius: props.type === 'previous' ? '8px 0px 0px 8px' : '0px 8px 8px 0px',
    borderLeft: props.type === 'previous' && `1px solid ${props.theme.palette.gray.light}`,
    borderRight: props.type === 'next' && `1px solid ${props.theme.palette.gray.light}`,
  },
}));
