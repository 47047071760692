import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { defaultShadow } from '../../../styles/theme';

interface IShowRankingLabel {
  selected: boolean;
}

export const Container = styled(Box)((props) => ({
  display: 'flex',
  gap: '40px',
  height: '100%',

  '@media (max-width: 768px)': {
    flexDirection: 'column',
    gap: '0px',
  },
}));

export const UserDataContainer = styled(Box)((props) => ({
  paddingTop: '32px',
  paddingBottom: '32px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '20%',

  '@media (max-width: 768px)': {
    width: '100%',
    height: 'auto',
    paddingBottom: '0px',
    marginBottom: '-10px',
  },
}));

export const PageStrutctureContainer = styled(Box)((props) => ({
  width: '100%',

  '@media (max-width: 768px)': {
    width: '100%',
  },
}));

export const ScoreCard = styled(Box)((props) => ({
  padding: '12px',
  display: 'flex',
  flexDirection: 'column',
  marginTop: '16px',
  alignItems: 'center',
  borderRadius: '16px',
  background: props.theme.palette.white.main,
  boxShadow: defaultShadow,
  width: '100%',

  '@media (max-width: 768px)': {
    width: '100%',
    marginTop: '12px',
  },
}));

export const ScoreLabel = styled(Typography)((props) => ({
  color: props.theme.palette.textColor,
  textAlign: 'center',
  fontSize: '14px',
  letterSpacing: '0.5px',
}));

export const ScoreValue = styled(Typography)((props) => ({
  color: props.theme.palette.purple.main,
  fontWeight: 700,
  textAlign: 'center',
  fontSize: '36px',
  letterSpacing: '0.8px',
}));

export const ScoreFooter = styled(Typography)((props) => ({
  color: props.theme.palette.textColor,
  textAlign: 'center',
  fontSize: '10px',
  letterSpacing: '0.5px',
}));

export const InviteFriend = styled(Typography)((props) => ({
  marginTop: '24px',
  cursor: 'pointer',
  color: props.theme.palette.purple.dark,
  textAlign: 'center',
  fontSize: '16px',
  letterSpacing: '0.5px',
  padding: '8px',
  width: '100%',
  border: `1px solid ${props.theme.palette.purple.dark}`,
  borderRadius: '24px',
  transitionDuration: '100ms',
  userSelect: 'none',

  '&:hover': {
    color: props.theme.palette.white.main,
    border: `1px solid ${props.theme.palette.white.main}`,
    background: props.theme.palette.purple.main,
  },

  '&:active': {
    background: props.theme.palette.purple.dark,
  },

  '@media (max-width: 768px)': {
    fontSize: '14px',
  },
}));

export const UserName = styled(Typography)((props) => ({
  color: props.theme.palette.textColor,
  fontSize: '16px',
  fontWeight: 700,
  display: 'block',
  textAlign: 'center',
}));

export const ShowRankingLabel = styled(Typography)<IShowRankingLabel>((props) => ({
  color: props.selected ? props.theme.palette.purple.main : props.theme.palette.gray.main,
  fontSize: '16px',
  fontWeight: 500,

  '@media (max-width: 768px)': {
    display: 'block',
    fontSize: '14px',
  },
}));

export const ToggleContainer = styled(Box)((props) => ({
  cursor: 'pointer',
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  mt: '8px',
}));
