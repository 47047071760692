import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

interface IContainer {
  expandAsideMenu: boolean;
}

interface IButtonSubItem {
  selected?: boolean;
}

export const Container = styled(Box)<IContainer>((props) => ({
  gridArea: 'AS',
  backgroundColor: props.theme.palette.purple.main,
  paddingTop: '10vh',
  display: props.expandAsideMenu ? 'block' : 'none',

  '@media (max-width: 768px)': {
    paddingTop: '1vh',
  },
}));

export const MenuItemLink = styled(Link)(() => ({
  cursor: 'pointer',
  textDecoration: 'none',
  width: '100%',
  height: '100%',
}));

export const ButtonSubItem = styled(Box)<IButtonSubItem>((props) => ({
  backgroundColor: props.selected ? '#ffffff1a' : 'transparent',
  width: '100%',
  height: '100%',
  paddingTop: '16px',
  paddingBottom: '16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  color: 'white',

  '@media (max-width: 768px)': {
    flexDirection: 'row',
    justifyContent: 'start',
    paddingLeft: '30px',
  },
}));

export const LabelItem = styled(Typography)(() => ({
  display: 'flex',
  fontSize: '9px',
  fontWeight: 'bold',
  justifyContent: 'center',
  marginTop: '6px',

  '@media (max-width: 768px)': {
    fontSize: '18px',
    fontWeight: '400',
    marginLeft: '33px',
  },
}));
