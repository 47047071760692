import React, { useContext, useEffect, useState } from 'react';

import {
  Container,
  MissionContainer,
  MissionStatusLabel,
  MissionContentNew,
  MissionExpirationDate,
  Tag,
  StatusContainer,
  NotFoundLabel,
} from './styles';
import { Box, Skeleton } from '@mui/material';
import { defaultColors, defaultShadow } from '../../../../styles/theme';
import { getUserMissionsService } from '../../../../services/mission';
import CustomSnackBar from '../../../../shared/components/toast';
import CustomAlert from '../../../../shared/components/alert';
import { formatStringDate, isExpiredDate } from '../../../../shared/util/functions';
import NotFoundImg from '../../../../images/illustrations/notFound';
import { Link } from 'react-router-dom';
import CustomButton from '../../../../shared/components/button';
import { routes } from '../../../../routes/routes';
import { MissionsContext } from '../../../../contexts/missions';

export enum MissionStatus {
  acomplished = 1,
  pending = 2,
  expired = 3,
}

const Missions: React.FC = () => {
  const [userMissionsList, setUserMissionsList] = useState<any[]>([]);
  const [snackOpen, setSnackOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { missionsData, setMissionsData } = useContext(MissionsContext);

  const getStatusLabel = (status: MissionStatus) => {
    switch (status) {
      case MissionStatus.acomplished:
        return 'Concluída';
      case MissionStatus.pending:
        return 'Iniciada';
      default:
        return 'Expirada';
    }
  };

  const getUserMissions = async () => {
    const response = await getUserMissionsService();

    if (response.status === 200) {
      const missionsResult = response.data.result;

      setMissionsData(missionsResult);
    } else {
      setSnackOpen(true);
    }
    setIsLoading(false);
  };

  const getMissionStatus = (mission: any) => {
    if (mission.isAccomplished) {
      return MissionStatus.acomplished;
    } else if (isExpiredDate(mission.expirationDate)) {
      return MissionStatus.expired;
    } else {
      return MissionStatus.pending;
    }
  };

  useEffect(() => {
    getUserMissions();
  }, []);

  useEffect(() => {
    if (missionsData) {
      const newUserMissions = missionsData.sort((a: any, b: any) => {
        return getMissionStatus(a) - getMissionStatus(b);
      });
      setUserMissionsList(newUserMissions);
    }
  }, [missionsData]);

  return (
    <Container>
      <Box display={'flex'} flexDirection={'column'} gap={'20px'} mt={'12px'}>
        {!isLoading && userMissionsList.length > 0 ? (
          userMissionsList.map((item) => (
            <Box
              key={userMissionsList.indexOf(item)}
              display={'flex'}
              width={'100%'}
              sx={{ boxShadow: defaultShadow, borderRadius: '16px' }}
            >
              <MissionContainer>
                <Tag status={getMissionStatus(item)} />
                <Box display={'flex'} flexDirection={'column'} width={'100%'} gap={'6px'}>
                  <MissionContentNew>{item.missionDescription}</MissionContentNew>
                  <MissionExpirationDate>Data limite: {formatStringDate(item.expirationDate)}</MissionExpirationDate>
                </Box>
              </MissionContainer>
              <StatusContainer>
                <MissionStatusLabel status={getMissionStatus(item)}>
                  {getStatusLabel(getMissionStatus(item))}
                </MissionStatusLabel>
              </StatusContainer>
            </Box>
          ))
        ) : (isLoading || snackOpen) && userMissionsList.length === 0 ? (
          <Box display={'flex'} flexDirection={'column'} gap={'16px'}>
            <Skeleton
              sx={{ bgcolor: defaultColors.purple.light, borderRadius: '12px' }}
              variant="rectangular"
              animation="wave"
              width={'100%'}
              height={'88px'}
            />
            <Skeleton
              sx={{ bgcolor: defaultColors.purple.light, borderRadius: '12px' }}
              variant="rectangular"
              animation="wave"
              width={'100%'}
              height={'88px'}
            />
            <Skeleton
              sx={{ bgcolor: defaultColors.purple.light, borderRadius: '12px' }}
              variant="rectangular"
              animation="wave"
              width={'100%'}
              height={'88px'}
            />
          </Box>
        ) : !snackOpen && !isLoading ? (
          <Box
            display={'flex'}
            flexDirection={'column'}
            width={'100%'}
            textAlign={'center'}
            justifyContent={'center'}
            gap={'32px'}
            alignItems={'center'}
            mt={'16px'}
          >
            <NotFoundImg />
            <NotFoundLabel>
              É, parece que não encontramos nenhuma missão para você. Que tal fazer o teste de perfil para que possamos
              gerar algumas missões conforme o resultado?
            </NotFoundLabel>
            <Link to={routes.suitabilityQuiz.path!}>
              <CustomButton classification="secondary" uppercase={false} title="Fazer teste de perfil" />
            </Link>
          </Box>
        ) : (
          <></>
        )}
      </Box>
      <CustomSnackBar isOpen={snackOpen}>
        <CustomAlert
          showLeftIcon={true}
          showCloseIcon={true}
          severity={'error'}
          title={'Ops! Não conseguimos carregar suas missões no momento.'}
          onClose={() => setSnackOpen(false)}
        />
      </CustomSnackBar>
    </Container>
  );
};

export default Missions;
