import React from 'react';

import { Container, Explanation } from './styles';
import ErrorImg from '../../../images/illustrations/error';

interface IError {
  message: string;
  actionLink?: string;
}

const Error: React.FC<IError> = ({ message, actionLink }) => {
  return (
    <Container>
      <ErrorImg />
      <Explanation>{message}</Explanation>
    </Container>
  );
};

export default Error;
