import * as React from 'react';
import { SVGProps } from 'react';

const ErrorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={30}
    height={30}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15 16.537c.325 0 .607-.119.844-.356.238-.238.357-.519.357-.843V8.812c0-.3-.12-.569-.357-.805A1.154 1.154 0 0 0 15 7.651c-.325 0-.607.118-.844.356a1.148 1.148 0 0 0-.357.842v6.525c0 .3.12.57.357.807.237.237.519.356.844.356Zm0 5.587c.35 0 .65-.112.9-.336.25-.226.374-.538.374-.938 0-.35-.124-.65-.374-.9s-.55-.374-.9-.374-.65.124-.9.374-.374.55-.374.9c0 .4.124.712.374.938.25.224.55.336.9.336Zm0 7.127c-1.975 0-3.832-.375-5.57-1.125-1.737-.75-3.243-1.763-4.517-3.039-1.276-1.274-2.288-2.78-3.038-4.518C1.125 18.832.75 16.975.75 15s.375-3.832 1.125-5.57c.75-1.737 1.762-3.243 3.038-4.517 1.274-1.276 2.78-2.289 4.518-3.039S13.025.75 15 .75c1.974 0 3.83.375 5.568 1.125 1.738.75 3.244 1.763 4.518 3.039 1.276 1.274 2.289 2.78 3.039 4.518.75 1.737 1.125 3.594 1.125 5.569s-.375 3.832-1.125 5.57c-.75 1.737-1.763 3.243-3.039 4.517-1.274 1.276-2.78 2.289-4.518 3.039-1.737.75-3.594 1.125-5.569 1.125Zm0-2.402c3.275 0 6.069-1.156 8.38-3.468 2.313-2.313 3.47-5.106 3.47-8.381 0-3.275-1.157-6.068-3.47-8.38C21.07 4.306 18.276 3.15 15 3.15c-3.275 0-6.068 1.157-8.38 3.47C4.306 8.931 3.15 11.724 3.15 15c0 3.275 1.157 6.068 3.47 8.38 2.312 2.313 5.105 3.47 8.38 3.47Z"
      fill={props.fill}
    />
  </svg>
);

export default ErrorIcon;
