import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { defaultShadow } from '../../../styles/theme';
import { Link } from 'react-router-dom';

interface IStyledContainer {
  imagePath: string;
  imageHoverPath: string;
}

export const StyledContainer = styled(Box)<IStyledContainer>((props) => ({
  backgroundColor: props.theme.palette.white.main,
  boxShadow: defaultShadow,
  display: 'flex',
  flexDirection: 'column',
  padding: '16px 24px',
  borderRadius: '16px',
  backgroundImage: `url(${props.imagePath})`,
  backgroundRepeat: 'no-repeat',
  backgroundPositionX: 'right',
  backgroundPositionY: '-10px',
  backgroundSize: '40%',
  cursor: 'pointer',
  transition: 'all 0.3s',

  '&:nth-of-type(n)': {
    '&:hover': {
      backgroundColor: props.theme.palette.purple.main,
      backgroundImage: `url(${props.imageHoverPath})`,

      '& > *': {
        color: props.theme.palette.white.main,
      },
    },
  },
}));

export const StyledSpan = styled(Typography)((props) => ({
  color: props.theme.palette.textColor,
  fontSize: '12px',
  fontWeight: 500,
  transition: 'all 0.3s',
}));

export const UserName = styled(Typography)((props) => ({
  color: props.theme.palette.purple.main,
  fontSize: '16px',
  fontWeight: 600,
  marginTop: '4px',
  transition: 'all 0.3s',
}));

export const StyledLink = styled(Link)((props) => ({
  textDecoration: 'none',
}));
