import * as React from 'react';

import {
  AnswersContainer,
  StyledContainer,
  StyledLabel,
  AnswerLabel,
  StyledFormControlLabel,
  StyledRadioButton,
  StyledRadioIcon,
  StyledRadioIconChecked,
} from './styles';
import { Box, RadioGroup } from '@mui/material';
import { useState, useEffect } from 'react';
import CustomButton from '../button';
import PlusIcon from '../../../images/icons/plus';
import MinusIcon from '../../../images/icons/minus';

interface IQuestion {
  question: string;
  answers: { description: string }[];
  onChange: (event: any) => void;
  selectedValue: string | undefined | null;
  answered: boolean;
  correctAnswer: string;
}

const maximumFontSizeRate = 1.4;
const minimumFontSizeRate = 0.7;
const fontSizeRateKey = 'cfcLabFontSizeRateQuestion';

const Question: React.FC<IQuestion> = ({ question, answers, onChange, selectedValue, answered, correctAnswer }) => {
  const storagedFontSizeRateKey = Number(localStorage.getItem(fontSizeRateKey) || '1');
  const [fontSizeRate, setFontSizeRate] = useState(storagedFontSizeRateKey);

  const increaseFontSize = () => {
    if (fontSizeRate < maximumFontSizeRate) {
      setFontSizeRate(fontSizeRate + fontSizeRate * 0.1);
    }
  };

  const decreaseFontSize = () => {
    if (fontSizeRate > minimumFontSizeRate) {
      setFontSizeRate(fontSizeRate - fontSizeRate * 0.1);
    }
  };

  useEffect(() => {
    localStorage.setItem(fontSizeRateKey, fontSizeRate.toString());
  }, [fontSizeRate]);

  return (
    <Box mt={'-20px'}>
      {answers && (
        <Box display={'flex'} flexDirection={'column'} gap={'16px'}>
          <Box display={'flex'} gap={'20px'} width={'100%'}>
            <CustomButton
              classification="primary"
              uppercase={false}
              onClick={() => decreaseFontSize()}
              icon={<MinusIcon />}
            />
            <CustomButton
              classification="primary"
              uppercase={false}
              onClick={() => increaseFontSize()}
              icon={<PlusIcon />}
            />
          </Box>
          <StyledContainer>
            <StyledLabel fontSizeRate={fontSizeRate} dangerouslySetInnerHTML={{ __html: question }}></StyledLabel>
            <AnswersContainer>
              <RadioGroup>
                <StyledFormControlLabel
                  onClick={!answered ? onChange : () => {}}
                  value={answers && answers[0]}
                  control={
                    <StyledRadioButton
                      icon={
                        <StyledRadioIcon
                          isCorrect={answers[0]?.description === correctAnswer}
                          answeredWrong={
                            selectedValue === answers[0]?.description &&
                            answers[0]?.description !== correctAnswer &&
                            answered
                          }
                        >
                          A
                        </StyledRadioIcon>
                      }
                      checkedIcon={
                        <StyledRadioIconChecked
                          isCorrect={answers[0]?.description === correctAnswer}
                          answeredWrong={
                            selectedValue === answers[0]?.description &&
                            answers[0]?.description !== correctAnswer &&
                            answered
                          }
                        >
                          <b>A</b>
                        </StyledRadioIconChecked>
                      }
                      size={'small'}
                      value={answers[0]?.description}
                      checked={selectedValue ? selectedValue === answers[0]?.description : false}
                    />
                  }
                  label={
                    <AnswerLabel
                      dangerouslySetInnerHTML={{ __html: answers[0]?.description }}
                      fontSizeRate={fontSizeRate}
                      isCorrect={answers[0]?.description === correctAnswer}
                      answeredWrong={
                        selectedValue === answers[0]?.description &&
                        answers[0]?.description !== correctAnswer &&
                        answered
                      }
                    ></AnswerLabel>
                  }
                />
                <StyledFormControlLabel
                  onClick={!answered ? onChange : () => {}}
                  value={answers && answers[1]}
                  control={
                    <StyledRadioButton
                      icon={
                        <StyledRadioIcon
                          isCorrect={answers[1]?.description === correctAnswer}
                          answeredWrong={
                            selectedValue === answers[1]?.description &&
                            answers[1]?.description !== correctAnswer &&
                            answered
                          }
                        >
                          B
                        </StyledRadioIcon>
                      }
                      checkedIcon={
                        <StyledRadioIconChecked
                          isCorrect={answers[1]?.description === correctAnswer}
                          answeredWrong={
                            selectedValue === answers[1]?.description &&
                            answers[1]?.description !== correctAnswer &&
                            answered
                          }
                        >
                          <b>B</b>
                        </StyledRadioIconChecked>
                      }
                      size={'small'}
                      value={answers[1]?.description}
                      checked={selectedValue ? selectedValue === answers[1]?.description : false}
                    />
                  }
                  label={
                    <AnswerLabel
                      fontSizeRate={fontSizeRate}
                      dangerouslySetInnerHTML={{ __html: answers[1]?.description }}
                      isCorrect={answers[1]?.description === correctAnswer}
                      answeredWrong={
                        selectedValue === answers[1]?.description &&
                        answers[1]?.description !== correctAnswer &&
                        answered
                      }
                    ></AnswerLabel>
                  }
                />
                <StyledFormControlLabel
                  onClick={!answered ? onChange : () => {}}
                  value={answers && answers[2]}
                  control={
                    <StyledRadioButton
                      icon={
                        <StyledRadioIcon
                          isCorrect={answers[2]?.description === correctAnswer}
                          answeredWrong={
                            selectedValue === answers[2]?.description &&
                            answers[2]?.description !== correctAnswer &&
                            answered
                          }
                        >
                          C
                        </StyledRadioIcon>
                      }
                      checkedIcon={
                        <StyledRadioIconChecked
                          isCorrect={answers[2]?.description === correctAnswer}
                          answeredWrong={
                            selectedValue === answers[2]?.description &&
                            answers[2]?.description !== correctAnswer &&
                            answered
                          }
                        >
                          <b>C</b>
                        </StyledRadioIconChecked>
                      }
                      size={'small'}
                      value={answers[2]?.description}
                      checked={selectedValue ? selectedValue === answers[2]?.description : false}
                    />
                  }
                  label={
                    <AnswerLabel
                      fontSizeRate={fontSizeRate}
                      isCorrect={answers[2]?.description === correctAnswer}
                      dangerouslySetInnerHTML={{ __html: answers[2]?.description }}
                      answeredWrong={
                        selectedValue === answers[2]?.description &&
                        answers[2]?.description !== correctAnswer &&
                        answered
                      }
                    ></AnswerLabel>
                  }
                />
                <StyledFormControlLabel
                  onClick={!answered ? onChange : () => {}}
                  value={answers && answers[3]}
                  control={
                    <StyledRadioButton
                      icon={
                        <StyledRadioIcon
                          isCorrect={answers[3]?.description === correctAnswer}
                          answeredWrong={
                            selectedValue === answers[3]?.description &&
                            answers[3]?.description !== correctAnswer &&
                            answered
                          }
                        >
                          D
                        </StyledRadioIcon>
                      }
                      checkedIcon={
                        <StyledRadioIconChecked
                          isCorrect={answers[3]?.description === correctAnswer}
                          answeredWrong={
                            selectedValue === answers[3]?.description &&
                            answers[3]?.description !== correctAnswer &&
                            answered
                          }
                        >
                          <b>D</b>
                        </StyledRadioIconChecked>
                      }
                      size={'small'}
                      value={answers[3]?.description}
                      checked={selectedValue ? selectedValue === answers[3]?.description : false}
                    />
                  }
                  label={
                    <AnswerLabel
                      fontSizeRate={fontSizeRate}
                      isCorrect={answers[3]?.description === correctAnswer}
                      dangerouslySetInnerHTML={{ __html: answers[3]?.description }}
                      answeredWrong={
                        selectedValue === answers[3]?.description &&
                        answers[3]?.description !== correctAnswer &&
                        answered
                      }
                    ></AnswerLabel>
                  }
                />
              </RadioGroup>
            </AnswersContainer>
          </StyledContainer>
        </Box>
      )}
    </Box>
  );
};

export default Question;
