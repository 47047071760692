import axiosInstance from '../shared/util/axiosInstance';

export const getSuitabilityQuizRepository = async () => {
  const result: any = await axiosInstance
    .get(`/getSuitabilityQuiz`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return result;
};

export const updateSuitabilityQuizRepository = async (newSuitability: string) => {
  const result: any = await axiosInstance
    .patch(`/updateUserSuitability`, { suitability: newSuitability })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
  return result;
};
