export const tokenKey = 'cfcLab-token';
export const userNameStorage = 'cfcLab-userName';
export const userFirstAccessKey = 'cfcLab-userFirstAccess';
export const userIdKey = 'cfcLab-userId';
export const userSuitabilityKey = 'cfcLab-userSuitability';
export const scoreToBeBold = 7;
export const correctAnswerKey = 'cfcLabCorrectAnswerKeyTrue';

export const SuitabilityEnum = {
  conservative: {
    label: 'Conservador',
    value: 'conservative',
    description:
      'Isso significa que sua rotina de estudos é um pouco mais leve, e com isso suas missões são mais fáceis de se atingir, mas lembre-se, você pode refazer o teste de perfil a qualquer momento acessando Perfil -> Teste de perfil. Agora o que acha de vermos suas missões?',
  },
  bold: {
    label: 'Arrojado',
    value: 'bold',
    description:
      'Isso significa que sua rotina de estudos demanda um pouco mais de dedicação, e com isso suas missões exigem um pouco mais de dedicação para serem cumpridas, mas lembre-se, você pode refazer ot este de perfil a qualquer momento acessando Perfil -> Teste de perfil. Agora o que acha de vermos suas missões?',
  },
};

export const educationOptions = [
  { value: 'Escolaridade*', label: 'Escolaridade*' },
  { value: 'graduated', label: 'Superior completo' },
  { value: 'nonGraduated', label: 'Superior incompleto' },
];

export const ufs = [
  { value: 'Estado*', label: 'Estado*' },
  { value: 'AC', label: 'AC' },
  { value: 'AL', label: 'AL' },
  { value: 'AP', label: 'AP' },
  { value: 'AM', label: 'AM' },
  { value: 'BA', label: 'BA' },
  { value: 'CE', label: 'CE' },
  { value: 'DF', label: 'DF' },
  { value: 'ES', label: 'ES' },
  { value: 'GO', label: 'GO' },
  { value: 'MA', label: 'MA' },
  { value: 'MT', label: 'MT' },
  { value: 'MS', label: 'MS' },
  { value: 'MG', label: 'MG' },
  { value: 'PA', label: 'PA' },
  { value: 'PB', label: 'PB' },
  { value: 'PR', label: 'PR' },
  { value: 'PE', label: 'PE' },
  { value: 'PI', label: 'PI' },
  { value: 'RJ', label: 'RJ' },
  { value: 'RN', label: 'RN' },
  { value: 'RS', label: 'RS' },
  { value: 'RO', label: 'RO' },
  { value: 'RR', label: 'RR' },
  { value: 'SC', label: 'SC' },
  { value: 'SP', label: 'SP' },
  { value: 'SE', label: 'SE' },
  { value: 'TO', label: 'TO' },
];

export const localStorageParameters = {
  userName: 'userName',
  company: 'company',
  optionSelected: 'optionSelected',
  containerSelected: 'containerSelected',
  securityToken: 'securityToken',
};
