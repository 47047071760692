import React, { useContext, useEffect, useState } from 'react';

import {
  Column,
  Container,
  Content,
  MenuItemLink,
  StyledTooltip,
  SystemName,
  TooltipOption,
  TooltipOptionLabel,
  UserDataContainer,
  UserName,
} from './styles';

import { userNameStorage } from '../../util/constants';
import NotificationsIcon from '../../../images/icons/notifications';
import MenuIcon from '../../../images/icons/menu';
import CloseMenuIcon from '../../../images/icons/closeMenu';
import { defaultColors, mobileBreakPoint } from '../../../styles/theme';
import { Box, ClickAwayListener } from '@mui/material';
import ArrowDownIcon from '../../../images/icons/arrow_down';
import { profileTabOptions } from '../../../routes/routes';
import { AuthenticationContext } from '../../../contexts/authentication';
import UnreadNotificationsIcon from '../../../images/icons/unread_notifications';
import { updateUserNotificationsService } from '../../../services/communication';
import { NotificationContext } from '../../../contexts/notification';

interface IHeaderProps {
  setExpandAsideMenu: () => void;
  expandAsideMenu: boolean;
}

const tooltipOptions = {
  edit: 'edit',
  delete: 'delete',
  visibility: 'visibility',
};

const Header: React.FC<IHeaderProps> = ({ setExpandAsideMenu, expandAsideMenu }) => {
  const [optionHovered, setOptionHovered] = useState<string | undefined>('');
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isNotificationsTooltipOpen, setIsNotificationsTooltipOpen] = useState(false);
  const { user } = useContext(AuthenticationContext);
  const { setUserNotifications, userNotifications } = useContext(NotificationContext);

  const isMobile = window.screen.width <= mobileBreakPoint;

  const userName = (user?.name || localStorage.getItem(userNameStorage)).split(' ')[0];
  const userNameFormated = userName[0].toUpperCase() + userName.substr(1);

  const handleNotificationsOpen = async () => {
    setIsNotificationsTooltipOpen(!isNotificationsTooltipOpen);
    if (isNotificationsTooltipOpen) {
      setUserNotifications([]);
    }

    const idsToUpdate = userNotifications.map((item: any) => item.id);

    await updateUserNotificationsService(idsToUpdate);
  };

  return (
    <Container>
      <Content>
        <Column>
          <Box display={'flex'} gap="8px" alignItems={'center'} justifyContent={'center'}>
            {/* <Box sx={{ cursor: 'pointer' }} mt="4px">
              {isMobile ? <HeaderLogoInverted /> : <HeaderLogo />}
            </Box>
            <SystemName>CFC Lab</SystemName> */}
          </Box>
        </Column>
        {isMobile ? (
          <Column>
            <Box display={'flex'} gap={'32px'} alignItems={'center'}>
              {!expandAsideMenu ? (
                <MenuIcon fill="white" onClick={setExpandAsideMenu} />
              ) : (
                <CloseMenuIcon fill="white" onClick={setExpandAsideMenu} />
              )}
            </Box>
          </Column>
        ) : (
          <Column>
            <StyledTooltip
              open={isNotificationsTooltipOpen}
              disableHoverListener
              placement="bottom-start"
              title={userNotifications.map((item: any) => {
                return (
                  <ClickAwayListener key={item.id} onClickAway={() => handleNotificationsOpen()}>
                    <TooltipOption
                      notification={true}
                      first={userNotifications.indexOf(item) === 0 && userNotifications.length !== 1}
                      last={
                        userNotifications.indexOf(item) === userNotifications.length - 1 &&
                        userNotifications.length !== 1
                      }
                      single={userNotifications.length === 1}
                    >
                      <TooltipOptionLabel>{item.description}</TooltipOptionLabel>
                    </TooltipOption>
                  </ClickAwayListener>
                );
              })}
            >
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                sx={{ cursor: 'pointer', marginRight: '14px' }}
                onClick={() => handleNotificationsOpen()}
              >
                {userNotifications.length > 0 ? (
                  <UnreadNotificationsIcon />
                ) : (
                  <NotificationsIcon fill={defaultColors.purple.main} />
                )}
              </Box>
            </StyledTooltip>
            <StyledTooltip
              open={isTooltipOpen}
              onClose={() => setIsTooltipOpen(false)}
              onOpen={() => setIsTooltipOpen(true)}
              placement="bottom-end"
              title={
                <Box onClick={() => setIsTooltipOpen(false)}>
                  {/* <MenuItemLink to={`/perfil?tab=${profileTabOptions.profileTest.param}`}>
                    <TooltipOption
                      onMouseEnter={() => setOptionHovered(tooltipOptions.edit)}
                      onMouseLeave={() => setOptionHovered(undefined)}
                      first={true}
                    >
                      <TooltipOptionLabel hovered={optionHovered === tooltipOptions.edit}>
                        Teste de perfil
                      </TooltipOptionLabel>
                    </TooltipOption>
                  </MenuItemLink>
                  <MenuItemLink to={`/perfil?tab=${profileTabOptions.signature.param}`}>
                    <TooltipOption
                      onMouseEnter={() => setOptionHovered(tooltipOptions.delete)}
                      onMouseLeave={() => setOptionHovered(undefined)}
                    >
                      <TooltipOptionLabel hovered={optionHovered === tooltipOptions.delete}>
                        Gerenciar assinatura
                      </TooltipOptionLabel>
                    </TooltipOption>
                  </MenuItemLink> */}
                  <MenuItemLink to={`/perfil?tab=${profileTabOptions.general.param}`}>
                    <TooltipOption
                      onMouseEnter={() => setOptionHovered(tooltipOptions.visibility)}
                      onMouseLeave={() => setOptionHovered(undefined)}
                      // last={true}
                    >
                      <TooltipOptionLabel hovered={optionHovered === tooltipOptions.visibility}>
                        Alterar dados cadastrais
                      </TooltipOptionLabel>
                    </TooltipOption>
                  </MenuItemLink>
                </Box>
              }
            >
              <UserDataContainer>
                <UserName>{userNameFormated}</UserName>
                <ArrowDownIcon style={{ marginLeft: '6px' }} fill={defaultColors.purple.main} />
              </UserDataContainer>
            </StyledTooltip>
          </Column>
        )}
      </Content>
    </Container>
  );
};

export default Header;
