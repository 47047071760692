import React, { useContext, useEffect, useState } from 'react';

import PageStructure from '../../../shared/components/pageStructure';
import { Box } from '@mui/material';
import {
  ButtonsContainer,
  Explanation,
  OptionsContainer,
  TestOptionContainer,
  TestOptionContent,
  TestOptionTitle,
} from './styles';
import CustomButton from '../../../shared/components/button';
import CustomizeTest from '../components/customizeTest';
import DefaultTestImg from '../../../images/illustrations/defaultTest';
import CustomizeTestImg from '../../../images/illustrations/customizeTest';
import { getQuestionsService } from '../../../services/questions';
import QuestionSkeleton from '../../../shared/components/questionSkeleton';
import Question from '../../../shared/components/question';
import Error from '../../../shared/components/error';
import { IAnsweredQuestionInput } from '../../../interfaces/input/answeredQuestion';
import { postAnsweredQuestionService } from '../../../services/answeredQuestions';
import { SuitabilityEnum, correctAnswerKey } from '../../../shared/util/constants';
import * as bcrypt from 'bcryptjs';
import CustomAlert from '../../../shared/components/alert';
import CustomSnackBar from '../../../shared/components/toast';
import ProgressBar from '../../../shared/components/progressBar';
import TestResults from '../components/testResults';
import { mobileBreakPoint } from '../../../styles/theme';
import { UserContext } from '../../../contexts/user';
import { MissionStatus } from '../../dashboard/components/missions';
import { isExpiredDate } from '../../../shared/util/functions';
import { updateUserMissionsService } from '../../../services/mission';
import { acquireAchievementToUserService } from '../../../services/achievements';
import { updateUserAnsweredCustomTestsService, updateUserAnsweredDefaultTestsService } from '../../../services/user';

const Tests = () => {
  const isMobile = window.screen.width <= mobileBreakPoint;
  const [customizeOpen, setCustomizeOpen] = React.useState(false);
  const [test, setTest] = useState<any[]>([]);
  const [userTest, setUserTest] = useState<any[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [loadingAnswer, setLoadingAnswer] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState<any>();
  const [numberOfQuestions, setNumberOfQuestions] = useState('50');
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questionsOpen, setQuestionsOpen] = useState(false);
  const [showTestResult, setShowTestResult] = useState(false);
  const [answered, setAnswered] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState('');
  const [radioValue, setRadioValue] = useState();
  const [startMomment, setStartMomment] = useState<any>();
  const [endMomment, setEndMomment] = useState<any>();
  const [questionsAlreadyAnswered, setQuestionsAlreadyAnswered] = useState<string[]>([]);
  const [primaryButtonLabel, setPrimaryButtonLabel] = useState<string>('Responder');
  const [snackOpen, setSnackOpen] = useState(false);
  const [optionsToCustomize, setOptionsToCustomize] = useState<any>();
  const [remainingTime, setRemainingTime] = useState();
  const { userData } = useContext(UserContext);

  const getMissionStatus = (mission: any) => {
    if (mission.isAccomplished) {
      return MissionStatus.acomplished;
    } else if (isExpiredDate(mission.expirationDate)) {
      return MissionStatus.expired;
    } else {
      return MissionStatus.pending;
    }
  };

  const finishTest = async () => {
    setShowTestResult(true);
    const userAnsweredQuestions = userTest.filter((item) => item.answered);

    //Chamar update de usuário
    if (userData.suitability === SuitabilityEnum.conservative.value) {
      if (userAnsweredQuestions.length >= 10) {
        await updateUserAnsweredCustomTestsService();
      }
    } else {
      if (userAnsweredQuestions.length >= 20) {
        await updateUserAnsweredCustomTestsService();
      }
    }

    if (userAnsweredQuestions.length === 50) {
      await updateUserAnsweredDefaultTestsService();
    }

    //Lidando com as missões
    const userMissions = userData.missions;
    const answeredCustomTestsOnWeek = userData.weekData.answeredCustomTests;

    if (userData.suitability === SuitabilityEnum.conservative.value) {
      //Lidando com missões de usuarios conservadores
      if (userAnsweredQuestions.length >= 10 && answeredCustomTestsOnWeek + 1 >= 2) {
        const missionId = '24ebd346-15d5-4ae3-bfd7-0bb5dfd86f56';
        const mission = userMissions.filter(
          (item: any) => getMissionStatus(item) === MissionStatus.pending && item.missionId === missionId,
        );

        if (mission.length > 0) {
          await updateUserMissionsService([mission[0].id]);
        }
      }
    } else {
      //Lidando com missões de usuarios arrojados
      if (userAnsweredQuestions.length >= 20 && answeredCustomTestsOnWeek + 1 >= 2) {
        const missionId = '2b63d21b-9fc6-400e-895f-72e117f02145';
        const mission = userMissions.filter(
          (item: any) => getMissionStatus(item) === MissionStatus.pending && item.missionId === missionId,
        );

        if (mission.length > 0) {
          await updateUserMissionsService([mission[0].id]);
        }
      }

      if (userAnsweredQuestions.length === 50) {
        const missionId = '730accf9-dbf7-487f-b193-8a0b6ec4281a';
        const mission = userMissions.filter(
          (item: any) => getMissionStatus(item) === MissionStatus.pending && item.missionId === missionId,
        );

        if (mission.length > 0) {
          await updateUserMissionsService([mission[0].id]);
        }
      }
    }

    //Lidando com conquistas
    const answeredDefaultTests = userData.answeredDefaultTests;
    let achievementId: string[] = [];

    if (userAnsweredQuestions.length === 50) {
      //Lidando com conquistas relacionadas a simulado padrão
      if (answeredDefaultTests + 1 === 1) {
        achievementId.push('7c51f4b5-3a0c-4188-a868-151f2327e3e2');
      } else if (answeredDefaultTests + 1 >= 5 && answeredDefaultTests + 1 < 10) {
        achievementId.push('5e7686d2-dfc6-47aa-a2bb-bbc1553432b4');
      } else if (answeredDefaultTests + 1 >= 10) {
        achievementId.push('abd9ef10-cb33-472e-af49-0ae8d65df85d');
      }

      const achievement = userData.acquiredAchievements.find((item: string) => item === achievementId[0]);

      if (!achievement && achievementId.length > 0) {
        await acquireAchievementToUserService(achievementId);
        return;
      }
    }

    //Lidando com conquistas relacionadas a simulados customizados
    if (userAnsweredQuestions.length >= 10) {
      achievementId.push('8e532131-a8ee-47e0-812a-dbd65d9b1e36');
    }
    if (userAnsweredQuestions.length >= 20) {
      achievementId.push('a1f955cd-5938-4ca2-b6d0-fdd92c3026ff');
    }

    const idsToInsert: string[] = [];
    for (const id of achievementId) {
      const achievement = userData.acquiredAchievements.find((item: string) => item === id);

      if (!achievement) {
        idsToInsert.push(id);
      }
    }

    if (idsToInsert.length > 0) {
      await acquireAchievementToUserService(idsToInsert);
    }
  };

  const getTestQuestions = async () => {
    setQuestionsOpen(true);
    const response = await getQuestionsService(numberOfQuestions);

    if (response.status === 200) {
      const test = response.data.result;

      setTest(test);
      setUserTest(test);
      setCurrentQuestion(test[currentQuestionIndex]);
    } else {
      setErrorMessage(
        'Ops! Parece que algo deu errado e não conseguimos carregar as questões do simulado agora. Provavelmente é algo momentâneo e estamos trabalhando para resolver. Em breve tudo estará normalizado.',
      );
    }
  };

  const updateUserTest = () => {
    const updatedUserTest = [];
    for (const item of userTest) {
      let updatedItem;
      if (item.question === currentQuestion.question) {
        const fullCorrectAnswer = currentQuestion.answers.find((item: any) => {
          if (bcrypt.compareSync(correctAnswerKey, item.isCorrect)) {
            return item;
          }
        });

        updatedItem = {
          question: item.question,
          answered: true,
          correctAnswer: fullCorrectAnswer.description,
          answers: item.answers.map((answer: any) => {
            return {
              ...answer,
              selected: answer.description === radioValue,
            };
          }),
        };
      } else {
        updatedItem = item;
      }
      updatedUserTest.push(updatedItem);
    }
    setUserTest(updatedUserTest);
  };

  const handleSendAnsweredQuestion = async () => {
    const answerPaylod: IAnsweredQuestionInput = {
      answerDate: new Date().toDateString(),
      answeredCorrectly: radioValue === correctAnswer,
      questionId: currentQuestion.id,
      spendedTime: endMomment - startMomment,
    };
    setLoadingAnswer(true);

    const answeredQuestionResponse = await postAnsweredQuestionService(answerPaylod);

    if (answeredQuestionResponse.status === 201) {
    } else {
      setSnackOpen(true);
    }
    const questionsAlreadyAnsweredUpdated = questionsAlreadyAnswered;
    questionsAlreadyAnsweredUpdated.push(currentQuestion.id);
    setQuestionsAlreadyAnswered(questionsAlreadyAnsweredUpdated);
    setRadioValue(undefined);
    setAnswered(false);
    setCorrectAnswer('');
    setLoadingAnswer(false);
  };

  const handleNextClick = async () => {
    if (test.indexOf(currentQuestion) === test.length - 1) {
      setPrimaryButtonLabel('Concluir');
    } else {
      setPrimaryButtonLabel('Próxima');
    }

    if (test.indexOf(currentQuestion) === test.length - 2 && primaryButtonLabel === 'Próxima') {
      setPrimaryButtonLabel('Concluir');
    }

    const userAnsweredCurrentQuestion = questionsAlreadyAnswered.some((item) => item === currentQuestion.id);

    if (userAnsweredCurrentQuestion) {
      setCurrentQuestion(test[currentQuestionIndex + 1]);
      setCurrentQuestionIndex(currentQuestionIndex + 1);

      return;
    }

    if (answered) {
      if (test.indexOf(currentQuestion) === test.length - 1) {
        setPrimaryButtonLabel('Concluir');
        finishTest();
        return;
      }
      await handleSendAnsweredQuestion();
      setCurrentQuestion(test[currentQuestionIndex + 1]);
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setStartMomment(new Date());
    } else {
      const fullCorrectAnswer = currentQuestion.answers.find((item: any) => {
        if (bcrypt.compareSync(correctAnswerKey, item.isCorrect)) {
          return item;
        }
      });

      setEndMomment(new Date());
      updateUserTest();
      setCorrectAnswer(fullCorrectAnswer.description);
      setAnswered(true);
    }
  };

  const handleBackClick = () => {
    setPrimaryButtonLabel('Próxima');
    setCurrentQuestion(test[currentQuestionIndex - 1]);
    setCurrentQuestionIndex(currentQuestionIndex - 1);
  };

  const handleRadioChange = (event: any) => {
    setPrimaryButtonLabel('Responder');
    setRadioValue(event.target.value);

    const updatedUserTest = [];
    for (const item of userTest) {
      let updatedItem;
      if (item.question === currentQuestion.question) {
        updatedItem = {
          ...item,
          answers: item.answers.map((answer: any) => {
            return {
              ...answer,
              selected: answer.description === event.target.value,
            };
          }),
        };
      } else {
        updatedItem = item;
      }
      updatedUserTest.push(updatedItem);
    }
    setUserTest(updatedUserTest);
  };

  const getSelectedValueFromUserQuiz = () => {
    if (userTest.length > 0) {
      const userAnsweredQuestion = userTest.find((item) => item.question === currentQuestion?.question);
      const selectedValue = userAnsweredQuestion?.answers.find((item: any) => item.selected === true);

      if (selectedValue?.selected) {
        return selectedValue.description;
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  };

  const getCurrentQuestion = () => {
    if (userTest.length > 0) {
      const result = userTest[currentQuestionIndex];

      return result;
    }
  };

  const handleCustomizeClick = (data: any) => {
    setOptionsToCustomize(data);
    setNumberOfQuestions(data.inputValue);
  };

  const getTimeToTest = () => {
    if (optionsToCustomize && optionsToCustomize.tagSelected) {
      return optionsToCustomize.tagSelected;
    } else {
      //Quatro horas - tempo do exame oficial
      return 14400;
    }
  };

  useEffect(() => {
    setStartMomment(new Date());
  }, []);

  useEffect(() => {
    if (optionsToCustomize) {
      getTestQuestions();
    }
  }, [optionsToCustomize]);

  useEffect(() => {}, [showTestResult]);

  return (
    <Box height={'100%'}>
      {questionsOpen ? (
        <PageStructure
          timeToCountDown={getTimeToTest()}
          setRemainingTime={(param: any) => setRemainingTime(param)}
          onFinishCountDown={() => finishTest()}
          stopCountDown={showTestResult}
          title={showTestResult ? 'Seu desempenho' : undefined}
          footerContent={
            !errorMessage && !showTestResult ? (
              !isMobile ? (
                <ButtonsContainer sx={{ paddingBottom: '16px' }}>
                  <CustomButton
                    classification="secondary"
                    uppercase={false}
                    title="Voltar"
                    onClick={() => handleBackClick()}
                    disabled={test.indexOf(currentQuestion) === 0 || test.length === 0}
                  />
                  <CustomButton
                    classification="primary"
                    uppercase={false}
                    title={primaryButtonLabel}
                    onClick={() => handleNextClick()}
                    disabled={!getSelectedValueFromUserQuiz()}
                    isLoading={loadingAnswer}
                  />
                  <Box ml={'30%'}>
                    <CustomButton
                      classification="secondary"
                      uppercase={false}
                      title="Encerrar simulado"
                      onClick={() => finishTest()}
                    />
                  </Box>
                </ButtonsContainer>
              ) : (
                <ButtonsContainer sx={{ paddingBottom: '24px' }}>
                  <CustomButton
                    classification="primary"
                    uppercase={false}
                    title={primaryButtonLabel}
                    onClick={() => handleNextClick()}
                    disabled={!getSelectedValueFromUserQuiz()}
                    isLoading={loadingAnswer}
                  />
                  <CustomButton
                    classification="secondary"
                    uppercase={false}
                    title="Voltar"
                    onClick={() => handleBackClick()}
                    disabled={test.indexOf(currentQuestion) === 0 || test.length === 0}
                  />
                </ButtonsContainer>
              )
            ) : (
              <></>
            )
          }
        >
          <Box mt={'24px'} mb={'18px'}>
            {userTest.length === 0 && !errorMessage && !currentQuestion ? (
              <QuestionSkeleton />
            ) : errorMessage ? (
              <Error message={errorMessage} />
            ) : showTestResult ? (
              <TestResults remainingTime={remainingTime} fullTime={getTimeToTest()} userTest={userTest} />
            ) : currentQuestion && userTest.length > 0 ? (
              <Box display={'flex'} gap={'28px'} flexDirection={'column'} mt={'-32px'}>
                <Box mb={'12px'}>
                  <ProgressBar value={currentQuestionIndex + 1} length={userTest.length} />
                </Box>
                <Question
                  answered={getCurrentQuestion()?.answered}
                  correctAnswer={getCurrentQuestion()?.correctAnswer}
                  question={getCurrentQuestion()?.question}
                  answers={getCurrentQuestion()?.answers}
                  onChange={(event) => handleRadioChange(event)}
                  selectedValue={getSelectedValueFromUserQuiz()}
                />
              </Box>
            ) : (
              <></>
            )}
          </Box>
          <CustomSnackBar isOpen={snackOpen}>
            <CustomAlert
              showLeftIcon={true}
              showCloseIcon={true}
              severity={'error'}
              title={'Ops! Não foi possível enviar a resposta. Tente novamente mais tarde.'}
              onClose={() => setSnackOpen(false)}
            />
          </CustomSnackBar>
        </PageStructure>
      ) : (
        <PageStructure title="Simulados">
          <Box>
            <Explanation>
              Personalize um simulado como quiser, ou responda um simulado seguindo os moldes da prova oficial.
            </Explanation>
            <OptionsContainer>
              <TestOptionContainer>
                <Box textAlign={'center'}>
                  <DefaultTestImg />
                </Box>
                <TestOptionTitle>Padrão</TestOptionTitle>
                <TestOptionContent>
                  Nesta modalidade você pratica nos moldes do exame oficial. São 50 questões para realizar em até 4h.
                </TestOptionContent>
                <CustomButton
                  classification="primary"
                  uppercase={false}
                  title="Começar"
                  onClick={() => getTestQuestions()}
                />
              </TestOptionContainer>
              <TestOptionContainer>
                <Box textAlign={'center'}>
                  <CustomizeTestImg />
                </Box>
                <TestOptionTitle>Personalizado</TestOptionTitle>
                <TestOptionContent>
                  Você pode escolher quantas questões fazer e em quanto tempo quer realizar o simulado.
                </TestOptionContent>
                <CustomButton
                  classification="primary"
                  uppercase={false}
                  title="Personalizar"
                  onClick={() => setCustomizeOpen(true)}
                />
              </TestOptionContainer>
            </OptionsContainer>
            <CustomizeTest
              isOpen={customizeOpen}
              onClose={() => setCustomizeOpen(false)}
              handleClickButtom={handleCustomizeClick}
            />
          </Box>
        </PageStructure>
      )}
    </Box>
  );
};

export default Tests;
