import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

export const Container = styled(Box)(() => ({
  width: '45vw',
  paddingTop: '25%',
  paddingLeft: '10%',
  paddingRight: '10%',
  paddingBottom: '10%',
  height: '100%',
  justifyContent: 'space-between',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '24px',

  '@media (max-width: 768px)': {
    width: '100vw',
    paddingLeft: '10%',
    paddingRight: '10%',
    paddingTop: '40%',
    paddingBottom: '12px',
  },
}));

export const Span = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: 400,

  '@media (max-width: 768px)': {
    fontSize: '14px',
  },
}));

export const StyledLink = styled(Link)((props) => ({
  cursor: 'pointer',
  color: props.theme.palette.purple.main,
  fontSize: '14px',
  letterSpacing: '0.5px',
  textDecoration: 'underline',

  '&:hover': {
    color: props.theme.palette.purple.dark,
  },
}));
