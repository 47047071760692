import React from 'react';

import { CircularProgress } from '@mui/material';

import { StyledButtonProps, StyledButton, StyledIconButton } from './styles';
import { defaultColors } from '../../../styles/theme';

interface ButtonProps extends StyledButtonProps {
  id?: string;
  onClick?: () => void;
  size?: 'small' | 'medium' | 'large';
  fullWidth?: boolean;
  disabled?: boolean;
  title?: string;
  icon?: JSX.Element;
  isLoading?: boolean;
  typeSubmit?: boolean;
}

const CustomButton: React.FC<ButtonProps> = (props) => {
  let newIcon = null;
  if (props.icon) {
    newIcon = Object.assign({}, props.icon, {
      props: {
        fill:
          props.classification === 'primary' || props.classification === 'warning'
            ? defaultColors.white.main
            : defaultColors.purple.main,
      },
    });
  }

  return (
    <>
      {props.title ? (
        <StyledButton
          id={props.id}
          type={props.typeSubmit ? 'submit' : 'button'}
          onClick={props.onClick}
          size={props.size}
          fullWidth={props.fullWidth}
          disabled={props.disabled || props.isLoading}
          classification={props.classification}
          uppercase={props.uppercase}
          startIcon={newIcon || null}
        >
          {props.isLoading ? <CircularProgress color="inherit" size={25} /> : props.title}
        </StyledButton>
      ) : (
        <StyledIconButton
          disabled={props.disabled || props.isLoading}
          classification={props.classification}
          onClick={props.onClick}
          size="small"
          uppercase={props.uppercase}
        >
          {newIcon}
        </StyledIconButton>
      )}
    </>
  );
};

export default CustomButton;
