import { IUserInput } from '../interfaces/input/user';
import {
  getUserRepository,
  patchUserFirstAccessRepository,
  patchUserShowOnRankingRepository,
  patchUserSignatureRepository,
  updateUserAnsweredCustomTestsRepository,
  updateUserAnsweredDefaultTestsRepository,
  updateUserDataRepository,
  userSignUpRepository,
} from '../repositories/user';

export const updateUserFirstAccessService = async () => {
  return await patchUserFirstAccessRepository();
};

export const updateUserShowOnRankingService = async () => {
  return await patchUserShowOnRankingRepository();
};

export const updateUserSignatureService = async (reason: string) => {
  return await patchUserSignatureRepository(reason);
};

export const userSignUpService = async (userData: IUserInput) => {
  return await userSignUpRepository(userData);
};

export const getUserDataService = async () => {
  return await getUserRepository();
};

export const updateUserDataService = async (userData: any) => {
  return await updateUserDataRepository(userData);
};

export const updateUserAnsweredCustomTestsService = async () => {
  return await updateUserAnsweredCustomTestsRepository();
};

export const updateUserAnsweredDefaultTestsService = async () => {
  return await updateUserAnsweredDefaultTestsRepository();
};
