import * as React from 'react';

import FormControl from '@mui/material/FormControl';

import { StyledErrorText, StyledLabel, StyledMenuItem, StyledSelect } from './styles';
import { defaultColors } from '../../../styles/theme';
import CheckIcon from '../../../images/icons/check';

interface ISelectOption {
  value: string;
  label: string;
}

interface ICustomSelectProps {
  fullWidth?: boolean;
  minWidth?: number | string;
  options: ISelectOption[];
  value: string | undefined | string[];
  onChange: (event: any) => void;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  errorMessage?: string;
  onBlur?: () => void;
}

const CustomSelect: React.FC<ICustomSelectProps> = ({
  fullWidth,
  minWidth,
  options,
  value,
  onChange,
  placeholder,
  label,
  disabled,
  errorMessage,
  onBlur,
}) => {
  return (
    <FormControl sx={{ minWidth: fullWidth ? '100%' : minWidth }}>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledSelect
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        displayEmpty
        renderValue={!value ? () => <span>{placeholder}</span> : undefined}
        disabled={disabled}
        error={!!errorMessage}
      >
        {options.map((option) => (
          <StyledMenuItem key={option.value} value={option.value} selected={value === option.value}>
            {option.label}
            <CheckIcon style={{ justifyContent: 'end' }} fill={defaultColors.white.main} />
          </StyledMenuItem>
        ))}
      </StyledSelect>
      {errorMessage && <StyledErrorText>{errorMessage}</StyledErrorText>}
    </FormControl>
  );
};

export default CustomSelect;
