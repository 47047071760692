import React, { useState } from 'react';

import { ButtonsContainer, Container, SuitabilityDescription, SuitabilityLabel } from './styles';
import BoldProfileImg from '../../../images/illustrations/boldProfile';
import ConservativeProfileImg from '../../../images/illustrations/conservativeProfile';
import { SuitabilityEnum } from '../../util/constants';
import CustomButton from '../button';
import { Link } from 'react-router-dom';
import { routes } from '../../../routes/routes';

interface IUserSuitability {
  showSecondaryButton: boolean;
  suitability: string | undefined;
}

const UserSuitability: React.FC<IUserSuitability> = ({ showSecondaryButton, suitability }) => {
  const userSuitability: any =
    suitability === SuitabilityEnum.bold.value ? SuitabilityEnum.bold : SuitabilityEnum.conservative;
  return (
    <Container>
      {userSuitability.value === SuitabilityEnum.bold.value ? <BoldProfileImg /> : <ConservativeProfileImg />}
      <SuitabilityLabel>{userSuitability.label}</SuitabilityLabel>
      <SuitabilityDescription>{userSuitability.description}</SuitabilityDescription>
      <ButtonsContainer>
        {showSecondaryButton && (
          <Link to={routes.suitabilityQuiz.path!}>
            <CustomButton classification="secondary" uppercase={false} title="Refazer teste de perfil" />
          </Link>
        )}
        <Link to={`${routes.dashboard.path}?tab=missoes`}>
          <CustomButton classification="primary" uppercase={false} title="Ir para missões" />
        </Link>
      </ButtonsContainer>
    </Container>
  );
};

export default UserSuitability;
