import * as React from 'react';

import { StyledContainer } from './styles';
import { Box, Skeleton } from '@mui/material';
import { defaultColors } from '../../../styles/theme';

const QuestionSkeleton: React.FC = () => {
  return (
    <StyledContainer>
      <Box gap={'12px'} display={'flex'} flexDirection={'column'}>
        <Skeleton
          sx={{ bgcolor: defaultColors.purple.light, borderRadius: '8px' }}
          variant="rectangular"
          animation="wave"
          width={'100%'}
          height={16}
        />
        <Skeleton
          sx={{ bgcolor: defaultColors.purple.light, borderRadius: '8px' }}
          variant="rectangular"
          animation="wave"
          width={'50%'}
          height={16}
        />
      </Box>
      <Box gap={'20px'} display={'flex'} flexDirection={'column'}>
        <Box gap={'12px'} display={'flex'} alignItems={'center'}>
          <Skeleton
            sx={{ bgcolor: defaultColors.purple.light, borderRadius: '12px' }}
            variant="rectangular"
            animation="wave"
            width={24}
            height={24}
          />
          <Skeleton
            sx={{ bgcolor: defaultColors.purple.light, borderRadius: '8px' }}
            variant="rectangular"
            animation="wave"
            width={'30%'}
            height={16}
          />
        </Box>
        <Box gap={'12px'} display={'flex'} alignItems={'center'}>
          <Skeleton
            sx={{ bgcolor: defaultColors.purple.light, borderRadius: '12px' }}
            variant="rectangular"
            animation="wave"
            width={24}
            height={24}
          />
          <Skeleton
            sx={{ bgcolor: defaultColors.purple.light, borderRadius: '8px' }}
            variant="rectangular"
            animation="wave"
            width={'30%'}
            height={16}
          />
        </Box>
        <Box gap={'12px'} display={'flex'} alignItems={'center'}>
          <Skeleton
            sx={{ bgcolor: defaultColors.purple.light, borderRadius: '12px' }}
            variant="rectangular"
            animation="wave"
            width={24}
            height={24}
          />
          <Skeleton
            sx={{ bgcolor: defaultColors.purple.light, borderRadius: '8px' }}
            variant="rectangular"
            animation="wave"
            width={'30%'}
            height={16}
          />
        </Box>
        <Box gap={'12px'} display={'flex'} alignItems={'center'}>
          <Skeleton
            sx={{ bgcolor: defaultColors.purple.light, borderRadius: '12px' }}
            variant="rectangular"
            animation="wave"
            width={24}
            height={24}
          />
          <Skeleton
            sx={{ bgcolor: defaultColors.purple.light, borderRadius: '8px' }}
            variant="rectangular"
            animation="wave"
            width={'30%'}
            height={16}
          />
        </Box>
      </Box>
    </StyledContainer>
  );
};

export default QuestionSkeleton;
