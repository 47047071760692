import React, { useContext, useEffect, useState } from 'react';

import PageStructure from '../../../shared/components/pageStructure';
import { Box, Typography } from '@mui/material';
import TabBar from '../components/tabBar';
import {
  Container,
  InviteFriend,
  PageStrutctureContainer,
  ScoreCard,
  ScoreFooter,
  ScoreLabel,
  ScoreValue,
  ShowRankingLabel,
  ToggleContainer,
  UserDataContainer,
  UserName,
} from './styles';
import CustomToggle from '../../../shared/components/toggle';
import { useSearchParams } from 'react-router-dom';
import { profileTabOptions } from '../../../routes/routes';
import { AuthenticationContext } from '../../../contexts/authentication';
import { educationOptions, ufs, userSuitabilityKey } from '../../../shared/util/constants';
import UserSuitability from '../../../shared/components/userSuitability';
import CustomTextInput from '../../../shared/components/textInput';
import CustomSelect from '../../../shared/components/select';
import CustomButton from '../../../shared/components/button';
import { formatPhone, isEmailValid, isPhoneNumberValid } from '../../../shared/util/functions';
import ChangePasswordModal from '../components/changePasswordModal';
import RocketGuyImg from '../../../images/illustrations/rocketGuy';
import { mobileBreakPoint } from '../../../styles/theme';
import CustomSnackBar from '../../../shared/components/toast';
import CustomAlert from '../../../shared/components/alert';
import { getUserDataService, updateUserDataService, updateUserSignatureService } from '../../../services/user';
import UserDataSkeleton from '../components/userDataSkeleton';

const Profile = () => {
  const isMobile = window.screen.width <= mobileBreakPoint;
  const [tabValue, setTabValue] = useState(profileTabOptions.general.tabId);
  const [showOnRanking, setShowOnRanking] = useState(true);
  const [disableButton, setDisableButton] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [userDataLoading, setUserDataLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  // states user data
  const [fullName, setFullName] = useState<string>('');
  const [nickName, setNickName] = useState<string>('');
  const [state, setState] = useState<string>('Estado*');
  const [education, setEducation] = useState<string>('Escolaridade*');
  const [email, setEmail] = useState<string>('');
  const [whatsapp, setWhatsapp] = useState<string>('');
  // states user data errors
  const [fullNameError, setFullNameError] = useState<string>();
  const [stateError, setStateError] = useState<string>();
  const [educationError, setEducationError] = useState<string>();
  const [emailError, setEmailError] = useState<string>();
  const [whatsappError, setWhatsappError] = useState<string>();

  //cancelar assinatura
  const [cancelReason, setCancelReason] = useState();
  const [showReasonToCancel, setShowReasonToCancel] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  //update
  const [updateLoading, setUpdateLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string>();

  const { user } = useContext(AuthenticationContext);
  const userSuitability = user?.suitability || localStorage.getItem(userSuitabilityKey);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);

    switch (newValue) {
      case profileTabOptions.signature.tabId:
        searchParams.set('tab', profileTabOptions.signature.param);
        break;
      case profileTabOptions.profileTest.tabId:
        searchParams.set('tab', profileTabOptions.profileTest.param);
        break;
      default:
        searchParams.set('tab', profileTabOptions.general.param);
    }

    setSearchParams(searchParams);
  };

  const handleNameChange = (event: any) => {
    const name = event.target.value;

    const fullName = name.split(' ');
    const regex = /[^a-zà-ú]/gi;

    const fullNameToTest = name.replace(' ', '');

    if (fullName.length <= 1 || fullName[1] == '' || fullNameToTest.match(regex)) {
      setFullNameError('Nome inválido');
    } else {
      setFullNameError(undefined);
    }

    setFullName(name);
  };

  const handleNameBlur = () => {
    if (!fullName) {
      setFullNameError('O nome completo é obrigatório');
    } else {
      setFullNameError(undefined);
    }
  };

  const handleEmailBlur = () => {
    if (!email) {
      setEmailError('O e-mail é obrigatório');
    } else {
      setEmailError(undefined);
    }
  };

  const handleWhatsappBlur = () => {
    if (!whatsapp) {
      setWhatsappError('O whatsapp é obrigatório');
    } else {
      setWhatsappError(undefined);
    }
  };

  const handleEmailChange = (event: any) => {
    const email = event.target.value;

    if (!isEmailValid(email) || !email) {
      setEmailError('E-mail inválido');
    } else {
      setEmailError(undefined);
    }

    setEmail(email);
  };

  const handleWhatsappChange = (event: any) => {
    const whatsapp = event.target.value;

    if (!isPhoneNumberValid(whatsapp) || !whatsapp) {
      setWhatsappError('Whatsapp inválido');
    } else {
      setWhatsappError(undefined);
    }

    setWhatsapp(formatPhone(whatsapp));
  };

  const handleCancelSignature = async () => {
    if (!showReasonToCancel) {
      setShowReasonToCancel(true);
      return;
    }
    const response = await updateUserSignatureService(cancelReason!);

    if (response.status !== 201) {
      setErrorMessage(
        'Não foi possível realizar o cancelamento. Caso o problema persista, entre em contato com nosso suporte.',
      );
    }
  };

  const handleEducationBlur = () => {
    if (education === 'Escolaridade*') {
      setEducationError('A escolaridade é obrigatória');
    } else {
      setEducationError(undefined);
    }
  };

  const handleStateBlur = () => {
    if (state === 'Estado*') {
      setStateError('O estado é obrigatório');
    } else {
      setStateError(undefined);
    }
  };

  const getUserData = async () => {
    setUserDataLoading(true);
    const response = await getUserDataService();

    if (response.status === 200) {
      const user = response.data.result;
      setFullName(user?.name || '');
      setNickName(user?.nickName || '');
      setState(user?.state || 'Estado*');
      setEducation(user?.scholarity || 'Escolaridade*');
      setWhatsapp(formatPhone(user?.phoneNumber) || '');
      setEmail(user?.email || '');
      setShowOnRanking(user?.isActiveOnRanking || false);
    } else {
      setErrorMessage('Não foi possível carregar seus dados.');
    }
    setUserDataLoading(false);
  };

  const handleSaveUserData = async () => {
    setUpdateLoading(true);

    const input = {
      name: fullName,
      state: state,
      email: email,
      scholarity: education,
      password: '654321',
      phoneNumber: whatsapp,
      //   isActiveOnRanking: showOnRanking ? 1 : 0,
    };
    const response = await updateUserDataService(input);
    if (response.status !== 200) {
      setErrorMessage('Ops! Não foi possível concluir a atualização dos dados.');
    } else {
      setSuccessMessage('Dados atualizados com sucesso!');
    }

    setUpdateLoading(false);
  };

  useEffect(() => {
    const tabParamValue = searchParams.get('tab');

    switch (tabParamValue) {
      case profileTabOptions.signature.param:
        setTabValue(profileTabOptions.signature.tabId);
        break;
      case profileTabOptions.profileTest.param:
        setTabValue(profileTabOptions.profileTest.tabId);
        break;
      default:
        setTabValue(profileTabOptions.general.tabId);
    }
  }, [searchParams]);

  useEffect(() => {
    if (state === 'Estado*') {
      setStateError('O estado é obrigatório');
    } else {
      setStateError(undefined);
    }
  }, [state]);

  useEffect(() => {
    if (education === 'Escolaridade*') {
      setEducationError('A escolaridade é obrigatória');
    } else {
      setEducationError(undefined);
    }
  }, [education]);

  useEffect(() => {
    if (
      !fullName ||
      fullNameError ||
      stateError ||
      educationError ||
      !email ||
      emailError ||
      !whatsapp ||
      whatsappError ||
      education === 'Escolaridade*' ||
      state === 'Estado*'
    ) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [
    fullName,
    email,
    fullNameError,
    stateError,
    educationError,
    emailError,
    whatsapp,
    whatsappError,
    education,
    state,
  ]);

  useEffect(() => {
    setStateError(undefined);
    setEducationError(undefined);
    getUserData();
  }, []);

  return (
    <Container>
      {/* <UserDataContainer>
        <UserName>Gustavo Henrique Nogueira</UserName>
        <ScoreCard>
          <ScoreLabel>Total de acertos:</ScoreLabel>
          <ScoreValue>62%</ScoreValue>
          <ScoreFooter>Você acertou 78 questões das 130 questões que respondeu</ScoreFooter>
        </ScoreCard>
      </UserDataContainer> */}
      {/* <InviteFriend onClick={() => {}}>Convidar um amigo</InviteFriend> */}
      <PageStrutctureContainer>
        <PageStructure title="Dados de perfil">
          <Box display={'flex'} flexDirection={'column'} gap={'12px'}>
            <Box>
              {/* <TabBar value={tabValue} handleChange={handleTabChange} /> */}
              {tabValue === profileTabOptions.general.tabId ? (
                userDataLoading ? (
                  <UserDataSkeleton />
                ) : (
                  <Box mt={'4px'} display={'flex'} flexDirection={'column'} gap={'6px'}>
                    <Box width={isMobile ? '100%' : '75%'}>
                      <CustomTextInput
                        fullWidth
                        value={fullName}
                        placeholder="Nome completo*"
                        maxLength={100}
                        onChange={(event: any) => handleNameChange(event)}
                        errorMessage={fullNameError}
                        onBlur={() => handleNameBlur()}
                      />
                    </Box>
                    {/* <Box width={'100%'} display={'flex'} gap={'1vw'}>
                      <Box mt={'6px'} width={isMobile ? '100%' : '20%'}>
                        <CustomSelect
                          fullWidth={true}
                          onChange={(event: any) => setState(event.target.value)}
                          value={state}
                          options={ufs}
                          errorMessage={stateError}
                          onBlur={() => handleStateBlur()}
                        />
                      </Box>
                    </Box> */}

                    {/* <Box mt={'12px'} width={isMobile ? '100%' : '50%'}>
                      <CustomSelect
                        onChange={(event: any) => setEducation(event.target.value)}
                        value={education}
                        options={educationOptions}
                        minWidth={'100%'}
                        errorMessage={educationError}
                        onBlur={() => handleEducationBlur()}
                      />
                    </Box> */}
                    <Box mt={'6px'} width={isMobile ? '100%' : '35%'}>
                      <CustomTextInput
                        fullWidth={true}
                        value={whatsapp}
                        placeholder="Whatsapp*"
                        maxLength={15}
                        onChange={(event: any) => handleWhatsappChange(event)}
                        errorMessage={whatsappError}
                        onBlur={() => handleWhatsappBlur()}
                      />
                    </Box>
                    <Box mt={'6px'} width={isMobile ? '100%' : '70%'}>
                      <CustomTextInput
                        fullWidth={true}
                        value={email}
                        placeholder="E-mail*"
                        maxLength={100}
                        onChange={(event: any) => handleEmailChange(event)}
                        errorMessage={emailError}
                        onBlur={() => handleEmailBlur()}
                        disabled
                      />
                    </Box>
                    {/* <Box mt={'18px'}>
                      <ToggleContainer onClick={() => setShowOnRanking(!showOnRanking)}>
                        <CustomToggle selected={showOnRanking} />
                        <ShowRankingLabel
                          sx={{
                            userSelect: 'none',
                          }}
                          selected={showOnRanking}
                        >
                          Exibir meu desempenho no ranking
                        </ShowRankingLabel>
                      </ToggleContainer>
                    </Box> */}
                    <CustomSnackBar isOpen={!!errorMessage}>
                      <CustomAlert
                        showLeftIcon={true}
                        showCloseIcon={true}
                        severity={'error'}
                        title={errorMessage!}
                        onClose={() => setErrorMessage(undefined)}
                      />
                    </CustomSnackBar>
                    <CustomSnackBar isOpen={!!successMessage}>
                      <CustomAlert
                        showLeftIcon={true}
                        showCloseIcon={true}
                        severity={'info'}
                        title={successMessage!}
                        onClose={() => setSuccessMessage(undefined)}
                      />
                    </CustomSnackBar>

                    {isMobile ? (
                      <Box mt={'3vh'} display={'flex'} flexDirection={'column'} gap={'16px'}>
                        {/* <CustomButton
                          classification="secondary"
                          uppercase={false}
                          title="Alterar senha"
                          onClick={() => setModalOpen(true)}
                        /> */}
                        <CustomButton
                          classification="primary"
                          uppercase={false}
                          title="Salvar"
                          onClick={() => handleSaveUserData()}
                          disabled={disableButton}
                          isLoading={updateLoading}
                        />
                      </Box>
                    ) : (
                      <>
                        <Box mt={'7vh'} display={'flex'} gap={'16px'}>
                          {/* <CustomButton
                            classification="secondary"
                            uppercase={false}
                            title="Alterar senha"
                            onClick={() => setModalOpen(true)}
                          /> */}
                          <CustomButton
                            classification="primary"
                            uppercase={false}
                            title="Salvar"
                            onClick={() => handleSaveUserData()}
                            disabled={disableButton}
                            isLoading={updateLoading}
                          />
                        </Box>
                      </>
                    )}
                  </Box>
                )
              ) : tabValue === profileTabOptions.profileTest.tabId ? (
                <UserSuitability suitability={userSuitability} showSecondaryButton />
              ) : (
                <Box height={'100%'} mb={'84px'}>
                  <Box
                    display={'flex'}
                    mt={'32px'}
                    gap={'24px'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    height={'100%'}
                  >
                    <RocketGuyImg />
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      justifyContent={'space-between'}
                      sx={{ height: isMobile ? '20vh' : '30vh', alignItems: 'center', textAlign: 'center' }}
                    >
                      <Typography>Sua assinatura está ativa!</Typography>
                      {showReasonToCancel && (
                        <>
                          <Typography mt="12px" mb="12px" fontSize={'14px'}>
                            Que pena que deseja cancelar sua assinatura. Pode nos contar um pouco sobre o motivo?
                          </Typography>
                          <CustomTextInput
                            multiline={true}
                            rows={3}
                            value={cancelReason}
                            onChange={(event: any) => setCancelReason(event.target.value)}
                            maxLength={250}
                            minWidth={'100%'}
                          />
                        </>
                      )}

                      <Box mt="16px">
                        <CustomButton
                          classification="secondary"
                          uppercase={false}
                          title={showReasonToCancel ? 'Confirmar cancelamento' : ' Cancelar assinatura'}
                          onClick={() => handleCancelSignature()}
                          disabled={showReasonToCancel ? !cancelReason : false}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <CustomSnackBar isOpen={!!errorMessage}>
                    <CustomAlert
                      showLeftIcon={true}
                      showCloseIcon={true}
                      severity={'error'}
                      title={errorMessage!}
                      onClose={() => setErrorMessage(undefined)}
                    />
                  </CustomSnackBar>
                </Box>
              )}
            </Box>
          </Box>
          <ChangePasswordModal
            onClose={() => setModalOpen(false)}
            open={modalOpen}
            title={'Alterar senha'}
            primaryButtonTitle="Salvar"
            primaryButtonOnClick={() => {}}
            showSecondaryButton
            secondaryButtonTitle="Cancelar"
            secondaryButtonOnClick={() => setModalOpen(false)}
          />
        </PageStructure>
      </PageStrutctureContainer>
    </Container>
  );
};

export default Profile;
