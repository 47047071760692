import { TableContainer, TableCell, tableCellClasses, TableRow, Typography, tableRowClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { defaultShadow } from '../../../styles/theme';

export const StyledTableContainer = styled(TableContainer)((props) => ({}));

export const StyledTableCell = styled(TableCell)((props) => ({
  [`&.${tableCellClasses.head}`]: {
    color: props.theme.palette.gray.main,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '14px',
    border: 'none',
    padding: '8px 14px',

    '&:first-of-type': {
      width: '10%',
    },

    '&:last-of-type': {
      textAlign: 'center',
      width: '10%',
    },

    '@media (max-width: 768px)': {
      fontSize: '12px',
      lineHeight: '12px',
    },
  },
  [`&.${tableCellClasses.body}`]: {
    color: props.theme.palette.textColor,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '14px',

    '&:first-of-type': {
      borderTopLeftRadius: '16px',
      borderBottomLeftRadius: '16px',
    },

    '&:last-of-type': {
      textAlign: 'center',
      fontWeight: 400,
      borderTopRightRadius: '16px',
      borderBottomRightRadius: '16px',
    },

    '@media (max-width: 768px)': {
      fontSize: '12px',
      lineHeight: '12px',
      maxWidth: '150px',
    },
  },
}));

export const StyledTableRow = styled(TableRow)((props) => ({
  cursor: 'pointer',
  transition: 'all 0.3s',
  marginLeft: '16px',
  backgroundColor: props.theme.palette.white.main,
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  '&:nth-of-type(n)': {
    '&:hover': {
      backgroundColor: props.theme.palette.purple.main,

      '& > .MuiTableCell-root': {
        color: props.theme.palette.white.main,
        fontWeight: 500,
        border: '1px solid transparent',
      },
    },
  },
}));

export const StyledLabelAction = styled(Typography)((props) => ({
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '14px',
  color: props.theme.palette.purple.main,
  textDecorationLine: 'underline',
  cursor: 'pointer',
}));
